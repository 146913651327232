import * as ACTIONS from './login.actions';

const initialState = {
	loading: false,
	userFields: {
		email: null,
		password: null,
		displayName: null,
		avatar: null,
	},
	error: null,
};

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.RESET_PASSWORD_ACTION:
		case ACTIONS.SIGNUP_USER_ACTION:
		case ACTIONS.LOGIN_USER_ACTION:
		case ACTIONS.CHECK_EMAIL_ACTION:
		case ACTIONS.CHECK_DISPLAY_NAME_ACTION:
		case ACTIONS.LOGOUT_USER_ACTION_PENDING:
		case ACTIONS.REQUEST_PASSWORD_RESET_ACTION:
			return {
				...state,
				loading: true,
				error: null
			};
		case ACTIONS.CHECK_EMAIL_ACTION_SUCCESS:
		case ACTIONS.CHECK_DISPLAY_NAME_ACTION_SUCCESS:
		case ACTIONS.REQUEST_PASSWORD_RESET_ACTION_SUCCESS:
			return {
				...state,
				loading: false,
				error: null
			};
		case ACTIONS.SIGNUP_USER_ACTION_SUCCESS:
		case ACTIONS.LOGIN_USER_ACTION_SUCCESS:
		case ACTIONS.LOGOUT_USER_ACTION:
		case ACTIONS.RESET_PASSWORD_ACTION_SUCCESS:
			return {
				...initialState,
			};
		case ACTIONS.REQUEST_PASSWORD_RESET_ACTION_FAIL:
		case ACTIONS.RESET_PASSWORD_ACTION_FAIL:
		case ACTIONS.SIGNUP_USER_ACTION_FAIL:
		case ACTIONS.CHECK_EMAIL_ACTION_FAIL:
		case ACTIONS.CHECK_DISPLAY_NAME_ACTION_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.error
			};
		case ACTIONS.LOGIN_USER_ACTION_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.DETAILS
			};
		case ACTIONS.SET_EMAIL_PASSWORD_FIELDS_ACTION:
			const {email, password} = action.payload;
			return {
				...state,
				userFields: {
					...state.userFields,
					email,
					password
				}
			};
		case ACTIONS.SET_DISPLAY_NAME_FIELD_ACTION:
			const {displayName} = action.payload;
			return {
				...state,
				userFields: {
					...state.userFields,
					displayName
				}
			};
		case ACTIONS.SET_AVATAR_FIELD_ACTION:
			const {avatar} = action.payload;
			return {
				...state,
				userFields: {
					...state.userFields,
					avatar
				}
			};
		default:
			return state;
	}
}; 
