import GameSparks from './gamesparks-es6.js';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { get } from 'lodash';
import { readRestUserData } from './local-storage';
import WebsocketAPI from './websocketAPI';
const CryptoJS = require('crypto-js');

let antstreamInstance = null;
class Antstream {
	constructor() {
		this.isInitialised$ = new BehaviorSubject([]);
		this.message$ = new BehaviorSubject([]);
		this.websocketAPImessage$ = new BehaviorSubject([]);

		// New GameSparks instance
		this.gameSparks = new GameSparks();
	}

	startup = (sessionData) => {
		this.gameSparks.init(window.config.REACT_APP_GAMESPARKS_WEBSOCKET_URL, {
			key: window.config.REACT_APP_APIKEY,
			onNonce: (nonce) => {
				return {
					hmacData:CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(nonce, window.config.REACT_APP_SECRETKEY)),
					sessionData
				};
			},
			onInit: (userId=null) => {
				this.isInitialised$.next({success:true,userId});
				this.isInitialised$.complete();
			},
			onMessage: (message) => {
				this.message$.next(message);
			}
		});
	};

	getIsInitialised$ = () => {
		return this.isInitialised$.asObservable();
	};

	getMessage$ = () => {
		return this.message$.asObservable();
	};

	gsSocketReconnect() {
		// this prevents the issue with using previous token (this.authToken) for new session when user logout and login under another account
		this.gameSparks.clearAuthTokenAndSession();

		this.gameSparks.reconnectConnection();
	}

	// @method getUserLogin
	// @params display-name, password, token
	// @description Handles a user login request
	getUserLogin(userName, password, token, onResponse) {
		const request = { userName, password };
		this.gameSparks.sendWithData('AuthenticationRequest', request, onResponse);
	}

	fetchPostAuthenticationData(promoCode) {
		const subj = new Subject();
		const payload = {
			promoCode
		};

		if(navigator && navigator.userAgent){
			payload.userAgent = navigator.userAgent;
		}

		this.logEventRequest('post_authentication', payload, (response) => {
			if (response.error) subj.error(response.message);
			else {
				subj.next(response.scriptData);
			}
			subj.complete();
		});
		return subj.asObservable();
	}

	getAuthResponse() {
		// ids = typeof ids === 'string' ? [ids] : ids;
		const subj = new Subject();
		this.logEventRequest('get_auth_response', null, (resp) => {
			if (resp.error) subj.error(resp.message);
			else subj.next(resp.scriptData);
			subj.complete();
		});
		return subj.asObservable();
	}

	loginWithFacebook(accessToken) {
		const subj = new Subject();
		const payload = {
			accessToken: accessToken,
			code: null,
			doNotLinkToCurrentPlayer: false,
			errorOnSwitch: false,
			segments: {},
			switchIfPossible: false,
			syncDisplayName: false,
		};
		this.gameSparks.sendWithData('FacebookConnectRequest', payload, (resp) => {
			subj.next(resp);
			subj.complete();
		});
		return subj.asObservable();
	}

	setDisplayName( displayName ) {
		const subj = new Subject();
		const payload = {
			action: 'change_display_name',
			newDisplayName: displayName,
		};
		this.logEventRequest('query_settings', payload, (resp) => {
			if (resp.error) subj.error(resp.error);
			else subj.next(resp.scriptData);
			subj.complete();
		});
		return subj.asObservable();
	}

	getUploadUrl () {
		const subj = new Subject();
		const payload ={
			action:'get_upload_url'
		};
		this.logEventRequest('query_settings',payload, (resp)=>{
			subj.next(resp.scriptData.uploadUrl);
			subj.complete();
		});
		return subj.asObservable();
	}

	setUploadId(uploadId) { 
		const subj = new Subject();
		const payload = {
			action: "set_upload_id",
			upload_id:uploadId
		};
		this.logEventRequest('query_settings',payload, (resp) => {
			subj.next(resp.scriptData.profile_image_id);
			subj.complete();
		});
		return subj.asObservable();
	}

	signupNewUser(email, password, displayName, origin, attributionData) {
		const subj = new Subject();

		const payload = {
			userName: email,
			password: password,
			displayName,
			scriptData: {
				action: 'SimpleRegistration',
				origin,
				attributionData
			}
		};
		this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else {
				subj.next(resp);
			}
			subj.complete();
		});
		return subj.asObservable();
	}

	checkDisplayName(displayName) {
		const subj = new Subject();
		const payload = {
			displayName: '',
			password: '',
			userName: '',
			scriptData: {
				action: 'CheckUserName',
				displayName,
			}
		};
		this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
			const {error, message} = resp.error.result;
			if (error) subj.error({ success: false, error: message });
			else subj.next({ success: true });
			subj.complete();
		});
		return subj.asObservable();
	}

	changeUserDetails(userName) {
		const subj = new Subject();
		const payload = {
			userName
		};
		this.gameSparks.sendWithData('ChangeUserDetailsRequest', payload, (resp) => {
			subj.next(resp);
			subj.complete();
		});
		return subj.asObservable();
	}

	checkEmail(email) {
		const subj = new Subject();
		const payload = {
			displayName: '',
			password: '',
			userName: '',
			scriptData: {
				action: 'CheckEmail',
				email,
			}
		};
		this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
			const {error, message} = resp.error.result;
			if (error) subj.error({ success: false, error: message });
			else subj.next({ success: true });
			subj.complete();
		});
		return subj.asObservable();
	}

	getAvatarsBeforeLogin() {
		const subj = new Subject();
		const payload = {
			displayName: '',
			password: '',
			userName: '',
			scriptData: {
				action: 'fetchAvatars'
			}
		};
		this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
			const {error, message} = resp.error.result;
			if (error) subj.error({ success: false, error: message });
			else subj.next(message);
			subj.complete();
		});
		return subj.asObservable();
	}

	passwordResetRequest(email) {
		const subj = new Subject();
		const payload = {
			displayName: '',
			password: '',
			userName: '',
			scriptData: {
				action: 'PasswordRecovery',
				email,
			}
		};
		this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
			// because of special circumstances the error key is always present even in case of success
			if (!resp.error['Verification']) subj.error({ success: false, error: resp.error.message });
			else subj.next({ success: true, message: resp.error['Verification'] });
			subj.complete();
		});
		return subj.asObservable();
	}

	resetPassword(token, email, password) {
		const subj = new Subject();
		const payload = {
			displayName: '',
			password: '',
			userName: '',
			scriptData: {
				action: 'ResetPassword',
				token,
				password,
				email,
			}
		};
		this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
			if (!resp.error['ResetPassword']) subj.error({ success: false, error: resp.error.message });
			else subj.next({ success: true, message: resp.error['ResetPassword'] });
			subj.complete();
		});
		return subj.asObservable();
	}

	getPlans(paymentTypes) {
		const subj = new Subject();
		this.logEventRequest('query_payment', { action: 'getPlans', paymentTypes }, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(get(resp, 'scriptData', null));
			subj.complete();
		});
		return subj.asObservable();
	}

	createCardWithSCAData(data, sku) {
		const subj = new Subject();
		const payload = {
			action: 'createCardWithSCAData',
			token:data,		// paymentMethod.id
			sku,
		};
		this.logEventRequest('query_payment', payload, (resp) => {
			console.log("createCardWithSCAData:  resp", resp);
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData.paymentData);
			subj.complete();
		});
		return subj.asObservable();
	}

	// Called when a free trial isn't available, but the user still
	// wants to create a subscription
	continueCreateSubscription(sku) {
		const subj = new Subject();
		const payload = {
			action: 'continueCreateSub',
			sku,
		};
		this.logEventRequest('query_payment', payload, (resp) => {
			console.log("continueCreateSub:  resp", resp);
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData.paymentData);
			subj.complete();
		});
		return subj.asObservable();
	}

	cancelSubscription() {
		const subj = new Subject();
		const payload = {
			action: 'cancelCard'
		};
		this.logEventRequest('query_payment', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData.paymentData);
			subj.complete();
		});
		return subj.asObservable();
	}

	replaceCard(token) {
		const subj = new Subject();
		const payload = {
			action: 'replaceCard',
			token,
		};
		this.logEventRequest('query_payment', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData.paymentData);
			subj.complete();
		});
		return subj.asObservable();
	}

	replaceCardSCA(paymentMethod) {
		const subj = new Subject();
		const payload = {
			action: 'replaceCard',
			paymentMethod,
		};
		this.logEventRequest('query_payment', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData.paymentData);
			subj.complete();
		});
		return subj.asObservable();
	}

	updateSCACardStatus(paymentMethod) {
		const subj = new Subject();
		const payload = {
			action: 'updateSCACardStatus',
			paymentMethod,
		};
		this.logEventRequest('query_payment', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData.paymentData);
			subj.complete();
		});
		return subj.asObservable();
	}

	applyPromoCode(code, googleReCaptchaToken) {
		const subj = new Subject();
		const payload = {
			action: 'usePromoCode',
			code,
			googleReCaptchaToken,
		};
		this.logEventRequest('query_payment', payload, (resp) => {
			if (resp.error) {
				subj.error(resp);
			} else {
				subj.next(resp.scriptData.response);
			}
			subj.complete();
		});
		return subj.asObservable();
	}

	getAvatars() {
		const subj = new Subject();

		this.logEventRequest('get_base_avatars', null, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(get(resp, 'scriptData.avatars.urls', []));
			subj.complete();
		});
		return subj.asObservable();
	}

	setUserAvatar(url) {
		const subj = new Subject();
		const payload = {
			avatarUrl: url,
		};
		this.logEventRequest('set_base_avatar', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(resp);
			subj.complete();
		});
		return subj.asObservable();
	}


	finishRegistrationNewUser(avatarType, displayName, userId) {
		const subj = new Subject();
		const payload = {
			displayName: '', // here to get gamesparks to let the request through
			password: '',
			userName: '',
			scriptData: {
				action: 'FinishRegistration',
				displayName: displayName,
				avatarType: avatarType,
				userId,
			}
		};
		this.gameSparks.sendWithData('RegistrationRequest', payload, (resp) => {
			const {error, message} = resp.error.result;
			if (error) subj.error(resp);
			else {
				subj.next(message);
			}
			subj.complete();
		});
		return subj.asObservable();
	}

	onPaypalCheckoutResponse(response) {
		const subj = new Subject();
		const payload = {
			action: 'onPaypalCheckoutResponse',
			response:response
		};
		this.logEventRequest('query_payment', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData.response);
			subj.complete();
		});
		return subj.asObservable();
	}

	getFortumoConfig(origin) {
		const subj = new Subject();
		const payload = {
			action: 'getConfig',
			origin: origin
		};
		this.logEventRequest('fortumo_event', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData);
			subj.complete();
		});
		return subj.asObservable();
	}

	getFortumoPaymentObject() {
		const subj = new Subject();
		const payload = {
			action: 'getPaymentObject',
		};
		this.logEventRequest('fortumo_event', payload, (resp) => {
			if (resp.error) subj.error(resp);
			else subj.next(resp.scriptData.response);
			subj.complete();
		});
		return subj.asObservable();
	}

	logEventRequest(eventKey, data, onResponse) {
		const request = { eventKey };
		if (data) request.data = data;
		this.gameSparks.sendWithData('LogEventRequest', request, onResponse);
	}

	getWebsocketAPImessage$ = () => {
		return this.websocketAPImessage$.asObservable();
	};

	socketDisconnect() {
		if (this.websocketAPI) {
			this.websocketAPI.close();
		}
	}

	socketConnect() {
		const { authToken } = readRestUserData();
		this.websocketAPI = new WebsocketAPI();
		this.websocketAPI.init(`${window.config.REACT_APP_ANTSTREAM_WS_API_BASE_URL}/${window.config.REACT_APP_ANTSTREAM_WS_API_STAGE}?token=${authToken}`, {
			key: '',
			onMessage: (message) => {
				this.websocketAPImessage$.next(message);
			}
		});
	}
}

const antstream = () => {
	if(antstreamInstance === null ) antstreamInstance = new Antstream();
	return antstreamInstance;
};

export default antstream();