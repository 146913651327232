const FOLDER_TYPES = {
	AVATARS:"AVATARS",
	BROCHURE_CONTENT_ASSETS:"BROCHURE_CONTENT_ASSETS",
	FEATURED:"FEATURED",
	SCREENSHOT:"SCREENSHOT",
	HOMEPAGE_SCREENSHOT:"HOMEPAGE_SCREENSHOT",
	PRE_LOAD:"PRE_LOAD",
	GENRE_ICONS:"GENRE_ICONS",
	YEAR_ICONS:"YEAR_ICONS",
	HOMEPAGE_ICONS:"HOMEPAGE_ICONS",
	CHALLENGE_GAMES:"CHALLENGE_GAMES",
	CHALLENGE_MEDALS:"CHALLENGE_MEDALS",
	PUBLISHERS:"PUBLISHERS",
	BANNERS:"BANNERS",
	ACHIEVEMENTS:"ACHIEVEMENTS",
	TOURNAMENTS:"TOURNAMENTS",
};

export {FOLDER_TYPES};
