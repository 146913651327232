import React, {Component} from 'react';

import {PulsarComponent} from '../common/pulsar/pulsar.component';
import {capitalizeFirstLetter} from '../../assets/lib/utils';
import starImage from '../../assets/images/ftui/best_value.svg';

import './payment-plan-item.component.less';

class PaymentPlanItemComponent extends Component {

	onClick = () => {
		if(this.props.onSelect)this.props.onSelect();
	};

	render() {
		const {isFreeTierV2FeatureEnabled, className, title, term, description, annualPriceFormatted, priceFormatted, sku, selectedSku} = this.props;

		if (isFreeTierV2FeatureEnabled) {
			return (
				<div
					className={`payment-plan-item payment-plan-item-v2 ${className?className:''} ${sku===selectedSku?'active':''}`}
					onClick={this.onClick}
				>
					<div className="content-container">
						<h2>Unlimited Gaming for</h2>
						<h1>{term==='annual'?annualPriceFormatted:priceFormatted}</h1>
						<p className="description">{term==='annual'? 'Annual' : 'Monthly'} Subscription</p>
					</div>
					<div className="best-value">
						<img className="best-value-icon" src={starImage} alt="star-icon"/>
						<p className="title">BEST VALUE</p>
					</div>
					<PulsarComponent />
				</div>
			);
		}

		return (
			<div
				className={`payment-plan-item ${className?className:''} ${sku===selectedSku?'active':''}`}
				onClick={this.onClick}
			>
				<div className="content-container">
					<h2>{capitalizeFirstLetter(title)}</h2>
					<p className="description">{description}</p>
					<h1>{term==='annual'?annualPriceFormatted:priceFormatted}</h1>
					{annualPriceFormatted &&
						<>
							<p className="description">{term==='annual'?'Per Year':(term==='daily'?'Per Day':'Per Month')}</p>
							<h5>{term==='annual'?priceFormatted:annualPriceFormatted}</h5>
							<p className="description">{term==='annual'?'Equivalent To Monthly':'Equivalent To Annually'}</p>
						</>
					}
				</div>
				<div className="best-value">
					<img className="best-value-icon" src={starImage} alt="star-icon"/>
					<p className="title">BEST VALUE</p>
				</div>
				<PulsarComponent />
			</div>
		);
	}
}

export const PaymentPlanItem = PaymentPlanItemComponent;