import React from 'react';
import { history } from './configureStore';
import { Route, Router } from 'react-router';
import { ROUTES } from './app.router';
import { Splash } from './components/splash/splash.component';
import { LoginCredentials } from './components/login/login-credentials/login-credentials.component';
import { SignupAvatar } from './components/login/signup/signup-avatar.component';
import { ResetPassword } from './components/login/reset-password/reset-password.component';
import { PasswordResetRequest } from './components/login/reset-password/password-reset-request.component';
import Homepage from './components/homepage/homepage.component';
import UpdateProfile from './components/update-profile/update-profile.component';
import Payment from './components/payment/payment.component';
import { PaymentSelection } from './components/payment-selection/payment-selection.component';
import Landing from './components/ftui/landing/landing.component';
import SimpleRegistration from './components/ftui/simple-registration/simple-registration.component';
import DisplayName from './components/ftui/display-name/display-name.component';
import AvatarSelection from './components/ftui/avatar-selection/avatar-selection.component';
import GDPR from './components/ftui/gdpr/gdpr.component';
import Plans from './components/ftui/plan-selection/plan-selection.component';
import { FortumoPlanSelection } from './components/fortumo/fortumo-plan-selection.component';
import CardDetails from './components/ftui/card-details-payment/card-details-payment.component';
import AccountCreated from './components/ftui/account-created/account-created.component';
import PromoCodeComponent from './components/promo-code/promo-code.component';
import DocomoRegistration from './components/docomo-register/docomo-registration.component';
import DocomoAcknowledge from './components/docomo-register/docomo-acknowledge/docomo-acknowledge.component';
import ThankYou from './components/thank-you/thank-you.component';

export default function () {
    return (
        <Router history={history}>
            <Route exact path={ROUTES.SPLASH.path}					component={Splash} />
            <Route exact path={ROUTES.LOGIN_CREDENTIALS.path}		component={LoginCredentials} />
            <Route exact path={ROUTES.SIGNUP_AVATAR.path}			component={SignupAvatar} />
            <Route exact path={ROUTES.RESET_PASSWORD.path}			component={ResetPassword} />
            <Route exact path={ROUTES.PASSWORD_RESET_REQUEST.path}	component={PasswordResetRequest} />
            <Route exact path={ROUTES.HOMEPAGE.path}				component={Homepage} />
            <Route exact path={ROUTES.UPDATE_PROFILE.path}			component={UpdateProfile} />
            <Route exact path={ROUTES.PAYMENT.path}					component={Payment} />
            <Route exact path={ROUTES.PAYMENT_SELECTION.path}		component={PaymentSelection} />
            <Route exact path={ROUTES.LANDING.path} 	 	  	 	component={Landing} />
            <Route exact path={ROUTES.CREATE_USER.path}				component={SimpleRegistration} />
            <Route exact path={ROUTES.DISPLAY_NAME.path} 			component={DisplayName} />
            <Route exact path={ROUTES.AVATAR_SELECTION.path} 	 	component={AvatarSelection} />
            <Route exact path={ROUTES.GDPR.path} 	 	  	 		component={GDPR} />
            <Route exact path={ROUTES.PLANS.path} 	 	  	 		component={Plans} />
            <Route exact path={ROUTES.FORTUMO.path} 	 	  	 	component={FortumoPlanSelection} />
            <Route exact path={ROUTES.CARD_DETAILS.path} 	 	  	component={CardDetails} />
            <Route exact path={ROUTES.ACCOUNT_CREATED.path} 	 	component={AccountCreated} />
            <Route exact path={ROUTES.PROMO_CODE.path} 	 			component={PromoCodeComponent} />
            <Route exact path={ROUTES.DOCOMO_REGISTRATION.path} 	component={DocomoRegistration} />
            <Route exact path={ROUTES.DOCOMO_ACKNOWLEDGE.path} 	 	component={DocomoAcknowledge} />
            <Route exact path={ROUTES.THANK_YOU.path} 	 	        component={ThankYou} />
        </Router>
    );
}
