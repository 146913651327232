
import React, { Component } from 'react';
import {Field, reduxForm, Form} from 'redux-form';
import {Button} from '../../common/button/common-button.component';
import {Input} from '../../common/input/input.component'
import GenericPopup, {TYPE_HAS_TWO_BUTTONS} from '../../popup/generic-popup/generic-popup.component';
import {addPopup} from '../../popup/popup.component';

import {
	emojiValidator,
	maxLengthValidator,
	minLengthValidator,
	requiredValidator,
	trimValidator,
	emailValidator,
} from '../../common/input/input.validator';
import Checkbox from '../../common/checkbox/checkbox.component';

const maxValue20 = maxLengthValidator(20);
const minValue6 = minLengthValidator(6);
class RegistrationComponent extends Component {
    
	constructor(props){
		super(props);

		this.state = {
			checkboxChecked:false
		};
    }
    
	render () {
        const { handleSubmit, valid, loading, submitting, requirePrivacy, toggleChecked } = this.props
        const passwordValidation = [ minValue6, maxValue20, requiredValidator, emojiValidator, trimValidator]

        return (
            <div className="form-field-container">
                <Form onSubmit={handleSubmit(this.props.submitForm)} >
                    <div className="input-field-container">
                        <Field
                            name="email"
                            component={Input}
                            placeholder="Email Address"
                            validate={[ requiredValidator, emailValidator, trimValidator]}
                        />
                        <Field
                            name="password"
                            type="password"
                            component={Input}
                            placeholder="Password"
                            validate={passwordValidation}
                        />
                    </div>
                    {requirePrivacy && <div className="privacy_policy_container">
                        <Checkbox 
                            name="privacy_policy_terms"
                            checkboxToggled={(checked) =>this.setState({checkboxChecked: toggleChecked()})}
                            initallyChecked={false}
                            validate={this.isChecked}
                        />
                        <label className="privacy_policy-text" htmlFor="privacy_policy_terms">I accept the privacy policy, and consent to being contacted by Antstream with updates.
                            Read more... <a href="https://www.antstream.com/privacy" target="_blank" rel="noopener noreferrer">www.antstream.com/privacy</a></label>
                    </div>}
                    
                    <div className="buttons-container">
                        <Button
                            className="secondary"
                            onClick={() => {
                                addPopup(
                                    <GenericPopup
                                        title="Warning"
                                        message={"You will lose all your progress data. Are you sure you want to cancel?"}
                                        buttonType={TYPE_HAS_TWO_BUTTONS}
                                        okButtonLabel="Yes"
                                        cancelButtonLabel="No"
                                        onOkClicked={()=>{window.location = "https://www.antstream.com";}}
                                    />
                                );
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="primary"
                            disabled={!valid || loading || submitting}
                            type="submit"
                        >
                            Create User
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

                
export default reduxForm({
	form: 'registration'
})(RegistrationComponent);

export const Registration = RegistrationComponent;