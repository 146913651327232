import React, { Component } from 'react';
import { get } from 'lodash';

import './input.component.less';
import gamePad, { PLATFORM_TYPE_FIRE_TV } from '../../../assets/lib/gamepad.lib';

class Input extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: [],
			selected:false
		};
	}

	componentDidMount() {
		if(this.refs.input)
			this.refs.input.focusFunction = this.onCustomFocus;
	}

	onCustomFocus = (focus/*, nextElement*/) => {
		this.setState({selected:focus});
		if(!this.refs.input)return;

		if(focus) {
			if(gamePad.platformType!==PLATFORM_TYPE_FIRE_TV) {
				this.refs.input.focus();
			}else {
				this.refs.containerRef.focus();
			}
		} else {
			this.refs.input.blur();
		}
	};

	onInputClicked = () => {
		if(gamePad.platformType===PLATFORM_TYPE_FIRE_TV) {
			this.refs.input.focus();
		}
		if(this.refs.input && this.refs.input.setAsSelectedElement) {
			this.refs.input.setAsSelectedElement();
		}
	};

	focus = () => {
		this.refs.input.focus();
	};

	setValue(value) {
		this.refs.input.value = value;
	}

	getValue = () => {
		const value = get(this.refs, 'input.value');
		// in case we are not using an external form library this one handles errors if the validate array is passed
		if (this.props.validate) {
			const errors = [];
			this.props.validate.forEach( (validator) => {
				const res = validator(value);
				if (res) errors.push(res);
			});
			this.setState({errors: errors});			
		}
		return value;
	};

	renderError = (props) => {
		const meta = get(props, 'meta', {});
		if (meta.visited && meta.error) {
			return <div className="validation-error"><span>{meta.error} !</span></div>;
		}
		return null;
	};

	onKeyDown = (event) => {
		if(event.keyCode===13){
			//event.preventDefault();
		}
	};

	render() {
		const props = this.props;
		let containerClass = props.containerClass || '';
		containerClass += props.disabled ? ' disabled ' : '';
		return (
			<div ref="containerRef" className={`input-container ${containerClass}`}>
				{this.renderError(props)}
				<input
					autoFocus={props.autoFocus}
					className={`${this.state.selected?'selected':''}`}
					{...props.input}
					disabled={props.disabled}
					ref={'input'}
					autoComplete={'off'}
					type={props.type || 'text'}
					placeholder={props.placeholder || ''}
					defaultValue={props.defaultValue}
					onClick={this.onInputClicked}
					tabIndex={0}
					onKeyDown={this.onKeyDown}
				/>
			</div>
		);
	}
}

export { Input };
