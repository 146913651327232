const initialState = {
};

export const homepageReducer = (state = initialState, action) => {
	switch (action.type) {
	// case ACTIONS.SCROLL_TOP_ACTION :
	// 	return {
	// 		...state,
	// 		scrollTopActive: action.payload,
	// 	};

	default:
		return state;
	}
};
