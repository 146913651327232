import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getIsAppInitialised } from '../../app.selectors';
import { AppInitAction } from '../../app.actions';
import { navigateToSavedLocation } from '../../app.router';
import logo from '../../assets/images/splash/antstream_logo_vector.svg';
import './splash.component.less';


class SplashComponent extends Component {
	componentDidMount() {
		this.props.dispatch(AppInitAction());
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (newProps.appIsInitialized) {
			navigateToSavedLocation();
		}
	}

	render() {
		return (
			<section className="splash-component">
				<img src={logo} className="logo" alt="logo" />
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		appIsInitialized: getIsAppInitialised(state),
	};
};

export const Splash = connect(mapStateToProps)(SplashComponent);
