import React, {Component} from 'react';

import asyncScriptLoader from "react-async-script-loader";
import {getFortumoPaymentObject} from "../login/login.actions";
import {connect} from "react-redux";
import {addPopup} from "../popup/popup.component";
import GenericPopup from "../popup/generic-popup/generic-popup.component";
import {getIsUserSubscribed} from "../../app.selectors";

class FortumoButtonComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isScriptLoaded: false
		}

		this.STATUS_TYPES = {
			FAILED: 'failed',
			CHARGED: 'charged',
		}

		this.statusType = null;
	}

	componentDidMount() {
		const {isScriptLoaded, isScriptLoadSucceed} = this.props;
		this.checkScript(isScriptLoaded, isScriptLoadSucceed);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const {isScriptLoaded, isScriptLoadSucceed} = nextProps;
		this.checkScript(isScriptLoaded, isScriptLoadSucceed);
	}

	checkScript = (isScriptLoaded, isScriptLoadSucceed) => {
		if (isScriptLoaded && !this.props.isScriptLoaded) {
			if (isScriptLoadSucceed) {
				window.addEventListener("message", this.receiveMessage, false);
				this.setState({isScriptLoaded: true});
			} else {
				console.log('Cannot load Fortumo script!');
			}
		}
	}

	receiveMessage = (event) => {
		const eventOrigin = event.origin;
		const data = event.data;
		if (eventOrigin !== 'https://dcb.fortumo.com' || !data) return;

		const status = data.status;
		const error = data.error;
		const dataDo = data.do;

		if (status) this.statusType = status;

		if (!error && dataDo && dataDo === 'CLOSE_LIGHTBOX_AND_REDIRECT') {
			if (this.statusType === this.STATUS_TYPES.CHARGED) {
				this.props.dispatch(getFortumoPaymentObject());
				this.props.onPaymentApproved();
			} else {
				this.props.refreshConfig();
			}
		}
	}

	render() {
		if (this.state.isScriptLoaded) {
			const fortumoButton = document.getElementById("fortumoButton");
			fortumoButton.innerHTML = '';
			window.HdcbLightbox.addPaymentButton('fortumoButton', this.props.config);
		}

		return (
			<div className='fortumo-button-component' id='fortumoButton'/>
		)
	}
}

const mapStateToProps = (state) => {
	const isSubscribed = getIsUserSubscribed(state);

	return {
		isSubscribed
	}
};

export const FortumoButton = connect(mapStateToProps)(asyncScriptLoader('https://unpkg.com/@fortumo/hdcb-lightbox@latest/dist/HdcbLightbox.min.js')(FortumoButtonComponent));