import _ from 'lodash';
import AudioManager, { TYPE_VERTICAL_LIST_ROW_CHANGE} from '../../app.audio';

let inputManagerInstance = null;
class InputManager {
	constructor() {
		this.mapLayers = [];
		this.lastPositionOnBaseLayer = null;

		this.layerIndex = 0;
		this.rowIndex = 0;
		this.columnIndex = 0;
		this.currentElement = null;

		this.emitEvent = false;
	}

	setLayer(layer) {
		if(this.layerIndex !== layer){
			if(layer===0 && this.lastPositionOnBaseLayer){
				this.layerIndex = layer;
				this.setCurrentElement(layer,this.lastPositionOnBaseLayer.row,this.lastPositionOnBaseLayer.column);
				this.lastPositionOnBaseLayer = null;
			} else {
				if(this.layerIndex===0 && this.currentElement){
					this.lastPositionOnBaseLayer = {row:this.rowIndex,column:this.columnIndex};
				}
				this.clearCurrentElement();
				this.layerIndex = layer;
			}
		}
	}

	handleInputEvent(key) {
		const layerIndex = this.layerIndex;
		const mapLayer = this.mapLayers[layerIndex];
		if(!mapLayer) return;

		let rowIndex = mapLayer[this.rowIndex] ? this.rowIndex : 0;
		let columnIndex = this.columnIndex;

		if( key === 'button_1') {
			AudioManager.playAudio(TYPE_VERTICAL_LIST_ROW_CHANGE);
			if(this.currentElement)this.currentElement.click();
			if(this.emitEvent) window.dispatchEvent(new Event('input_manager_click'));
		} else if( key === 'd_pad_left' || key === 'd_pad_right' || key === 'd_pad_up' || key === 'd_pad_down' || key === 'tab') {
			if(key === 'tab') {
				const nextColumn = this.getElement(this.layerIndex,this.rowIndex,this.columnIndex+1);
				if(nextColumn){
					columnIndex++;
				} else {
					columnIndex = 0;
					rowIndex++;
				}
			} else if(key === 'd_pad_left') {
				columnIndex--;
				if(this.emitEvent) window.dispatchEvent(new Event('left'));
			} else if (key === 'd_pad_right') {
				columnIndex++;
				if(this.emitEvent) window.dispatchEvent(new Event('right'));
			} else {
				if(key === 'd_pad_up') {
					rowIndex--;
					if(rowIndex < 0) rowIndex = 0;
				} else if (key === 'd_pad_down') {
					rowIndex++;
					if(rowIndex > mapLayer.length-1) rowIndex = mapLayer.length-1;
				}

				columnIndex = columnIndex + this.getOffsetEffect(layerIndex, rowIndex, this.currentElement);
				if(this.getRow(layerIndex,rowIndex))columnIndex = Math.min(Math.max(columnIndex,0),this.getRow(layerIndex,rowIndex).columns.length-1);
				columnIndex = this.getCurrentColumnOfRow(layerIndex, rowIndex, columnIndex);
			}

			const row = this.getRow(layerIndex,rowIndex);
			if(row){
				columnIndex = Math.min(Math.max(columnIndex,0),row.columns.length-1);
				this.setCurrentElement(layerIndex,rowIndex,columnIndex,true);
			}
		}
	}

	getElement(layerIndex, rowIndex, columnIndex) {
		const row = this.getRow(layerIndex,rowIndex);
		if(!row) return null;

		const column = row.columns[columnIndex];
		if(!column) return null;

		return column;
	}

	getRow(layerIndex, rowIndex) {
		const row = this.mapLayers[layerIndex][rowIndex];
		if(row){
			return row;
		}else{
			return this.mapLayers[layerIndex][0];
		}
	}

	setCurrentElement(layerIndex, rowIndex, columnIndex, withInput) {
		return;//remove this to enable input manager

		// if(layerIndex!==this.layerIndex) return;
		// // console.log(layerIndex, rowIndex, columnIndex);
		// const element = this.getElement(layerIndex, rowIndex, columnIndex);
		// if(element && element!==this.currentElement) {
		// 	this.rowIndex = rowIndex;
		// 	this.columnIndex = columnIndex;
		// 	if(!element.navType || element.navType !== 'tile') this.setCurrentColumnOfRow(layerIndex, rowIndex, columnIndex);
		// 	if(this.currentElement !== element) this.clearCurrentElement(element);
		// 	this.focusElement(element);
		// 	this.currentElement = element;
		// 	if(withInput)AudioManager.playAudio(TYPE_LIST_TILE_CHANGE);
		// }
	}

	clearCurrentElement(nextElement) {
		if(this.currentElement){
			this.blurElement(this.currentElement,nextElement);
			this.currentElement = null;
		}
	}

	setCurrentColumnOfRow (layerIndex, rowIndex, columnIndex) {
		const row = this.getRow(layerIndex,rowIndex);
		row.currentColumn= columnIndex;
	}

	getCurrentColumnOfRow (layerIndex, rowIndex, columnIndex) {
		const row = this.getRow(layerIndex,rowIndex);
		if(!row)return columnIndex;
		const element = this.getElement(layerIndex,rowIndex,columnIndex);
		const navType = _.get(element,'navType');
		if(navType!=='tile' && navType!=='vTile' && row.currentColumn!==undefined) {
			return row.currentColumn;
		} else {
			return columnIndex;
		}
	}

	focusElement(element) {
		if(element.focusFunction)element.focusFunction(true);
	}

	blurElement(element,nextElement) {
		if(element.focusFunction)element.focusFunction(false,nextElement);
	}

	getOffsetEffect(layerIndex,rowIndex,oldElement) {
		const newFirstElement = this.getElement(layerIndex,rowIndex,0);
		let newOffset = _.get(newFirstElement, 'parentNode.parentNode.parentNode.globalOffset');
		if(!newOffset) newOffset = 0;

		let oldOffset = _.get(oldElement, 'parentNode.parentNode.parentNode.globalOffset');
		if(!oldOffset) oldOffset = 0;

		return newOffset - oldOffset;
	}

	selectHomePageButton() {
		this.setCurrentElement(0, 0, 1);
	}

	removeRow(rowId, navLayer) {
		return;//remove this to enable input manager

		// const currentMap = this.mapLayers[navLayer];
		// if(!currentMap) return;
		// _.remove(this.mapLayers[navLayer], (row) => {
		// 	return row.id===rowId;
		// });
		// console.log('removeRow', rowId, this.mapLayers[navLayer]);
	}

	refreshNavMap(navId, navLayer) {
		return;//remove this to enable input manager
		//
		// while(!this.mapLayers[navLayer]) this.mapLayers.push([]);
		//
		// this.removeRow(navId,navLayer);
		// const navigableElements = document.getElementsByClassName(`${navId}-navigable`);
		// const navElements = [];
		// for(let j = 0; j < navigableElements.length; j++) {
		// 	navElements.push(navigableElements[j]);
		// }
		// const navObject = {};
		// navObject.id = navId;
		// navObject.navElements = navElements;
		// navObject.navLayer = navLayer;
		// this.generateRowsAndColumns(navObject);
	}

	generateRowsAndColumns (navObject) {
		if(navObject.navElements.length<1) return null;
		for(let i=0;i<navObject.navElements.length;i++) {
			const navElement = navObject.navElements[i];
			const boundingRect = navElement.getBoundingClientRect();
			navElement.x = boundingRect.left;
			navElement.y = boundingRect.top;
		}
		//update row's First items bounds to be sure about using latest vertical positions
		this.mapLayers[navObject.navLayer].forEach(row => {
			const firstItem = row.columns[0];
			const boundingRect = firstItem.getBoundingClientRect();
			firstItem.x = boundingRect.left;
			firstItem.y = boundingRect.top;
		});

		const elements = [...navObject.navElements];
		//loop through Y positions
		while (elements.length>0) {
			const firstColumn = elements.shift();
			//create row object
			const rowObject = {
				id:navObject.id,
				columns:[ firstColumn ]
			};

			//loop through x positions
			for(let x = 0; x < elements.length; x++) {
				//get first X position
				const currentX = elements[x];
				//get the absolute value of Y position to make sure elements match
				const absoluteY = Math.abs(firstColumn.y - currentX.y);

				if(absoluteY < 35) {
					//push column to row
					rowObject.columns.push(currentX);
					//remove element from sorted
					elements.splice(x,1);
					x-=1;
				}
			}

			rowObject.columns = rowObject.columns.sort((a,b)=>{
				return a.x-b.x;
			});

			//push this row object
			this.mapLayers[navObject.navLayer].push(rowObject);
		}

		//sort rows together with new objects
		this.mapLayers[navObject.navLayer] = this.mapLayers[navObject.navLayer].sort((a,b) => {
			return a.columns[0].y - b.columns[0].y;
		});

		this.setElementProps(navObject);
		// console.log('gen nav map for ', navObject.id, this.mapLayers[navObject.navLayer]);
	}

	setElementProps(navObject) {
		for(let i=0;i<this.mapLayers[navObject.navLayer].length;i++) {
			for(let j=0;j<this.mapLayers[navObject.navLayer][i].columns.length;j++) {
				const column = this.mapLayers[navObject.navLayer][i].columns[j];
				column.setAsSelectedElement = () => {
					if(this.currentElement!==column){
						this.setCurrentElement(navObject.navLayer, i, j,true);
					}
				};
			}
		}
	}
}

const inputManager = () => {
	if(inputManagerInstance === null ) inputManagerInstance = new InputManager();
	return inputManagerInstance;
};

export default inputManager();
