import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { ROUTES, navigateToLocation } from '../../../app.router';
import {getIsLoggedIn} from '../../../app.selectors';
import { getLoading } from '../login.selectors';
import { Button } from '../../common/button/common-button.component';
import { Input } from '../../common/input/input.component';
import {requiredValidator, emailValidator, trimValidator} from '../../common/input/input.validator';
import {RequestPasswordResetAction, SetEmailPasswordFieldsAction} from '../login.actions';
import inputManager from '../../../assets/lib/inputmanager';
import '../login-global.less';
import logo from '../../../assets/images/splash/antstream_logo_vector.svg';
import {Loader} from "../../common/loader/loader.component";

const navId='reset-pass';
const navLayer=0;
class PasswordResetRequestComponent extends Component {
	constructor() {
		super();
		this.state = {};
		this.back = this.back.bind(this);
	}

	componentDidMount() {
		inputManager.refreshNavMap(navId, navLayer);
		inputManager.setCurrentElement(navLayer,0,0);
	}

	componentWillUnmount() {
		inputManager.removeRow(navId, navLayer);
	}

	onRequestReset = (values) => {
		const email = values.email.toLowerCase();
		this.props.dispatch(SetEmailPasswordFieldsAction(email,''));
		this.props.dispatch(RequestPasswordResetAction(email));
	};
	
	back = () => {
		if(!this.props.isLoggedIn) {
			navigateToLocation(ROUTES.LOGIN_CREDENTIALS);
		} else {
			navigateToLocation(ROUTES.UPDATE_PROFILE);
		}
	};
	
	render () {
		if (this.props.loading) {
			return (
				<div className="loader-games">
					<Loader loading={true}/>
				</div>
			);
		}

		return(
			<div className="login-wrapper password-reset-request-component">
				<section className="login-section login-section--left">
					<div className="logo-container">
						<img src={logo} alt="logo" />
					</div>
					<h1 className="title">Password Reset</h1>
					<p>
						Enter your email address that you used to register. We'll send you an email with a token to reset your password.
					</p>
					<form
						onSubmit={event => event.preventDefault()}
					>
						<Field name="email"
							component={Input} placeholder="Email"
							validate={[ requiredValidator, emailValidator ,trimValidator ]}
							navProps={navId}
						/>
						<Button
							disabled={this.props.loading}
							className="secondary right-button"
							onClick={this.props.handleSubmit(this.onRequestReset)}
							navProps={navId}
							type="submit"
						>
							Send Email
						</Button>
						<Button
							className="secondary right-button"
							onClick={this.back}
							navProps={navId}
						>
							Back
						</Button>
					</form>
					<p>If you still need help, contact support@antstream.zendesk.com</p>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { 
		isLoggedIn : getIsLoggedIn(state),
		loading: getLoading(state)
	};
};
  
PasswordResetRequestComponent = connect(mapStateToProps)(PasswordResetRequestComponent);

export const PasswordResetRequest = reduxForm({
	form: 'passwordResetRequest',
})(PasswordResetRequestComponent);
