import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import CardSection from './CardSection';
import { Button } from '../common/button/common-button.component';
import './cardSection.less';
import PaymentRequestForm from './payment-request-form';
import {PAYMENT_TYPE_BY_CARD, PAYMENT_TYPE_BY_REQUEST_BUTTON} from '../payment/payment.component';
import inputManager from '../../assets/lib/inputmanager';
import {addPopup} from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';

const navId = 'payment-component';
const navLayer = 0;
let resetPayNow = null;

class CheckoutForm extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			submitted:false,
			paymentType:null,
		};
		
		resetPayNow = this.resetPayNowButton;
	}

	componentDidMount() {
		this.refs.checkoutFormRef.focus();
	}

	componentWillUnmount() {
		inputManager.removeRow(navId, navLayer);
	}

	setNavMap = () => {
		inputManager.refreshNavMap(navId,navLayer);
		inputManager.setCurrentElement(navLayer,0,0);
	};

	handleSubmit = (ev) => {
		// We don't want to let default form submission happen here, which would refresh the page.
		if(ev) ev.preventDefault();
		// Within the context of `Elements`, this call to createToken knows which Element to
		// tokenize, since there's only one in this group.

		if(!this.state.submitted) {
			this.setState({
				submitted:true,
			});
			this.props.stripe.createPaymentMethod('card').then(({paymentMethod,error}) => {
				if(paymentMethod) {
					console.log("[CheckoutForm:handleSubmit] paymentMethod data:", paymentMethod);
					this.props.onStripePaymentMethod(paymentMethod);
				} else if(error) {
					console.log("[CheckoutForm:handleSubmit] ERROR:", error);
					addPopup(
						<GenericPopup
							title = "Warning"
							message = {error.message}
						/>
					);
					this.setState({
						submitted:false,
					});
				}
			}).catch(() => {
				this.setState({
					submitted:false,
				});
			});
		}
	};

	resetPayNowButton = () => {
		this.setState({
			submitted: false,
		});
		this.forceUpdate();
	};

	onLaterButtonClicked = () => {
		if(this.props.onLaterButtonClicked)this.props.onLaterButtonClicked();
	};

	onCanMakePayment = (canPayByRequest) => {
		if(canPayByRequest) {
			this.setState({paymentType:PAYMENT_TYPE_BY_REQUEST_BUTTON});
		}else {
			this.setState({paymentType:PAYMENT_TYPE_BY_CARD},()=>this.setNavMap());
		}
	};

	renderCardSection = () => {
		return (
			<form className="card-form" onSubmit={this.handleSubmit}>
				<CardSection/>
			</form>
		);
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13){
			this.handleSubmit();
		}
	};

	onBackKeyDown = (event) => {
		if(event.keyCode === 13){
			this.onLaterButtonClicked();
		}
	};

	render() {
		return (
			<div className="checkout-form" ref="checkoutFormRef" tabIndex="-1">
				<PaymentRequestForm
					stripe={this.props.stripe}
					onCanMakePayment = {this.onCanMakePayment}
					onStripeToken = {this.props.onStripeToken}
					onStripePaymentMethod = {this.props.onStripePaymentMethod}
					navProps={navId}
					selectedPaymentPlan = {this.props.selectedPaymentPlan}
					onKeyDown={this.onKeyDown}
				/>
				{
					this.renderCardSection()
				}
				<div className="buttons-container">
					<Button
						className="secondary"
						onClick={this.onLaterButtonClicked}
						navProps={navId}
						onKeyDown={this.onBackKeyDown}
					>
						Back
					</Button>
					<Button
						className = "secondary"
						disabled = {this.state.submitted}
						onClick = {this.handleSubmit}
						navProps={navId}
						onKeyDown={this.onKeyDown}
						withEventObject
					>
						Next
					</Button>
				</div>
			</div>
		);
	}
}

export {resetPayNow};
export default injectStripe(CheckoutForm);
