import {readCustomPlanData} from './assets/lib/local-storage';
import {isFreeTierV2FeatureFlagEnabled} from "./assets/lib/utils";

export const getIsAppInitialised = state => state.app.initialised;
export const getLoggedUser = state => state.app.loggedUser;
export const getIsLoggedIn = state => state.app.loggedUser && state.app.loggedUser._id;
export const getIsUserSubscribed = state => state.app.paymentData ? state.app.paymentData.subscription || state.app.paymentData.token : false ;
export const getPaymentData = state => state.app.paymentData;
export const getUploadUrl = state => state.app.uploadUrl;
export const getAppLoading = state => state.app.loading;
export const getAppIsDisplayNamePending = state => state.app.isDisplayNamePending;

export const getRoutingSearch = state => state.routing.search;
export const getRoutingSearchIsFtui = state => state.routing.search ? state.routing.search.isFtui : null;
export const getSessionData = state => state.routing.sessionData;
export const getSavedRoute = state => state.routing.savedRoute;
export const getCurrentRoute = state => state.routing.currentRoute;
export const getRoutingAuthToken = state => state.routing.search && state.routing.search.authToken;

export const getIpStackObject = state => state.app.ipStackObject;
export const getCurrencyCode = state => state.app.ipStackObject && state.app.ipStackObject.currency && state.app.ipStackObject.currency.code;

export const getAssetsUrl = state => state.config.assetsUrl;
export const getFolderTypes = state => state.config.folderTypes;

export const getOrigin = state => state.routing.origin;
export const getPaymentMethod = state => state.routing.paymentMethod;
export const getPlatform = state => state.routing.platform;

const supportedCurrencies = ['gbp','usd','eur'];
const defaultCurrency = 'usd';

export const getPromoCodeDiscount = state => {
	const paymentData = state.app.paymentData;

	if(paymentData && paymentData.promoCodeObject && paymentData.promoCodeObject.data && paymentData.promoCodeObject.data.percent_off) {
		return paymentData.promoCodeObject.data.percent_off;
	}

	return null;
};

export const getPromoLimit = state => {
	const paymentData = state.app.paymentData;

	if(paymentData && paymentData.promoCodeObject && paymentData.promoCodeObject.subscriptionLimit) {
		return paymentData.promoCodeObject.subscriptionLimit;
	}

	return "all";
}

const filterStandardPlans = (plans, isFreeTierV2) => {
	if (isFreeTierV2) {
		return plans.filter(plan=>{
			return plan.term === "monthly_V2.2" || plan.term === "annual";
		})
			.sort((a, b) => b.price - a.price);
	}

	return plans.filter(plan=>{
		return (plan.term === "monthly" || plan.term === "annual" || plan.term === "daily");
	});
}

const filterCustomPlans = (plans, customPlans) => {
	plans = plans.filter(plan=>{
		return plan.term.indexOf("custom") > -1;
	});
	return plans.filter(plan=>{
		return customPlans.includes(plan.sku)
	});
}

const filterCurrency = (state, plans) => {
	let currencyCode = getCurrencyCode(state);
	let currentCurrency = defaultCurrency;
	if(currencyCode) {
		currencyCode =  currencyCode.toLowerCase();
		if(supportedCurrencies.includes(currencyCode)){
			currentCurrency =currencyCode;
		}
	}
	return plans.filter(plan=>{
		return plan.currency===currentCurrency
	});
}

export const getPaymentPlans = state => {
	let plans = JSON.parse(JSON.stringify(state.app.paymentPlans));

	const planData = readCustomPlanData();
	const customPlans = planData && planData.plans ? planData.plans.split(",") : null;

	const isFreeTierV2 = isFreeTierV2FeatureFlagEnabled(state);

	if (plans && customPlans) {
		plans = filterCustomPlans(plans, customPlans);
	} else if(plans) {
		plans = filterStandardPlans(plans, isFreeTierV2);
	}

	if (plans) {
		plans = filterCurrency(state, plans);
		return plans.length > 0 ? plans : filterCurrency(state, filterStandardPlans(JSON.parse(JSON.stringify(state.app.paymentPlans)), isFreeTierV2));
	}
	return null;
};

export const getPaypalPlans = state => {
	let plans = state.app.paypalPlans;

	if(plans) {
		plans = filterCurrency(state, plans);
		return plans;
	}
	return null;
};

export const getFortumoPlans = state => {
	return state.app.fortumoPlans;
};

export const getFortumoConfig= state => {
	return state.app.fortumoConfig;
};

export const getAssetsUrlWithFolder = (state,folderType) => {
	const assetsUrl  = getAssetsUrl(state);
	const folderTypes  = getFolderTypes(state);

	return assetsUrl+folderTypes[folderType]["folder"];
};