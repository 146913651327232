import React, {Component} from 'react';
import './paypal-button.component.less';
import { fetchPostAuthenticationAction, onPaypalCheckoutResponse } from '../login/login.actions';
import {connect} from "react-redux";
import {getPaymentData, getPaypalPlans} from "../../app.selectors";
import {usingLivePayment} from "../../app.helpers";
import { addPopup } from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import { readRestUserData } from '../../assets/lib/local-storage';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const CLIENT = {
    sandbox:
        "AXnuOWqq15_Ia6xNqS4DFINOMXOybBnPXxDlCidX6cyIVrej1O4LgD-b5B3WQnxzE1j48IwSrz_0DKTF",
    production:
        "Aa6HONoupS-YqPfVLMMrcH4hOJbp5wtxdmYsATmtQ7XMl9dffAFPVIn45Wl5WFF0i1dArN0iI7FtDI-A"
};
const CLIENT_ID = (usingLivePayment() ? CLIENT.production : CLIENT.sandbox);

/**
 * @link https://developer.paypal.com/sdk/js/configuration
 **/
const initialPayPalOptions = {
    clientId: CLIENT_ID,
    disableFunding: ['card'],
    vault: true
};

class PaypalButtonComponent extends Component {
    createSubscription = async (data, actions) => {
        console.log("createSubscription=>data", data);
        let planId = null;

        /**
         * Check if user already subscribed.
         * Sometimes paypal webhooks can be slow and user can be already subscribed but UI cannot be updated yet.
         * In order to prevent duplicate subscription case we need to check this.
         **/
        if (await this.isUserAlreadySubscribed()) {
            addPopup(<GenericPopup
                okButtonLabel='Ok'
                title='Already subscribed'
                message='You are already subscribed. Please wait a minute or refresh the page.'
            />);
            return;
        }

        if (this.props.plan && this.props.paypalPlans) {
            const selectedPaypalPlan = this.props.paypalPlans.find(paypalPlan=>{
                if ((this.props.plan.price.toFixed(2)*100) === paypalPlan.amount) {
                    return !paypalPlan.hasTrial;  // As of 2022, always use plans with no trial
                }
                return null;
            });
            console.log("selectedPaypalPlan",selectedPaypalPlan);
            planId = selectedPaypalPlan.id;
        }

        const restUserData = readRestUserData();

        return actions.subscription.create({
            // this is player ID (custom_id) will be passed into backend webhook (BILLING.SUBSCRIPTION.CREATED)
            // so even if something is wrong with /payment/paypal-checkout request, and subscription is not created, the backend should pick up the playerId and create the subscription on BILLING.SUBSCRIPTION.ACTIVATED webhook
            custom_id: restUserData?.userId,
            plan_id: planId
        });
    };

    onApprove = (data, actions) => {
        this.props.dispatch(onPaypalCheckoutResponse(data));
        if (this.props.onApprove) this.props.onApprove();
    };

    onInit = (data, actions) => {
        console.log("onInit=>Data", data);
        console.log("actions", actions);
    };

    onCancel = (data, actions) => {
        console.log("onCancel=>Data", data);
        console.log("actions", actions);
    };

    onError = (data, actions) => {
        console.log("onError=>Data", data);
        console.log("actions", actions);
    };

    isUserAlreadySubscribed = async () => {
        let subscribed = false;

        await new Promise((resolve) => {
            this.props.dispatch(fetchPostAuthenticationAction(
                undefined,
                undefined,
                true,
                (res) => {
                    subscribed = res?.data?.paymentData?.subscription || false;
                    resolve();
                }));
        });

        return subscribed;
    };

    render() {
        return (
            <div className="paypal-button-component">
                <div className="separator">OR</div>
                <PayPalScriptProvider options={initialPayPalOptions}>
                    <PayPalButtons
                        createSubscription={this.createSubscription}
                        style={{color: 'white',height: 55}}
                        onApprove={this.onApprove}
                        onInit={this.onInit}
                        onCancel={this.onCancel}
                        onError={this.onError}
                    />
                </PayPalScriptProvider>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const paymentData = getPaymentData(state);
    const paypalPlans = getPaypalPlans(state);
    return {
        paymentData,
        paypalPlans
    };
};

export const PaypalButton = connect(mapStateToProps)(PaypalButtonComponent);