import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./plan-selection.component.less";
import {readCustomPlanData} from '../../../assets/lib/local-storage';
import NavigationBar, {NAV_BAR_ITEM_TYPES} from '../navigation-bar/navigation-bar.component';
import {PaymentSelection} from '../../payment-selection/payment-selection.component';
import {Button} from '../../common/button/common-button.component';
import {navigateToLocation, ROUTES} from '../../../app.router';
import {ClearCurrentSku} from '../../login/login.actions';
import {SaveRoute} from '../../../app.actions';
import {PaypalButton} from "../../paypal/paypal-button.component";

class PlanSelectionComponent extends Component {
	constructor(props){
		super(props);
		this.nextClicked = false;

		this.state={
			isPaypalEnabled:false,
			selectedPlan:null
		}
	}

	componentDidMount() {
		this.refs.planSelectionRef.focus();
	}

	componentWillUnmount(){
		if(!this.nextClicked){
			this.props.dispatch(ClearCurrentSku());
		}
	}

	onCardPaymentClicked = () => {
		this.nextClicked = true;
		navigateToLocation(ROUTES.CARD_DETAILS);
	};

	onExitClicked = () => {
		navigateToLocation(ROUTES.HOMEPAGE);
	};

	onRedeemClicked = () => {
		this.props.dispatch(SaveRoute(ROUTES.PLANS));
		navigateToLocation(ROUTES.PROMO_CODE,{isFtui:true});
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13){
			this.onCardPaymentClicked();
		}
	};

	onPlanSelected = (plan) => {
		this.setState({selectedPlan:plan});
	};

	onPaypalStateUpdate = (isPaypalEnabled) => {
		this.setState({isPaypalEnabled});
	};

	onPaypalApproved = () => {
		navigateToLocation(ROUTES.ACCOUNT_CREATED);
	};

	render () {
		const hidePromo = this.props.hidePromo;
		return(
			<div className="plan-selection" ref="planSelectionRef" tabIndex="-1" onKeyDown={this.onKeyDown}>
				<div className="component-body">
					<NavigationBar currentItem={NAV_BAR_ITEM_TYPES.SUBSCRIPTION.type}/>
					<h1 className="component-title">Pick your plan</h1>
					<PaymentSelection
						asComponent
						onPaypalStateUpdate={this.onPaypalStateUpdate}
						onPlanSelected={this.onPlanSelected}
					/>
					<div className="text-container">
						<p>No commitment - cancel your subscription at any time</p>
						<div className="spacer"/>
					</div>

					<div className="buttons-container">
						<Button
							className="secondary"
							notSkewed
							onClick={this.onCardPaymentClicked}
						>
							Pay By Card
						</Button>
						{this.state.isPaypalEnabled && <PaypalButton plan={this.state.selectedPlan} onApprove={this.onPaypalApproved}/>}
						{!hidePromo && <Button
							className="secondary promo-button"
							onClick={this.onRedeemClicked}
						>
							Add promo code
						</Button>}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const planData = readCustomPlanData();
	const hidePromo = planData && planData.hidePromo ? planData.hidePromo : false;
	return {
		hidePromo
	};
};

export default connect(mapStateToProps)(PlanSelectionComponent);