import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./account-created.component.less";
import leftImageTop from '../../../assets/images/ftui/footer-images/faq_top.png';
import leftImageBottom from '../../../assets/images/ftui/footer-images/faq_bot.png';
import FtuiFooterComponent from '../ftui-footer/ftui-footer.component';
import { Button } from '../../common/button/common-button.component';
import { getPaymentMethod } from "../../../app.selectors";
import { ATTRIBUTION_ORIGIN_TYPES, PAYMENT_TYPES } from "../../../constants";
import { readCustomPlanData } from "../../../assets/lib/local-storage";
import ReactHelmet from 'react-helmet'
import {navigateToLocation, ROUTES} from '../../../app.router';

class AccountCreatedComponent extends Component {
	getContent = () => {
		const { paymentMethod, attributionOrigin } = this.props;

		if (attributionOrigin && attributionOrigin === ATTRIBUTION_ORIGIN_TYPES.ATARI) {
			return {
				title: 'Almost there!',
				content: <>
					<p>
						Your account is ready to rock!
					</p>
					<p>
						Just boot up the app once more and login with your new account details, and get playing!
					</p>
				</>,
				footerContent: 'If you need help later, please see our FAQs or contact us.'
			}
		} else {
			switch (paymentMethod) {
				case PAYMENT_TYPES.FORTUMO:
					return {
						title: 'Już prawie zakończone!',
						content: <>
							<p>
								Twoje konto jest gotowe do działania – wysłaliśmy Ci e-mail z linkiem weryfikacyjnym, aby kontynuować. Dodaj subscription@antstream.com do swoich kontaktów, aby zapobiec trafianiu ważnychwiadomości e-mail do folderu ze spamem.
							</p>
							<p>
								Następnieprzejdź do pobrania i instalacji Antstream, aby zacząćdziałać!
							</p>
						</>,
						policy: {
							url: "https://www.antstream.com/warunki-i-postanowienia",
							description: "Warunki i Postanowienia"
						},
						faq: {
							url: "https://www.antstream.com/poradnik-pierwszych-krok%C3%B3w",
							description: "Poradnik Pierwszych Kroków"
						},
						buttonTitle: 'Pobierz Antstream',
						footerContent: 'Jeślibędzieszpotrzebowaćpóźniej pomocy, zajrzyj do naszych FAQ lub skontaktuj się z nami.'
					}
				default:
					return {
						title: 'Almost there!',
						content: <>
							<p>
								Your account is ready to rock, and we’ve sent you an email with a magic verification link to proceed. This is to make sure you are not a retro robot trying to be reborn. Please add subscriptions@antstream.com to your contacts to prevent important emails going to your spam folder.
							</p>
							<p>
								Please then proceed to download and install Antstream to get to the action!
							</p>
						</>,
						buttonTitle: 'Manage Account',
						footerContent: 'If you need help later, please see our FAQs or contact us.'
					}
			}
		}
	}

	onSubscribeClicked= () => {
		navigateToLocation(ROUTES.PAYMENT_SELECTION);
	}
	onRedeemClicked= () => {
		navigateToLocation(ROUTES.PROMO_CODE);
	}
	onAccountClicked= () => {
		navigateToLocation(ROUTES.HOMEPAGE);
	}
	render() {
		const content = this.getContent();

		return (
			<div className="account-created">
				<ReactHelmet>
					{/* <!-- Event snippet for Website lead conversion page --> */}
					<script>gtag_report_conversion()</script>
				</ReactHelmet>
				<div className="component-body">
					<div className="text-container">
						<h1 className="title">{content.title}</h1>
						<div className="content-text">
							{content.content}
						</div>
						{content.policy &&
							<div><a href={content.policy.url} rel="noopener" target="_blank">
								{content.policy.description}</a>
							</div>
						}
						{content.faq &&
							<div><a href={content.faq.url} rel="noopener" target="_blank">
								{content.faq.description}</a>
							</div>
						}
					</div>
					{content.buttonTitle &&
						<div className="buttons-container">
							<Button
								className="secondary"
								isLink
								onClick={this.onAccountClicked}
							>
								{content.buttonTitle}
							</Button>
							<Button
								className="secondary"
								//isLink
								onClick={this.onSubscribeClicked}
							>
								Subscribe
							</Button>
							<Button
								className="secondary"
								isLink
								onClick={this.onRedeemClicked}
							>
								Redeem Code
							</Button>
						</div>
						
					}
				</div>
				<FtuiFooterComponent
					leftImageTop={leftImageTop}
					leftImageBottom={leftImageBottom}
					content={content.footerContent}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const paymentMethod = getPaymentMethod(state);
	const planData = readCustomPlanData();
	const attributionOrigin = planData && planData.attribution_origin ? planData.attribution_origin : null;

	return {
		paymentMethod,
		attributionOrigin
	};
};

export default connect(mapStateToProps)(AccountCreatedComponent);