import {Component} from 'react';
import { connect } from 'react-redux';
import React from 'react';
import './fortumo-plan-selection.component.less';
import {PaymentPlanItem} from '../payment-selection/payment-plan-item.component';
import {
	getFortumoConfig,
	getFortumoPlans,
	getIsUserSubscribed,
	getOrigin,
	getRoutingSearch,
	getSessionData
} from '../../app.selectors';
import {
	FetchPaymentPlans,
	GetFortumoConfigAction,
	getFortumoPaymentObject,
	SetCurrentSku
} from '../login/login.actions';
import {PAYMENT_TYPES} from "../../constants";
import {FortumoButton} from "./fortumo-button.component";
import {navigateToLocation, ROUTES} from "../../app.router";
import {Loader} from "../common/loader/loader.component";
import fortumoPayPlayImage from '../../assets/images/login/fortumopay_play.svg';

const MONTHLY_PLAN_TEXT = "Płać co miesiąc";
const WEEKLY_PLAN_TEXT = "Płać co tydzień";

class FortumoPlanSelectionComponent extends Component {
	constructor(props) {
		super(props);

		this.state={
			selectedPlan: null,
		}
	}

	componentDidMount() {
		this.refreshConfig();
		this.props.dispatch(FetchPaymentPlans([PAYMENT_TYPES.FORTUMO], this.onPlansFetched));
		this.props.dispatch(getFortumoPaymentObject());
	}

	refreshConfig = () => {
		this.props.dispatch(GetFortumoConfigAction(this.props.origin));
	}

	onPlansFetched = (error) => {
		const {plans} = this.props;
		if(!error) {
			const planType = "monthly";
			const selectedPlan = plans.find((plan)=>plan.term.indexOf(planType) > -1);
			if(selectedPlan) this.onPlanSelected(selectedPlan);
		}
		if(this.props.isSubscribed) {
			this.navigateToLandingPage();
		}
	};

	renderPlans = () => {
		const items = [];
		const {plans, sku} = this.props;

		if(plans && plans.length>0) {
			plans.forEach((plan) => {
				const planTerm = plan.term === "monthly" ? "Miesięczny" : "Tygodniowy";
				const planDefault = plan.term === "monthly" ? MONTHLY_PLAN_TEXT : WEEKLY_PLAN_TEXT;
				const priceFormatted = `${plan.price.amount.toFixed(2)} ${plan.currencyString}`;

				items.push(<PaymentPlanItem
					key={plan.sku}
					className={plan.term}
					title={planTerm}
					term={planTerm}
					priceFormatted={priceFormatted}
					sku={plan.sku}
					selectedSku = {sku}
					description={planDefault}
					onSelect={()=>this.onPlanSelected(plan)}
				/>);
			})

			return items;
		}

		return null;
	};

	onPlanSelected = (plan) => {
		this.setState({selectedPlan:plan});
		this.props.dispatch(SetCurrentSku(plan.sku));
		if (this.props.onPlanSelected) this.props.onPlanSelected(plan);
	};

	onPaymentApproved = () => {
		this.navigateToLandingPage();
	}

	navigateToLandingPage = () => {
		if (this.props.isStandalone) {
			navigateToLocation(ROUTES.HOMEPAGE);
		} else {
			navigateToLocation(ROUTES.ACCOUNT_CREATED);
		}
	}

	render() {
		const {config, selectedPlanToken, isSubscribed} = this.props;
		if (!config) {
			return <div className="loader">
				<Loader loading={true}/>
			</div>
		}

		const fortumoConfig = {
			merchant_id: config.merchantId,
			token: selectedPlanToken,
			button_img_src: fortumoPayPlayImage
		}

		return(
			<div className="plan-selection" tabIndex="-1" onKeyDown={this.onKeyDown}>
				<div className="component-body">
					<h1 className="component-title">Wybierz plan dla siebie</h1>
					<div className="payment-selection">
						<div className="plans-container">
							{this.renderPlans()}
						</div>
					</div>
					<div className="text-container">
						<p>Bez zobowiązań – możesz wyłączyć subskrypcję w dowolnym momencie.</p>
						<div className="spacer"/>
					</div>

					<div className="buttons-container">
						{!isSubscribed && fortumoConfig.merchant_id && fortumoConfig.token &&
							<FortumoButton onPaymentApproved={this.onPaymentApproved} refreshConfig={this.refreshConfig} config={fortumoConfig}/>
						}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const plans = getFortumoPlans(state);
	const config = getFortumoConfig(state);
	const sessionData = getSessionData(state);
	const sku = sessionData ? sessionData.sku : null;
	const origin = getOrigin(state);
	const selectedPlanToken = sku && config ? config.tokens[sku] : null;
	const isSubscribed = getIsUserSubscribed(state);
	const isStandalone = getRoutingSearch(state).standalone;

	return {
		plans,
		sku,
		config,
		origin,
		selectedPlanToken,
		isSubscribed,
		isStandalone
	}
};

export const FortumoPlanSelection = connect(mapStateToProps)(FortumoPlanSelectionComponent);
