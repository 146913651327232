export const GOOGLE_CLIENT_ID = '1065831886597-ucbshop527spgmm79s1qg03ufhblebds.apps.googleusercontent.com';
export const FACEBOOK_APP_ID_PROD = '759851207448214';
export const FACEBOOK_APP_ID = window.config.REACT_APP_ENV === 'dev' ? '1181301875729172' : FACEBOOK_APP_ID_PROD;

export const ATTRIBUTION_ORIGIN_TYPES = {
    ATARI:"atari",
};
export const PAYMENT_TYPES = {
    APPLE_IOS: 'ios',
    GOOGLE: 'google',
    MICROSOFT: 'microsoft',
    AMAZON: 'amazon',
    USER_PLAN: 'userPlan',
    STRIPE: 'stripe',
    PAYPAL: 'paypal',
    DOCOMO: 'docomo',
    FORTUMO: 'fortumo'
};
