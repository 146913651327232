import fileLaunch from './assets/sounds/launch.mp3';
import fileGemcounter from './assets/sounds/gemcounter.mp3';
import fileGemspent from './assets/sounds/gemspent.mp3';
import fileClick from './assets/sounds/click.mp3';
import fileXpgain from './assets/sounds/xpgain.mp3';
import fileLevelup from './assets/sounds/levelup.mp3';
import fileChallengewin from './assets/sounds/challengewin.mp3';
import fileChallengefail from './assets/sounds/challengefail.mp3';
import fileNavigation from './assets/sounds/navigation.mp3';
import fileNotification from './assets/sounds/notification.mp3';
import fileGameDetailPlayButton from './assets/sounds/gameDetailPlayButton.mp3';
import fileFinalPlayButton from './assets/sounds/finalPlayButton.mp3';
import fileListTileChange from './assets/sounds/listTileChange.mp3';
import fileVerticalListRowChange from './assets/sounds/verticalListRowChange.mp3';
import {Howl, Howler} from 'howler';

const audioMasterVolume = 1.0;	//can make this configurable later

export const TYPE_LAUNCH = 'launch';
export const TYPE_GEM_COUNTER = 'gemcounter';
export const TYPE_GEM_SPENT = 'gemspent';
export const TYPE_CLICK = 'click';
export const TYPE_XP_GAIN = 'xpgain';
export const TYPE_LEVEL_UP = 'levelup';
export const TYPE_CHALLENGE_WIN = 'challengewin';
export const TYPE_CHALLENGE_FAIL = 'challengefail';
export const TYPE_NOTIFICATION = 'notification';
export const TYPE_NAVIGATION = 'navigation';
export const TYPE_GAMEDETAIL_PLAY_BUTTON = 'gameDetailPlayButton';
export const TYPE_FINAL_PLAY_BUTTON = 'finalPlayButton';
export const TYPE_LIST_TILE_CHANGE = 'listTileChange';
export const TYPE_VERTICAL_LIST_ROW_CHANGE = 'verticalListRowChange';


const audioSettings = [
	{type:TYPE_LAUNCH, file:fileLaunch, volume:0.25},
	{type:TYPE_GEM_COUNTER, file:fileGemcounter, volume:0.8},
	{type:TYPE_GEM_SPENT, file:fileGemspent, volume:1.0},
	{type:TYPE_CLICK, file:fileClick, volume:0.2},
	{type:TYPE_XP_GAIN, file:fileXpgain, volume:0.75},
	{type:TYPE_LEVEL_UP, file:fileLevelup, volume:1.0},
	{type:TYPE_CHALLENGE_WIN, file:fileChallengewin, volume:1.0},
	{type:TYPE_CHALLENGE_FAIL, file:fileChallengefail, volume:1.0},
	{type:TYPE_NOTIFICATION, file:fileNotification, volume:1.0},
	{type:TYPE_NAVIGATION, file:fileNavigation, volume:0.4},
	{type:TYPE_GAMEDETAIL_PLAY_BUTTON, file:fileGameDetailPlayButton, volume:1.0},
	{type:TYPE_FINAL_PLAY_BUTTON, file:fileFinalPlayButton, volume:0.5},
	{type:TYPE_LIST_TILE_CHANGE, file:fileListTileChange, volume:0.5},
	{type:TYPE_VERTICAL_LIST_ROW_CHANGE, file:fileVerticalListRowChange, volume:0.6}
];

let audioManagerInstance = null;
class AudioManager {
	constructor() {
		this.sounds = [];

		this.onStoreUpdate = this.onStoreUpdate.bind(this);

		Howler.volume(audioMasterVolume);

		audioSettings.forEach(item => {
			if(!this.sounds[item.type]) {
				this.sounds[item.type] = new Howl({
					src: [ item.file ],
					volume: item.volume
				});
			}
		});
		this.playAudio(TYPE_LAUNCH);
	}

	playAudio = (type) => {
		if(this.sounds[type]) {
			this.sounds[type].play();
		}
	};

	onStoreUpdate() {
	// onStoreUpdate(store) {
		// console.log('store',store);
	}
}

const getAudioManager = () => {
	if(audioManagerInstance===null) {
		audioManagerInstance = new AudioManager();
	}
	return audioManagerInstance;
};

export default getAudioManager();
