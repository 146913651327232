import React, { Component } from 'react';
import { connect } from 'react-redux';
import {SubmissionError} from 'redux-form';
import Registration from '../login/register/registration.component'
import {addPopup} from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import { navigateToLocation, ROUTES} from '../../app.router';
import logo from '../../assets/images/splash/antstream_logo_vector.svg';
import './docomo-registration.component.less';

class DocomoRegistrationComponent extends Component {
	componentDidMount() {
		const { location } = this.props
		const {ddSubscriptionId, merchantSubscriptionId, isIOSAcknowledged } = location && location.query
		if (!ddSubscriptionId || !merchantSubscriptionId) return navigateToLocation(ROUTES.LANDING);
		const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

		if(isiOS && !isIOSAcknowledged) {
			return navigateToLocation(ROUTES.DOCOMO_IOS_ACKNOWLEDGE, { ddSubscriptionId, merchantSubscriptionId});
		} 
	}
	submitForm = (values) => {
		const { confirmPassword, email, ...rest} = values;
		const { location } = this.props
		const endpoint = window.config.REACT_APP_DOCOMO_REGISTRATION_URL
		const {ddSubscriptionId, merchantSubscriptionId } = location && location.query

		this.submittedValues = values
		const max = 99;
		const randomNumber = Math.floor(Math.random() * Math.floor(max))
		const generatedDisplayName = `${Date.now()}+${randomNumber}`
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				ddSubscriptionId,
				merchantSubscriptionId,
				userName: email,
				displayName: generatedDisplayName,
				...rest
			})
		};
		return fetch(endpoint, requestOptions)
			.then(async response => {
				const data = await response.json();
				if(data.status === 'ERROR') {
					const error = (data && data.errorDescription);
					return Promise.reject(error);
				}
				return navigateToLocation(ROUTES.THANK_YOU);
			})
			.catch(error => {
				addPopup(<GenericPopup
					title="Warning!"
					message={error && error.toString()}
				/>)
				throw new SubmissionError({
					_error: error
				  })
			});
	};

	render () {
		return(
			<section className="docomo-registration-component">
				<div className="logo-container">
					<img src={logo} alt="logo" />
				</div>
				<Registration submitForm={this.submitForm} />
			</section>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		query: ownProps.location.query
	};
};

export default connect(mapStateToProps)(DocomoRegistrationComponent);