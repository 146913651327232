import React,{Component} from 'react';
import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import leftImageTop from '../../../assets/images/ftui/footer-images/payment_top.png';
import leftImageBottom from '../../../assets/images/ftui/footer-images/payment_bot.png';
import './free-trial-popup.component.less';

export const TYPE_HAS_TWO_BUTTONS='hasTwoButtons';

class FreeTrialPopup extends Component {
	componentDidMount() {
		this.refs.containerRef.focus();
	}

	onOkButtonClicked = () => {
		if(this.props.onOkClicked)this.props.onOkClicked();
		if(!this.props.isPermanent)closePopup();
	};

	onCancelButtonClicked = () => {
		if(this.props.onCancelClicked)this.props.onCancelClicked();
		if(!this.props.isPermanent)closePopup();
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13){
			this.onOkButtonClicked();
		}else if(event.keyCode === 27){
			this.onCancelButtonClicked();
		}
	};

	render() {
		return (
			<div className="free-trial-component" ref="containerRef" tabIndex="-1" onKeyDown={this.onKeyDown}>
				<div className="title-text">
					This card has already been used for a free trial.
				</div>
				<div className="message-text">
					Your subscription will begin today.
				</div>
				<div className="image-container">
					<img className="image-bottom" alt="ftui-background-bottom" src={leftImageBottom}/>
					<img className="image-top" alt="ftui-background-top" src={leftImageTop}/>
				</div>
				<div className="message-text">
					Would you like to continue?
				</div>
				<div className="buttons-container">
					<Button
						className="secondary"
						onClick={this.onCancelButtonClicked}
					>
						No cancel
					</Button>
					<Button
						className="secondary"
						onClick={this.onOkButtonClicked}
					>
						Yes continue
					</Button>
				</div>
			</div>
		);
	}
}

export default FreeTrialPopup;
