import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as FB from '../../../assets/lib/facebook.lib';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { getLoading } from '../login.selectors';
import { Button } from '../../common/button/common-button.component';
import { Input } from '../../common/input/input.component';
import { Loader } from '../../common/loader/loader.component';
import { LoginUserAction } from '../login.actions';
import {requiredValidator, emailValidator, trimValidator} from '../../common/input/input.validator';
import { LoginFacebookAction } from '../login.actions';
import logo from '../../../assets/images/splash/antstream_logo_vector.svg';
import '../login-global.less';
import {readCustomPlanData, writeFBUserData} from '../../../assets/lib/local-storage';
import {ButtonFacebook} from '../../common/button/common-button-with-facebook-icon.component';
import {ATTRIBUTION_ORIGIN_TYPES, PAYMENT_TYPES} from "../../../constants";
import {getPaymentMethod} from "../../../app.selectors";

class LoginCredentialsComponent extends Component {
	constructor() {
		super();
		this.state = {accessToken:null, email:null};
	}

	componentDidMount() {
		const { accessToken } = this.props;
		if(accessToken) {
			this.props.dispatch(LoginFacebookAction(accessToken));
		}
	}

	onResetPassword = () => {
		navigateToLocation(ROUTES.PASSWORD_RESET_REQUEST);
	};

	onLogin = (values) => {
		const {email, password} = values;
		this.props.dispatch(LoginUserAction({email:email.toLowerCase(), password}));
	};

	loginWithFacebook = () => {
		FB.login(ROUTES.LOGIN_CREDENTIALS);
	};

	back = () => {
		window.location = "https://www.antstream.com"
	};

	isFacebookButtonAvailable= () => {
		const {attributionOrigin, paymentMethod} = this.props;
		if(paymentMethod === PAYMENT_TYPES.FORTUMO) return false;
		if(attributionOrigin && attributionOrigin === ATTRIBUTION_ORIGIN_TYPES.ATARI) return false;
		//if (this.inIframe()) return false; // Does not work if page embeded in an iFrame
		return true;
	}
	
	inIframe =() => {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	render () {
		const { loading, accessToken, handleSubmit } = this.props;

		if (accessToken) {
			return (
				<div className="loader-games">
					<Loader loading={true}/>
				</div>
			);
		}

		return (
			<div className="login-wrapper login-credentials">
				<section className="login-section">
					<div className="logo-container">
						<img src={logo} alt="logo" />
					</div>

					{this.isFacebookButtonAvailable() && <>
						<ButtonFacebook
							onClick={this.loginWithFacebook}
						>
							Continue with Facebook
						</ButtonFacebook>
						<p className="separator-with-text">
							<span className="line"></span>
							<span className="text">OR</span>
							<span className="line"></span>
						</p>
					</>}
					<form
						autoComplete="off"
						onSubmit={event => event.preventDefault()}
					>
						<Field
							name="email"
							component={Input}
							placeholder="Email"
							validate={[ requiredValidator, emailValidator, trimValidator ]}
						/>
						<Field
							name="password"
							type="password"
							component={Input}
							placeholder="Password"
							validate={[ requiredValidator ]}
						/>

						<Button
							type="submit"
							onClick={handleSubmit(this.onLogin)}
							disabled={loading}
							className="secondary"
						>
							{loading ? 'Logging in ...' : 'Login'}
						</Button>
					</form>
					<div className="buttons-container">
						<Button
							className="secondary small-button"
							onClick={this.back}
						>
							Back
						</Button>
						<Button
							className="secondary small-button"
							onClick={this.onResetPassword}
						>
							Reset Password
						</Button>
					</div>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const paymentMethod = getPaymentMethod(state);
	const planData = readCustomPlanData();
	const attributionOrigin = planData && planData.attribution_origin ? planData.attribution_origin : null;

	return {
		...state.login,
		accessToken: state.routing.search.access_token || state.routing.hash.access_token,
		loading: getLoading(state),
		paymentMethod,
		attributionOrigin
	};
};

LoginCredentialsComponent = connect(mapStateToProps)(LoginCredentialsComponent);

export const LoginCredentials = reduxForm({
	form: 'loginCredentials'
})(LoginCredentialsComponent);
