import React from 'react';
// import {PaymentRequestButtonElement} from 'react-stripe-elements';
import {addPopup} from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';

/*eslint no-empty-function: 0, no-console: 0 */
class PaymentRequestForm extends React.Component {
	constructor(props) {
		super(props);
		let description = 'Demo Antstream Subscription';
		let	price = 1;
		const country = 'GB';
		let currency = 'gbp';

		if(this.props.selectedPaymentPlan) {
			description = this.props.selectedPaymentPlan.description || description;
			price = Math.round(this.props.selectedPaymentPlan.price*100);
			currency = this.props.selectedPaymentPlan.currency;
		}

		// FIXME: How do I get the 'stripe' ref into login.actions.js???!?
		//        For now, just make a global var to it till I can talk 
		//        to Serhat
		window.ak_stripe = props.stripe; 

		console.log("this.props.selectedPaymentPlan",this.props.selectedPaymentPlan);

		// For full documentation of the available paymentRequest options, see:
		// https://stripe.com/docs/stripe.js#the-payment-request-object
		const paymentRequest = props.stripe.paymentRequest({
			country,
			currency,
			total: {
				label:description,
				amount:price,
			},
			requestPayerEmail:true,
		});

		paymentRequest.on('token', async (ev) => {
			if (ev.token) {
				console.log('Received Stripe token: ', ev.token);
				console.log('Received customer information: ', ev.token);
				if(this.props.onStripeToken)this.props.onStripeToken(ev.token);
				ev.complete('success');
			} else {
				console.log(ev);
				addPopup(
					<GenericPopup
						title = "Warning"
						message = "Payment Failed"
					/>
				);
				ev.complete('fail');
			}
		});

		paymentRequest.on('paymentmethod', async (ev) => {
			if (ev.token) {
				console.log('Received Stripe paymentMethod: ', ev.paymentMethod);
				if(this.props.onStripePaymentMethod)this.props.onStripePaymentMethod(ev.paymentMethod);
				ev.complete('success');
			} else {
				console.log(ev);
				addPopup(
					<GenericPopup
						title = "Warning"
						message = "Payment Failed"
					/>
				);
				ev.complete('fail');
			}
		});

		paymentRequest.canMakePayment().then((result) => {
			// console.log('canMakePayment',result);
			if(this.props.onCanMakePayment)this.props.onCanMakePayment(!!result);
			this.setState({canMakePayment: !!result});
		});

		this.state = {
			canMakePayment: false,
			paymentRequest,
		};
	}

	render() {
		// if(this.state.canMakePayment) {
		// 	return (
		// 		<>
		// 			<PaymentRequestButtonElement
		// 				paymentRequest={this.state.paymentRequest}
		// 				className="payment-request-button"
		// 				style={{
		// 					// For more details on how to style the Payment Request Button, see:
		// 					// https://stripe.com/docs/elements/payment-request-button#styling-the-element
		// 					paymentRequestButton: {
		// 						theme: 'light',
		// 						height: '64px',
		// 					},
		// 				}}
		// 			/>
		// 			<div className="or-text-container">OR</div>
		// 		</>
		// 	);
		// } else {
		// 	return null;
		// }


		return null;
	}
}

export default PaymentRequestForm;
