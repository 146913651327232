import React from 'react';
import 'rxjs/add/operator/filter';
import {get} from 'lodash';
import { SET_USER_AVATAR_ACTION } from './components/login/login.actions';
import {addPopup} from './components/popup/popup.component';
import GenericPopup from './components/popup/generic-popup/generic-popup.component';
import {navigateToLocation, ROUTES} from './app.router';
import Antstream from './assets/lib/Antstream';
import { getIpStackObject } from './app.selectors';
import ERROR_TYPES from './assets/lib/ErrorTypes';
import {getUsersLocationData} from './assets/lib/utils';
import {antstreamAPIService} from "./app.reducer";
import {clearAll, readRestUserData} from "./assets/lib/local-storage";

export const AppInitAction = () => {
	return (dispatch) => {
		dispatch({ type: 'App Init Action' });
		dispatch(AppInitActionSuccess());
	};
};



export const ListenApiWebsocket = () => {
	return (dispatch) => {
		dispatch({type: 'Listen To API Websocket Action'});
		Antstream.getWebsocketAPImessage$().subscribe((resp) => {
			console.log('ListenApiWebsocket resp: ', resp);
			if(resp.error) {
				if(resp.error.type === ERROR_TYPES.PAYMENT_ERROR) {
					addPopup(<GenericPopup
						okButtonLabel="Ok"
						title="Payment Message"
						message={resp.error.message}
					/>);
				}else if(resp.error.message) {
					if(window.config.REACT_APP_ENV !== 'live') {
						addPopup(<GenericPopup
							okButtonLabel="Got it!"
							title="Something's gone wrong!"
							message={resp.error.message}
						/>);
					}
				}
				else if(resp.error.Verification) {
					addPopup(<GenericPopup
						okButtonLabel="Got it!"
						title="Account Recovery"
						message="An access token has been sent to your email address, please input the token to reset your password."
					/>);
				}
				else if(resp.error.DETAILS) {
					// alert(resp.error.DETAILS);
					switch(resp.error.DETAILS) {
						case 'UNRECOGNISED':
							addPopup(<GenericPopup
								okButtonLabel="Got it!"
								message="The username and password you entered did not match our records. Please double-check and try again."
							/>);
							break;
						case 'LOCKED':
							addPopup(<GenericPopup
								okButtonLabel="Got it!"
								message="Your account has been locked due to too many failed login attempts. Please try again later."
							/>);
							break;
						default:
							addPopup(<GenericPopup
								okButtonLabel="Got it!"
								title="Something's gone wrong!"
								message={resp.error.DETAILS}
							/>);
							break;
					}
				}
				return;
			}

			const messageClass = get(resp, '@class', '');
			switch (messageClass) {
				case '.LogEventResponse': {
					if (!resp || !resp.scriptData || !resp.scriptData.data) break;

					let parsed = null;
					try {
						parsed = JSON.parse(resp.scriptData.data);
					} catch (e) {
						console.error('Failed to parse ws message: ', e.message);
						break;
					}
					if (parsed && parsed.type === 'profile-image-update') {
						dispatch({ type: SET_USER_AVATAR_ACTION, payload: parsed.profile_image_id }); // update avatar in header
					} else if (parsed && parsed.type === 'accountDeleted') {
						clearAll();
						window.location.href = window.config.REACT_APP_URL;
					} else if (parsed && parsed.type === 'logout') {
						const authToVerify = parsed.authToken;
						const data = readRestUserData();
						// if we use the same accessToken - clear local storage and do restart app
						if (data && authToVerify === data.authToken) {
							clearAll();
							window.location.href = window.config.REACT_APP_URL;
						}
					}
					break;
				}
				default:
					break;
			}
		});
	};
};

export const APP_INIT_SUCCESS_ACTION = 'App Init Success';
export const AppInitActionSuccess = () => {
	return { type: APP_INIT_SUCCESS_ACTION };
};

export const SAVE_ROUTE = 'Saving Route Before Rerouting';
export const SaveRoute = (route, params={}, search={}) => {
	const payload = {
		route,
		params,
		search
	};
	return { type: SAVE_ROUTE, payload };
};

export const SET_DISPLAY_NAME_ACTION = 'Set DisplayName Action';
export const SetDisplayNameAction = (payload, callback) => {
	return (dispatch) => {
		dispatch({ type: SET_DISPLAY_NAME_ACTION, payload });

			const {authToken, userId} = readRestUserData();
			const updNameBody = {
				operation: '/player/name',
				newDisplayName: payload,
				accessToken: authToken,
				playerId: userId
			};
			antstreamAPIService.player.nameUpdate(updNameBody, {
				headers: {'Authorization': "Bearer " + authToken}
			}).then(({data}) => {
				if (data.body && data.body.result === "OK") {
					dispatch(SetDisplayNameActionSuccess({displayName: data.body.newDisplayName}));
				} else {
					addPopup(<GenericPopup message={'The display name is not available.'} />);
					throw new Error((data && data.error) || "Failed to update avatar. Please try later.");
				}

				if (callback) callback(true);
			}).catch(avatarUpdErr => {
				dispatch(SetDisplayNameActionFail());
				if (callback) callback(false);
			});

	};
};

export const SET_DISPLAY_NAME_ACTION_SUCCESS = 'Set DisplayName Success';
export const SetDisplayNameActionSuccess = (payload) => {
	return { type: SET_DISPLAY_NAME_ACTION_SUCCESS, payload };
};

export const SET_DISPLAY_NAME_ACTION_FAIL = 'Set DisplayName Fail';
export const SetDisplayNameActionFail = (payload) => {
	return { type: SET_DISPLAY_NAME_ACTION_FAIL, payload };
};


export const GET_UPLOAD_URL_ACTION = 'Get Upload Url Action';
export const GetUploadUrlAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: GET_UPLOAD_URL_ACTION, payload });
		Antstream
			.getUploadUrl(payload)
			.subscribe( (resp) => {
				dispatch(GetUploadUrlActionSuccess(resp));
			});
	};
};

export const GET_UPLOAD_URL_ACTION_SUCCESS = 'Get Upload Url Action Success';
const GetUploadUrlActionSuccess = (payload) => ({
	type: GET_UPLOAD_URL_ACTION_SUCCESS, payload
});

export const SetUploadIdAction = (payload) => {
	return (dispatch) =>{
		Antstream.setUploadId(payload)
			.subscribe( (resp) => {
				dispatch(SetUploadIdActionSuccess(resp));
			});
	};
};

export const SET_UPLOAD_ID_ACTION_SUCCESS = 'Set Upload Id Action Success';
const SetUploadIdActionSuccess = (payload) => {
	return (dispatch) => {
		dispatch({
			type: SET_UPLOAD_ID_ACTION_SUCCESS, payload
		});
		navigateToLocation(ROUTES.HOMEPAGE);
	};
};

export const GET_IP_STACK_OBJECT = 'GET_IP_STACK_OBJECT';
export const GetIpStackObject = (onComplete) => {
	return (dispatch,getState) => {
		if(!getIpStackObject(getState())){
			dispatch({ type: GET_IP_STACK_OBJECT });
			getUsersLocationData().then((resp)=>{
				dispatch(GetIpStackObjectSuccess(resp));
				if(onComplete)onComplete();
			}).catch((error)=>{
				console.log("unable to get location object",error);
				if(onComplete)onComplete();
			})
		}else{
			if(onComplete)onComplete();
		}
	};
};

export const GET_IP_STACK_OBJECT_SUCCESS = 'GET_IP_STACK_OBJECT_SUCCESS';
const GetIpStackObjectSuccess = (payload) => ({
	type: GET_IP_STACK_OBJECT_SUCCESS, payload
});

export const SET_CONFIG = 'SET_CONFIG';
export const setConfig = (config) => {
	return (dispatch) => {
		dispatch({type: SET_CONFIG, config});
	}
};
