import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./gdpr.component.less";
import {Button} from '../../common/button/common-button.component';
import {navigateToLocation, ROUTES} from '../../../app.router';
import {SaveRoute} from '../../../app.actions';
import {SignupUserAction} from '../../login/login.actions';

class GdprComponent extends Component {
	onExitClicked = () => {
		navigateToLocation(ROUTES.LANDING);
	};

	onAskClicked = () => {
		window.open('https://www.antstream.com/contact', '_blank');
	};

	onBackClicked = () => {
		navigateToLocation(ROUTES.AVATAR_SELECTION);
	};

	onConsentClicked = () => {
		this.props.dispatch(SaveRoute(ROUTES.PLANS));
		this.props.dispatch(SignupUserAction());
	};

	render () {
		return(
			<div className="gdpr">
				<div className="component-body">
					<div className="gdpr-text">
						<p>In order to provide you with this service we need to collect some data from you.</p>
						<p>
							The specific personal information we collect/hold includes:<br/>
							- Name<br/>
							- E-mail address<br/>
							- Facebook profile picture (if using Facebook to login)
						</p>
						<p>
							We use personal information for the following reasons:<br/>
							- Carry out our obligations arising from any contracts entered into between you and us<br/>
							- Notify you about changes to our service<br/>
							- Send newsletters from time to time<br/>
							- To comply with legal or regulatory requirements<br/>
						</p>
						<p>
							We may also automatically collect some system and usage data in order to provide the best service/performance, such as:<br/>
							- Computer / Device information<br/>
							- Connection information<br/>
							- Location information (town/city)<br/>
							- How often the service is used and at what times
						</p>
						<p>More information can be found here - https://www.antstream.com/privacy.</p>
						<p>Sadly, Antstream Arcade cannot function without this basic data, and we would love to have you as part of the colony!</p>
					</div>
				</div>
				<div className="buttons-container">
					<Button
						className="secondary"
						onClick={this.onExitClicked}
					>
						Exit
					</Button>
					<Button
						className="secondary"
						onClick={this.onAskClicked}
					>
						Ask a question
					</Button>
					<Button
						className="secondary"
						onClick={this.onBackClicked}
					>
						Back
					</Button>
					<Button
						onClick={this.onConsentClicked}
						className="primary"
					>
						I consent
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps)(GdprComponent);