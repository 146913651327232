import jwtDecode from "jwt-decode";

const KEY_REST_USER_DATA = 'restUserData'; // AWS API Gateway data
const KEY_USER_DATA = 'userData';
const KEY_FB_USER_DATA = 'fbUserData';
const KEY_SIGN_UP_EMAIL = 'signUpEmail';
const KEY_SIGN_UP_PASSWORD = 'signUpPass';
const KEY_SIGN_UP_DISPLAY_NAME = 'signUpDisplayName';
const KEY_SIGN_UP_AVATAR = 'signUpAvatar';
const KEY_CUSTOM_PLAN_DATA = 'pln';
const KEY_PLAN_TYPE_DATA = "planType";
const KEY_PROMO_CODE = "promoCode";
export const KEY_SHOW_ACCOUNT_DELETE_BTN = "showAccountDeleteBtn";

const read = (key) => {
	if (window.localStorage) {
		const data = localStorage.getItem(key);
		if(data) {
			try {
				return JSON.parse(data);
			}catch (e) {
				clearAll();
			}
		}
		return null;
	} else {
		return null;
	}
};

export const write = (key, data) => {
	if (window.localStorage) {
		localStorage.setItem(key, JSON.stringify(data));
	} else {
		return null;
	}
};

export const clearAll = () => {
	if (window.localStorage) {
		localStorage.removeItem(KEY_SIGN_UP_EMAIL);
		localStorage.removeItem(KEY_SIGN_UP_PASSWORD);
		localStorage.removeItem(KEY_SIGN_UP_DISPLAY_NAME);
		localStorage.removeItem(KEY_SIGN_UP_AVATAR);
		localStorage.removeItem(KEY_USER_DATA);
		localStorage.removeItem(KEY_REST_USER_DATA);
		localStorage.removeItem(KEY_SHOW_ACCOUNT_DELETE_BTN);
	}
};

export const readUserData = () => {
	return read(KEY_USER_DATA);
};

export const readRestUserData = () => {
	return read(KEY_REST_USER_DATA);
};

export const writeUserData = (userData) => {
	return write(KEY_USER_DATA,userData);
};

export const writeRestUserData = (userData) => {
	return write(KEY_REST_USER_DATA,userData);
};

export const readFBUserData = () => {
	const fbId = read(KEY_FB_USER_DATA)

	if((typeof fbId) === 'string'){
		return fbId;
	} else {
		clearAll();
		return null;
	}
};

export const writeFBUserData = (userData) => {
	return write(KEY_FB_USER_DATA,userData);
};

export const readPromoCode = () => {
	return read(KEY_PROMO_CODE);
};

export const writePromoCode = (promoCode) => {
	return write(KEY_PROMO_CODE,promoCode);
};

export const readShowAccountDeleteBtn = () => {
	return read(KEY_SHOW_ACCOUNT_DELETE_BTN);
};

export const writeShowAccountDeleteBtn = (value) => {
	return write(KEY_SHOW_ACCOUNT_DELETE_BTN, value);
};

export const clearPromoCode = () => {
	localStorage.removeItem(KEY_PROMO_CODE);
};
export const clearPlan = () => {
	localStorage.removeItem(KEY_CUSTOM_PLAN_DATA);
};
export const writeSignUpEmailAndPassword = (email,password) => {
	write(KEY_SIGN_UP_EMAIL,email);
	write(KEY_SIGN_UP_PASSWORD,password);
};

export const writeSignUpDisplayName = (displayName) => {
	write(KEY_SIGN_UP_DISPLAY_NAME,displayName);
};

export const writeSignUpAvatar = (avatar) => {
	write(KEY_SIGN_UP_AVATAR,avatar);
};

export const writeCustomPlanData = (token) => {
	write(KEY_CUSTOM_PLAN_DATA, token);
};

export const readCustomPlanData = () => {
	const token = read(KEY_CUSTOM_PLAN_DATA);
	if(token) return jwtDecode(token);
	else return null;
};

export const writePlanTypeData = (type) => {
	write(KEY_PLAN_TYPE_DATA, type);
};

export const readPlanTypeData = () => {
	const type = read(KEY_PLAN_TYPE_DATA);
	return type || null;
};

export const clearPlanTypeData = () => {
	if (window.localStorage) {
		localStorage.removeItem(KEY_PLAN_TYPE_DATA);
	}
}

export const readSignUpData = () => {
	return {
		email:read(KEY_SIGN_UP_EMAIL),
		password:read(KEY_SIGN_UP_PASSWORD),
		displayName:read(KEY_SIGN_UP_DISPLAY_NAME),
		avatar:read(KEY_SIGN_UP_AVATAR)
	};
};
