import React, {Component} from 'react'
import './checkbox.component.less';

export class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkboxChecked: this.props.initallyChecked || false,
        }
    }
    toggleCheckBox = () => {
        const newCheckBoxState = !this.state.checkboxChecked
        this.setState({checkboxChecked: newCheckBoxState});
        this.props.checkboxToggled && this.props.checkboxToggled(newCheckBoxState)
    };
    
    onCheckBoxClicked = (event) => {
        event.preventDefault();
        this.toggleCheckBox();
    };
    
    onCheckBoxKeyDown = (event) => {
        if(event.keyCode === 32){
            event.preventDefault();
            this.toggleCheckBox();
    }   else if(event.keyCode === 13) {
            this.props.handleSubmit && this.props.handleSubmit();
        }
    }
    
    render() {
        // eslint-disable-next-line no-unused-vars
        const { checkboxToggled, initallyChecked, rest } = this.props
        return (
            <div
                tabIndex="0"
                className={`checkbox-container ${this.state.checkboxChecked?'checked':''}`}
                onClick={this.onCheckBoxClicked}
                onKeyDown={this.onCheckBoxKeyDown}
                {...rest}
            />
        )
    }
}

export default Checkbox