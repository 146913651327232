import React,{ Component }  from 'react';
import CryptoJS from 'crypto-js';
import {getBrowserData} from './device-helper';
import GenericPopup from '../../components/popup/generic-popup/generic-popup.component';
import {addPopup} from '../../components/popup/popup.component';
import {navigateToLocation, ROUTES} from '../../app.router';
import {readUserData, writeUserData} from "./local-storage";

class GameSparks extends Component {
	init (webSocketUrl, options) {
		this.options = options;
		this.pendingRequests = {};
		this.requestCounter = 0;
		options.url = webSocketUrl + options.key;
		this.connect(options.url);
	}

	close () {
		// console.log('close');
		if (this.webSocket != null) {
			this.webSocket.onopen = null;
			this.webSocket.onclose = null;
			this.webSocket.onerror = null;
			this.webSocket.onmessage = null;
			this.webSocket.close();
			this.webSocket = null;
		}
	}

	clearAuthTokenAndSession() {
		this.authToken = null;
		this.sessionId = null;
	}

	connect (socketUrl) {
		// console.log('connect');
		try {
			this.webSocket = new WebSocket(socketUrl);
			this.webSocket.onopen = this.onWebSocketOpen.bind(this);
			this.webSocket.onclose = this.onWebSocketClose.bind(this);
			this.webSocket.onerror = this.onWebSocketError.bind(this);
			this.webSocket.onmessage = this.onWebSocketMessage.bind(this);
		} catch(e) {
			// console.log(e.message);
		}
	}

	reconnect (url) {
		// console.log('reconnect');
		this.close();
		this.connect(url);
	}

	reconnectConnection() {
		if (!this.options.url) {
			navigateToLocation(ROUTES.HOMEPAGE);
		}

		try {
			this.reconnect(this.options.url);
		} catch (e) {
			navigateToLocation(ROUTES.HOMEPAGE);
		}
	};

	onWebSocketOpen (event) {
		// console.log('WebSocket onOpen', event);
	}

	onWebSocketClose (event) {
		// console.log('WebSocket onClose ---', this.webSocket.readyState,'---',event);
		if(this.webSocket.readyState === 3){
			this.showReconnectPopup ();
			this.reconnect(this.options.url);
		}
	}

	onWebSocketError (event) {
		// console.log('WebSocket onError: Sorry, but there is some problem with your socket or the server is down',event);
		this.showReconnectPopup ();
	}
	
	handshake (result) {
		if (result.nonce) {
			let hmac;

			if (this.options.onNonce) {
				const {hmacData, sessionData} = this.options.onNonce(result.nonce);
				hmac=hmacData;
				if(sessionData){
					// console.log('sessionData',sessionData);
					this.authToken = sessionData.authToken;
					this.sessionId = sessionData.sessionId;
				}
			} else {
				hmac = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(result.nonce, this.options.secret));
			}

			const toSend = {
				'@class' : '.AuthenticatedConnectRequest',
				hmac : hmac
			};

				const userData = readUserData() || {};
				toSend.authToken = this.authToken || userData.authToken;
				toSend.sessionId = this.sessionId || userData.sessionId;


			const browserData = getBrowserData();
			toSend.platform = browserData.browser;
			toSend.os = browserData.operatingSystem;

			this.webSocketSend(toSend);
		} else if (result.sessionId) {
			this.sessionId = result.sessionId;
			// console.log('handshake');

				if (result.authToken) {
					writeUserData({
						userId: result.userId,
						authToken: result.authToken,
						sessionId: result.sessionId
					})
				}

			if (this.options.onInit) {
				if(result.userId){//if app authorize with authToken and sessionId
					this.options.onInit(result.userId);
				}else {
					this.options.onInit();
				}
				this.startKeepAliveTimer();
			}
		}
	}

	startKeepAliveTimer = () => {
		if(this.keepAliveTimerId)clearInterval(this.keepAliveTimerId);
		this.keepAliveTimerId = setInterval(()=>{
				if(this.webSocket.readyState  !== 0){
					// console.log(`WebSocket KEEP_ALIVE =>`);
					this.webSocket.send(' ');
				}
			},
			30000
		);
	};

	sendWithData (requestType, json, onResponse) {
		// Ensure requestType starts with a dot.
		if (requestType.indexOf('.') !== 0) requestType = `.${requestType}`;

		json['@class'] = requestType;
		json.requestId = `${(new Date()).getTime()}_${++this.requestCounter}`;

		if(onResponse)this.pendingRequests[json.requestId] = onResponse;
		// console.log('sendWithData => ',json.requestId, '---', this.webSocket.readyState);

		setTimeout(()=>{
			if (this.pendingRequests[json.requestId]) {
				// console.log("timeout",json.requestId);
				delete this.pendingRequests[json.requestId];
			}
		},32000);

		this.webSocketSend(json);
	}

	showReconnectPopup () {
		if(this.isPopupActive) return;
		this.isPopupActive = true;
		addPopup(<GenericPopup
			okButtonLabel="Got it!"
			title="There was a problem with your connection"
			message="Reconnecting..."
			onOkClicked={()=>{
				navigateToLocation(ROUTES.HOMEPAGE);
				this.isPopupActive = false;
			}}
		/>);
	}

	webSocketSend (data) {
		const requestString = JSON.stringify(data);
		// console.log(`WebSocket send: ${requestString}`,this.webSocket);
		if(this.webSocket.readyState  !== 0){
			this.webSocket.send(requestString);
		}
	}

	onWebSocketMessage(message) {
		// console.log(`WebSocket onMessage: ${message.data}`);
		let result;
		try {
			result = JSON.parse(message.data);
		} catch (e) {
			console.log(`An error ocurred while parsing the JSON Data: ${message}; Error: ${  e}`);
			return;
		}

		if (this.options.onMessage) {
			this.options.onMessage(result);
		}

		if (result.authToken) this.authToken = result.authToken;

		// Any time a connectUrl is in the response we should update and reconnect.
		if (result.connectUrl) this.connect(result.connectUrl);

		const resultType = result['@class'];
		if (resultType === '.AuthenticatedConnectResponse') {
			this.handshake(result);
		} else if (resultType.match(/Response$/)) {
			if (result.requestId) {
				// console.log('onWebSocketMessage <= ',result);
				const requestId = result.requestId;
				if (this.pendingRequests[requestId]) {
					this.pendingRequests[requestId](result);
					delete this.pendingRequests[requestId];
				}
			}
		}
	}
}

export default GameSparks;
