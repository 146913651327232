import React, { Component } from 'react';
import "./navigation-bar.component.less";

class NavigationBarItemComponent extends Component {
	render () {
		return(
			<div className={`navigation-bar-item ${this.props.status}`}>
				<div className="item-name">{this.props.itemName}</div>
			</div>
		);
	}
}

export default NavigationBarItemComponent;
