import React, { Component } from 'react';
import { connect } from 'react-redux';
import './header.component.less';
import logo from '../../assets/images/splash/antstream_logo_vector.svg';
import {getIsLoggedIn, getLoggedUser} from '../../app.selectors';
import {navigateToLocation, ROUTES} from '../../app.router';
import {getAvatarUrl} from '../../entities/entities.selectors';

class Header extends Component {
	onProfileImageClicked = () => {
		navigateToLocation(ROUTES.SIGNUP_AVATAR,{return:'updateProfile'});
	};

	render() {
		const { displayName, isNotFullyRegistered, noHeader, isLoggedIn, avatarUrl} = this.props
		if (noHeader || !isLoggedIn) return null;
		return (
			<header className="header-component">
				<img className="logo-img" src={logo} alt="logo" />
				<div className="user-profile">

					{!isNotFullyRegistered && <h3 className="user-name">{displayName}</h3>}
					<img
						className="profile-image"
						src={avatarUrl}
						alt="profile_image"
						onClick={this.onProfileImageClicked}
					/>
				</div>
			</header>
		);
	}
}

const mapStateToProps = (state) => {
	const user = getLoggedUser(state);
	const displayName = user ? user.displayName : null;
	const avatarUrl = user ? getAvatarUrl(state,user.profile_image_id): '';
	const isLoggedIn = getIsLoggedIn(state);
	return {
		isNotFullyRegistered: user && user.isNotFullyRegistered,
		noHeader: state.routing.currentRoute?.noHeader,
		isLoggedIn,
		displayName,
		avatarUrl,
	};
};

Header = connect(mapStateToProps)(Header);
export default Header;
