import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { getLoading } from '../login.selectors';
import { Button } from '../../common/button/common-button.component';
import { Input } from '../../common/input/input.component';
import { maxLengthValidator, minLengthValidator, requiredValidator } from '../../common/input/input.validator';
import { ResetPasswordAction } from '../login.actions';
import { navigateBack } from '../../../app.router';
import inputManager from '../../../assets/lib/inputmanager';

import '../login-global.less';
import {addPopup} from '../../popup/popup.component';
import GenericPopup from '../../popup/generic-popup/generic-popup.component';

const minValue6 = minLengthValidator(6);
const maxValue20 = maxLengthValidator(20);
const navId='reset-pass';
const navLayer=0;
const validateForm = (fields) => {
	if (fields.password !== fields.confirmpassword) {
		return {confirmpassword: 'Passwords must match'};
	}
	return null;
};

class ResetPasswordComponent extends Component {
	constructor() {
		super();
		this.state = {};
		this.saveForm = this.saveForm.bind(this);
	}

	componentDidMount() {
		inputManager.refreshNavMap(navId, navLayer);
		inputManager.setCurrentElement(navLayer,0,0);
	}

	componentWillUnmount() {
		inputManager.removeRow(navId, navLayer);
	}

	saveForm = (values) => {
		const payload = {
			email: this.props.mail,
			password: values.password,
			token: this.props.token || values.token,
		};
		// this.props.dispatch(SaveRoute(ROUTES.LOGIN));
		this.props.dispatch(ResetPasswordAction(payload,this.onPasswordActionSuccess));
	};

	onPasswordActionSuccess = () => {
		if(!this.props.token) {
			addPopup(<GenericPopup
				okButtonLabel="Got it!"
				title="Account Recovery"
				message="Your password has been reset successfully."
			/>);
		}
	};

	back = () => {
		navigateBack();
	};


	render () {
		return(
			<div className="login-wrapper reset-password-component">
				<section className="login-section login-section--left">
					<h1>{this.props.token ? 'Set Password' : 'Reset Password'}</h1>
					{!this.props.token && <p>Choose your new password</p>}
					<form
						onSubmit={event => event.preventDefault()}
					>
						{!this.props.token && <Field
							name="token"
							type="token"
							component={Input}
							placeholder="Token"
							validate={[ requiredValidator ]}
							navProps={navId}
						/>}
						<Field
							name="password"
							type="password"
							component={Input}
							placeholder="Password"
							validate={[ maxValue20, minValue6, requiredValidator ]}
							navProps={navId}
						/>
						<Field
							name="confirmpassword"
							type="password"
							component={Input}
							placeholder="Confirm Password"
							validate={[ ]}
							navProps={navId}
						/>
						<Button
							className="secondary right-button"
							disabled={this.props.loading}
							navProps={navId}
							type="submit"
							onClick={this.props.handleSubmit(this.saveForm)}
						>
							{this.props.token ? 'Set Password' : 'Set New Password'}
						</Button>
						{!this.props.token && <Button
							className="secondary right-button"
							onClick={this.back}
							navProps={navId}
						>
							Back
						</Button>}
					</form>

					<p>Passwords must be at least six characters.</p>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const token = state.routing.search.token;
	const mail = state.routing.search.mail || (state.login.userFields ? state.login.userFields.email : null);

	return {
		login: state.login,
		error: state.login.error,
		loading: getLoading(state),
		token,
		mail,
	};
};
  
ResetPasswordComponent = connect(mapStateToProps)(ResetPasswordComponent);

export const ResetPassword = reduxForm({
	form: 'resetPasswordForm',
	validate: validateForm
})(ResetPasswordComponent);
