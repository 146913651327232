import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./display-name.component.less";
import leftImageTop from '../../../assets/images/ftui/footer-images/challange_top.png';
import leftImageBottom from '../../../assets/images/ftui/footer-images/challange_bot.png';
import FtuiFooterComponent from '../ftui-footer/ftui-footer.component';
import {Field, reduxForm} from 'redux-form';
import {Input} from '../../common/input/input.component';
import {
	emojiValidator,
	maxLengthValidator,
	minLengthValidator,
	requiredValidator, specCharactersValidator,
	trimValidator
} from '../../common/input/input.validator';
import {Button} from '../../common/button/common-button.component';
import {navigateToLocation, ROUTES} from '../../../app.router';
import {SetDisplayNameFieldAction,CheckDisplayNameAction} from '../../login/login.actions';
import {getError, getLoading} from '../../login/login.selectors';
import {addPopup} from '../../popup/popup.component';
import GenericPopup from '../../popup/generic-popup/generic-popup.component';
const maxValue20 = maxLengthValidator(20);
const minValue2 = minLengthValidator(2);

class DisplayNameComponent extends Component {
	constructor(props){
		super(props);
		this.submittedValues = null;
	}

	saveForm = (values) => {
		const {displayName} = values;
		this.submittedValues = values;

		this.props.dispatch(CheckDisplayNameAction(displayName,this.onCheckDisplayNameResponse));
	};

	onCheckDisplayNameResponse = (success) => {
		if(success){
			const {displayName} = this.submittedValues;

			this.props.dispatch(SetDisplayNameFieldAction(displayName));
			navigateToLocation(ROUTES.AVATAR_SELECTION);
		}else{
			addPopup(<GenericPopup
				title="Warning!"
				message={this.props.error}
			/>);
		}
	};

	back = () => {
		navigateToLocation(ROUTES.CREATE_USER);
	};

	render () {
		return(
			<div className="display-name">
				<div className="component-body">
					<br/>
					<form
						onSubmit={event => event.preventDefault()}
					>
						<div className="input-field-container">
							<Field
								name="displayName"
								component={Input}
								placeholder="Unique Username"
								validate={[ minValue2, maxValue20, requiredValidator, emojiValidator, trimValidator, specCharactersValidator]}
							/>
						</div>
						<div className="buttons-container">
							<Button
								className="secondary"
								onClick={this.back}
							>
								Back
							</Button>
							<Button
								className="secondary"
								disabled={!this.props.valid || this.props.loading}
								onClick={this.props.handleSubmit(this.saveForm)}
								type="submit"
							>
								Next
							</Button>
						</div>
					</form>
				</div>
				<FtuiFooterComponent
					leftImageTop={leftImageTop}
					leftImageBottom={leftImageBottom}
					content="Play solo or battle with others with our exclusive challenges"
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loading:getLoading(state),
		error:getError(state)
	};
};

export default reduxForm({
	form: 'displayNameForm'
})(connect(mapStateToProps)(DisplayNameComponent));