import {getFBImageUrl} from '../assets/lib/facebook.lib';
import {getAssetsUrlWithFolder} from "../app.selectors";
import {FOLDER_TYPES} from "../assets/lib/FolderTypes";

export const getAllAvatarsById = state => state.entities.avatars.byId;
export const getAllAvatarsIds = state => state.entities.avatars.allIds;
export const getAvatarById =  (state,type) => state.entities.avatars.byId[type];

export const getAvatarUrl = (state, type) => {
	const avatarObject = getAvatarById(state, type);
	const avatarUrl = avatarObject ? getAssetsUrlWithFolder(state,FOLDER_TYPES.AVATARS)+avatarObject.url : getFBImageUrl(type);
	return avatarUrl;
};