export const getDateStringWithEpoch = (epochTime) => {
    const date = new Date(0);
    date.setUTCSeconds(epochTime);
    const options = {  day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);    
};

export const capitalizeFirstLetter = (sourceString) => {
    return sourceString.charAt(0).toUpperCase() + sourceString.slice(1);
};

const fetchJSON = (url) => new Promise((resolve, reject) =>
    fetch(url)
        .then((response) =>
            response.json()
        ).then((data) =>
        resolve(data)
    ).catch((e) =>
        reject(e)
    )
);

const fetchLocationDataFromIpApiCo = async () => {
    try {
        const data = await fetchJSON('https://ipapi.co/json/');
        if (!data || data?.error) {
            throw new Error(data?.reason || 'Unknown error.');
        } else {
            return {
                ...data,
                currency: {
                    code: data.currency
                },
                country_code: data?.country
            };
        }
    } catch (error) {
        console.log(`IPAPICO location error: ${error?.message}`);
        return { error: error?.message || 'Unknown error' };
    }
};

const ipStackKey="552c4a783594f3c5e44f0e915519408b";
export const getUsersLocationData = () => {
    return new Promise(async (resolve, reject) => {
        const storageData = localStorage.getItem('myLocationData');
        if (storageData) {
            resolve(sanitizeLocationData(JSON.parse(storageData)));
            return;
        }

        let requestFailed = false;
        let responseData = null;

        await fetch(`https://api.ipstack.com/check?access_key=${ipStackKey}`)
            .then(function (response) {
                return response.json();
            }).then(function(data) {
                if(data.error){
                    requestFailed = true;
                    responseData = data.error;
                } else {
                    responseData = data;
                }
            }).catch((e)=>{
                requestFailed = true;
                responseData = e;
            });

        if (requestFailed) {
            const apiCoData = await fetchLocationDataFromIpApiCo();
            if (apiCoData) {
                requestFailed = false;
                responseData = apiCoData;
            }
        }

        if (requestFailed) {
            reject(responseData);
        } else {
            localStorage.setItem('myLocationData', JSON.stringify(sanitizeLocationData(responseData)));
            resolve(sanitizeLocationData(responseData));
        }
    });
};

const isEpicUser = (store) => {
    return store
        && store.app
        && store.app.loggedUser
        && store.app.loggedUser.userName
        && store.app.loggedUser.userName.includes('epic_games_');
};

const freeTierV2Regions = [
    "CA" // "Canada"
];
export const isFreeTierV2FeatureFlagEnabled = (store) => {
    if (isEpicUser(store)) return false;

    const v1 = store
        && store.app
        && store.app.registrationCountry
        && freeTierV2Regions.includes(store.app.registrationCountry);
    const v2 = store
        && store.app
        && store.app.freemiumV2User === true;

    return v1 || v2;
};

/**
 * Get only fields that we really need.
 **/
function sanitizeLocationData(data) {
    try {
        return {
            country_code: data?.country_code,
            currency: {
                code: data?.currency?.code
            },
            latitude: data?.latitude,
            longitude: data?.longitude
        };
    } catch (e) {
        console.error('Failed to sanitize location data: ', e);
        return data;
    }
}
