import React, { Component } from 'react';
import { connect } from 'react-redux';
import './update-profile.component.less';
import {getAppIsDisplayNamePending, getIsLoggedIn, getLoggedUser} from '../../app.selectors';
import {Button} from '../common/button/common-button.component';
import {navigateToLocation, ROUTES} from '../../app.router';
import {clearAll} from '../../assets/lib/local-storage';
import {LogoutUserAction, LogoutUserActionPending, sendLogOutRequest} from '../login/login.actions';
import inputManager from '../../assets/lib/inputmanager';
import {Field, reduxForm} from 'redux-form';
import {Input} from '../common/input/input.component';
import {
	emailValidator, emojiValidator,
	maxLengthValidator,
	minLengthValidator,
	requiredValidator, specCharactersValidator, trimValidator
} from '../common/input/input.validator';
import {SetDisplayNameAction} from '../../app.actions';
import {getAvatarUrl} from '../../entities/entities.selectors';
import {Loader} from "../common/loader/loader.component";
import {getLoading} from "../login/login.selectors";

const navId = 'update-profile';
const navLayer = 0;
const maxValue20 = maxLengthValidator(20);
const minValue2 = minLengthValidator(2);

class UpdateProfile extends Component {
	componentDidMount() {
		inputManager.refreshNavMap(navId,navLayer);
		inputManager.setCurrentElement(navLayer,0,0);

		this.saveIntervalId = setInterval(this.onSaveIntervalTick,2000);
		this.sentDisplayName = null;
	}

	onSaveIntervalTick = () => {
		if(this.saveButtonRef && this.props.dirty && this.props.valid) {
			this.saveButtonRef.click();
		}
	};

	componentWillUnmount() {
		inputManager.removeRow(navId, navLayer);
		if(this.saveIntervalId) {
			clearInterval(this.saveIntervalId);
			this.saveIntervalId= null;
		}
	}

	onLogoutClicked = () => {
			this.props.dispatch(LogoutUserActionPending());
			sendLogOutRequest(() => {
				clearAll();
				navigateToLocation(ROUTES.LANDING);
				this.props.dispatch(LogoutUserAction());
			});
	};

	onResetPasswordClicked = () => {
		navigateToLocation(ROUTES.PASSWORD_RESET_REQUEST);
	};

	onProfileImageClicked = () => {
		navigateToLocation(ROUTES.SIGNUP_AVATAR,{return:'updateProfile'});
	};

	onBackClicked = () => {
		navigateToLocation(ROUTES.HOMEPAGE);
	};

	saveData = (values) => {
			if (this.props.isDisplayNamePending) return;

			if(values.displayName!==this.props.displayName && values.displayName!==this.sentDisplayName) {
				this.sentDisplayName = values.displayName;
				this.props.dispatch(SetDisplayNameAction(values.displayName, (isSuccess) => {
					if (!isSuccess) { // rollback value to previous if smth is wrong
						this.props.change('displayName', this.props.displayName);
						this.sentDisplayName = this.props.displayName;
					}
				}));
			}
	};

	render() {
		if (this.props.noHeader || !this.props.isLoggedIn) return null;

		if (this.props.isLoading) {
			return (
				<div className="loader-games">
					<Loader loading={true}/>
				</div>
			);
		}

		return (
			<div className="update-profile-component">
				<div className="user-details">
					<div
						className="image-container"
					>
						<img
							className="profile-image"
							src={this.props.avatarUrl}
							alt="profile_image"
							onClick={this.onProfileImageClicked}
						/>
					</div>
					<div className="details-container">
						<form>
							<Field
								disabled={this.props.isDisplayNamePending}
								name="displayName"
								component={Input}
								placeholder="Username"
								validate={[ minValue2, maxValue20, requiredValidator, emojiValidator, trimValidator, specCharactersValidator ]}
								navProps={navId}
							/>
							<Field
								name="email"
								type="email"
								component={Input}
								placeholder="Email"
								validate={[ requiredValidator, emailValidator ]}
								disabled
							/>
						</form>
						<button
							ref={element=>this.saveButtonRef=element}
							className="hidden-save-button"
							onClick={this.props.handleSubmit(this.saveData)}
						>
							Save
						</button>
						<Button
							className="secondary"
							onClick={this.onResetPasswordClicked}
							navProps={navId}
						>
							Send Password Reset
						</Button>
					</div>
				</div>
				<div className="line"/>
				<Button
					className="secondary"
					onClick={this.onLogoutClicked}
					navProps={navId}
				>
					Logout
				</Button>
				<Button
					className="secondary"
					onClick={this.onBackClicked}
					navProps={navId}
				>
					Back
				</Button>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const isLoading = getLoading(state);
	const isDisplayNamePending = getAppIsDisplayNamePending(state);
	const isLoggedIn = getIsLoggedIn(state);
	const user = getLoggedUser(state);
	const isNotFullyRegistered = user && user.isNotFullyRegistered;
	const displayName = user && !isNotFullyRegistered ? user.displayName : null;
	const avatarUrl = user ? getAvatarUrl(state,user.profile_image_id): '';
	const email = user ? user.userName : null;

	return {
		isLoading,
		isLoggedIn,
		avatarUrl,
		displayName,
		isDisplayNamePending,
		initialValues: { displayName:displayName, email:email},
	};
};

UpdateProfile = connect(mapStateToProps)(reduxForm({
	form: 'userDetailsForm',
	enableReinitialize : true,
})(UpdateProfile));
export default UpdateProfile;
