export default () => {
	// Really basic check for the ios platform
	// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
	const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	// Get the device pixel ratio
	const ratio = window.devicePixelRatio || 1;

	// Define the users device screen dimensions
	const screen = {
		width : window.screen.width * ratio,
		height : window.screen.height * ratio
	};

	let rv;
	// iPhone X Detection
	if (iOS && screen.width === 1125 && screen.height === 2436) {
		rv = true;
	} else {
		rv = false;
	}

	// console.log(screen,'return',rv);

	return rv;
};
