import React, { Component } from 'react';
import { connect } from 'react-redux';
import Registration from '../../login/register/registration.component'
import {addPopup} from '../../popup/popup.component';
import GenericPopup from '../../popup/generic-popup/generic-popup.component';
import {ROUTES} from '../../../app.router';
import {getError, getLoading} from '../../login/login.selectors';
import {ButtonFacebook} from '../../common/button/common-button-with-facebook-icon.component';
import * as FB from '../../../assets/lib/facebook.lib';
import {readCustomPlanData, readPromoCode, writeFBUserData} from '../../../assets/lib/local-storage';
import NavigationBar, {NAV_BAR_ITEM_TYPES} from '../navigation-bar/navigation-bar.component';
import { CheckEmailAction, SetEmailPasswordFieldsAction, SignupUserAction, LoginFacebookAction } from '../../login/login.actions';
import { SaveRoute } from '../../../app.actions';
import './simple-registration.component.less';
import {getOrigin, getPaymentMethod} from "../../../app.selectors";
import {ATTRIBUTION_ORIGIN_TYPES, PAYMENT_TYPES} from "../../../constants";

const freePromoCodes = [ // TODO a new endpoint in gamesparks should define this
	"PLAYFREE", "MAILPROMO12", "RETROGAMES12", "TWITTER_0121_F12", "IG_0121_F12", "FB_0121_F12", "TRISTA_0121_F12", "JUSTICE_0121_F12", "HITCH_0121_F12", "AERIALSLAYER_0121_F12", "RETROGRADETOM_0121_F12", "BAGGERS_0121_F12", "GOOGLEADS_0121_F12", "YTC_0121_F12", "DISCORD_0121_F12"
]
class SimpleRegistrationComponent extends Component {
	constructor(props){
		super(props);

		this.state = {
			checkboxChecked:false
		};
	}
	
	componentDidMount() {
		const { accessToken } = this.props;

			if(accessToken) {
				this.props.dispatch(SaveRoute(ROUTES.PLANS));
				this.props.dispatch(LoginFacebookAction(accessToken,false));
			}

	}

	loginWithFacebook = () => {
		if(this.isChecked()){
			FB.login(ROUTES.CREATE_USER);
		}
	};

	isChecked = () => {
		if(this.state.checkboxChecked) return true;
		addPopup(<GenericPopup
			okButtonLabel="Got it!"
			message="Please accept the privacy policy to continue."
		/>);
		return false;
    };

    toggleChecked = () => {
        this.setState({
            checkboxChecked: !this.state.checkboxChecked
        });
        return this.state.checkboxChecked;
    }

	submitForm = (values) => {
		if(this.isChecked() && values){
			const {email} = values;
            this.submittedValues = values;
			this.props.dispatch(CheckEmailAction(email.toLowerCase(),this.onEmailResponse));
		}
	};

	onEmailResponse = (success) => {
		if(success){
			const {email, password} = this.submittedValues;
			this.props.dispatch(SetEmailPasswordFieldsAction(email,password));
			if(this.props.paymentMethod === PAYMENT_TYPES.FORTUMO) {
				this.props.dispatch(SaveRoute(ROUTES.FORTUMO, {
					paymentMethod: this.props.paymentMethod,
					origin: this.props.origin
				}));
			} else {
				this.props.dispatch(SaveRoute(ROUTES.PLANS));
			}

            this.props.dispatch(SignupUserAction());
		}else{
			addPopup(<GenericPopup
				title="Warning!"
				message={this.props.error}
			/>);
		}
    };

	isFacebookButtonAvailable= () => {
		const {attributionOrigin, paymentMethod} = this.props;
		if(paymentMethod === PAYMENT_TYPES.FORTUMO) return false;
		if(attributionOrigin && attributionOrigin === ATTRIBUTION_ORIGIN_TYPES.ATARI) return false;
		//if (this.inIframe()) return false; // Does not work if page embeded in an iFrame

		return true;
	}

	inIframe =() => {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	isFreePromoCode = () => {
		const promoCode = readPromoCode();
		if(!promoCode) return false;
		return true;
		
		// We don't show payment options at registration anymore, but keep this in case we bring it back in the future.
		//return !!freePromoCodes.find((freePromoCode) => freePromoCode.toLowerCase() === promoCode.toLowerCase());
	}

	render () {
		const isNoNavigationNeeded = this.isFreePromoCode() || this.props.paymentMethod === PAYMENT_TYPES.FORTUMO; 

		return(
			<div className="component-body">
				{!isNoNavigationNeeded &&
					<NavigationBar currentItem={NAV_BAR_ITEM_TYPES.CREATE_ACCOUNT.type}/>
				}
				<section className={`simple-registration-component ${isNoNavigationNeeded ? "simple-registration-component--center" : ""}`}>
					<Registration
						submitForm={this.submitForm}
						requirePrivacy={true}
						loading={this.props.isLoading}
						toggleChecked={this.toggleChecked}
					/>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const paymentMethod = getPaymentMethod(state);
	const origin = getOrigin(state);
	const accessToken = state.routing.search.access_token || state.routing.hash.access_token;
	const planData = readCustomPlanData();
	const attributionOrigin = planData && planData.attribution_origin ? planData.attribution_origin : null;
	const isLoading = getLoading(state);

	return {
		error: getError(state),
		attributionOrigin,
		accessToken,
		paymentMethod,
		origin,
		isLoading,
	};
};

export default connect(mapStateToProps)(SimpleRegistrationComponent);