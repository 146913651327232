import * as ACTIONS from './promo-code.actions';

const initialState = {
	waitingResponse:false,
	response:{},
};

export const promoCodeReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.APPLY_PROMO_CODE_ACTION:
			return {
				...state,
				waitingResponse: true,
				response:null,
			};
		case ACTIONS.APPLY_PROMO_CODE_FAIL:
		case ACTIONS.APPLY_PROMO_CODE_SUCCESS:
			return {
				...state,
				waitingResponse: false,
				response: action.response
			};
		case ACTIONS.CLEAR_PROMO_CODE_REDUCER:
			return initialState;
		default:
			return state;
	}
};
