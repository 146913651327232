import React, {Component} from 'react';
import { PulsarComponent } from '../pulsar/pulsar.component';
import './common-button.component.less';

class Button extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bounceAnimationActive:false
		};
	}

	onButtonClicked = (event) => {
		if(this.state.bounceAnimationActive)return;

		this.setState({bounceAnimationActive:true});
		if(this.props.onClick && this.props.withEventObject)this.props.onClick(event);
	};

	onAnimationEnd = () => {
		this.setState({bounceAnimationActive:false});
		if(this.props.onClick && !this.props.withEventObject)this.props.onClick();
	};

	renderLinkOverlay = () => {
		const {isLink, targetUrl} = this.props;
		if(isLink){
			return <a className="link-wrapper" href={targetUrl} target="_blank" rel="noopener noreferrer"> </a>;
		}else{
			return null;
		}
	};

	render() {
		let moreStyles = '';
		moreStyles += this.props.className;
		moreStyles += this.props.disabled ? ' disabled' : '';
		moreStyles += this.props.notSkewed ? ' not-skewed' : '';

		return (
			<button
				className={`common-button ${moreStyles} ${this.state.bounceAnimationActive?'bounce-animation':''}`}
				type={this.props.type || 'button'}
				disabled={this.props.disabled}
				onClick={this.onButtonClicked}
				onAnimationEnd={this.onAnimationEnd}
			>
				<div className="content">
					{this.props.children}
				</div>
				<PulsarComponent />
				{this.renderLinkOverlay()}
			</button>
		);
	}
}

export { Button };
