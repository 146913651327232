import React,{Component} from 'react';
import {connect} from 'react-redux';
import './payment.component.less';
import {CreateCardWithSCATokenAction, FetchPaymentPlans, ReplaceCardAction, ReplaceCardSCAAction} from '../login/login.actions';
import {Elements, StripeProvider} from 'react-stripe-elements';
import InjectedCheckoutForm from '../stripe/CheckoutForm';
import { navigateToSavedLocation } from '../../app.router';
import { getPaymentData, getSessionData, getPaymentPlans} from '../../app.selectors';
import logo from '../../assets/images/splash/antstream_logo_vector.svg';
import { PAYMENT_TYPES } from '../../constants';

export const PAYMENT_TYPE_BY_CARD = 'payByCard';
export const PAYMENT_TYPE_BY_REQUEST_BUTTON = 'payByRequestButton';

class Payment extends Component {
	constructor(props) {
		super(props);

		const paymentTitle = 'Payment';
		const paymentMainText = 'We only use your card details to take subscription payments. We\'ll never take any payment without your permission, and we don\'t store your details.';

		this.state = {
			paymentTitle,
			paymentMainText,
		};

		if(!this.props.paymentPlans) {
			this.props.dispatch(FetchPaymentPlans([PAYMENT_TYPES.STRIPE, PAYMENT_TYPES.PAYPAL]));
		}
	}

	onLaterButtonClicked = () => {
		if(this.props.onBackButtonClicked)this.props.onBackButtonClicked();
		else navigateToSavedLocation();
	};

	onStripeToken = (stripeToken) => {
		if(stripeToken && stripeToken.id) {
			if(this.props.paymentData.subscription && this.props.paymentData.paymentType === PAYMENT_TYPES.STRIPE) {
				this.props.dispatch(ReplaceCardAction(stripeToken.id));
			}else {
				this.props.dispatch(CreateCardWithSCATokenAction(stripeToken.id,this.props.sku));
			}
		}
	};

	onStripePaymentMethod = (paymentMethod) => {
		if(paymentMethod && paymentMethod.id) {
			if(this.props.paymentData.paymentType === PAYMENT_TYPES.STRIPE && (this.props.paymentData.subscription || this.props.paymentData.subscriptionStatus==="past_due")) {
				console.log("[onStripePaymentMethod] Found sub payment data", paymentMethod);
				this.props.dispatch(ReplaceCardSCAAction(paymentMethod.id));
			} else {
				console.log("[onStripePaymentMethod] sub payment data NOT found", this.props.sku);
				this.props.dispatch(CreateCardWithSCATokenAction(paymentMethod.id, this.props.sku));
			}
		}
	};

	getStripeEnvKey = () => {
		return window.config.REACT_APP_STRIPE_KEY;
	};

	renderContent = () => {
		if(!this.props.asComponent) {
			return (
				<>
					<div className="logo-container">
						<img src={logo} alt="logo" />
					</div>
					<h1 className="title-text">
						{this.state.paymentTitle}
					</h1>
					<h4 className="info-text">
						{this.state.paymentMainText}
					</h4>
				</>
			);
		} else {
			return null
		}
	};

	render() {
		const {paymentPlans,sku} = this.props;

		if(!paymentPlans)return null;

		const selectedPaymentPlan = sku && paymentPlans ? paymentPlans.find(plan=>plan.sku===sku):null;

		return (
			<div className={`payment-component ${!this.props.asComponent?'standalone':''}`}>
				{this.renderContent()}
				<div className="card-details-container">
					<StripeProvider apiKey={this.getStripeEnvKey()}>
						<Elements>
							<InjectedCheckoutForm
								onStripeToken={this.onStripeToken}
								onStripePaymentMethod={this.onStripePaymentMethod}
								onLaterButtonClicked = {this.onLaterButtonClicked}
								selectedPaymentPlan = {selectedPaymentPlan}
							/>
						</Elements>
					</StripeProvider>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const paymentData = getPaymentData(state);
	const paymentPlans = getPaymentPlans(state);
	const sessionData = getSessionData(state);
	const sku = sessionData ? sessionData.sku : null;

	return {
		paymentData,
		paymentPlans,
		sku,
	};
};

export default connect(mapStateToProps,null)(Payment);
