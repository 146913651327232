import React from 'react';
import './popup-overlay.component.less';

const PopupOverlay = () => {
	return (
		<div
			className="popup-overlay"
			id="popup-overlay" />
	);
};

export default PopupOverlay;
