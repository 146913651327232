import React from 'react';

import './pulsar.component.less';


const PulsarComponent = ({borderWithRadius}) => {
	return (
		<div className="pulsar-component">
			<div className={`back-border ${borderWithRadius?'radius':''}`}/>
			<div className={`front-border ${borderWithRadius?'radius':''}`}/>
		</div>
	);
};

export { PulsarComponent };
