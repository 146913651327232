import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROUTES, navigateToLocation } from '../../../app.router';
import { getAvatars } from '../login.selectors';
import { SetUserAvatarAction } from '../login.actions';
import '../login-global.less';
import inputManager from '../../../assets/lib/inputmanager';
import {getAppLoading, getLoggedUser, getRoutingSearch} from '../../../app.selectors';
import {Button} from '../../common/button/common-button.component';
import AvatarComponent from '../../common/avatar/avatar.component';
import logo from '../../../assets/images/splash/antstream_logo_vector.svg';
import {Loader} from "../../common/loader/loader.component";

const navId = 'signup-avatar';
const navLayer = 0;
class SignupAvatarComponent extends Component {
	constructor() {
		super();
		this.state = {};
	}

	componentDidMount() {
	}

	componentWillUnmount() {
		inputManager.removeRow(navId, navLayer);
	}

	onFetchComplete = () => {
		inputManager.refreshNavMap(navId,navLayer);
		inputManager.setCurrentElement(navLayer,0,0);
	};

	onAvatarClicked = (selectedId) => {
		if (this.props.avatarId === selectedId) return;
		if (this.props.isLoading) return;

			this.props.dispatch(SetUserAvatarAction(selectedId, () => {
				if(this.props.returnUpdateProfile) {
					navigateToLocation(ROUTES.UPDATE_PROFILE);
				}else{
					navigateToLocation(ROUTES.HOMEPAGE);
				}
			}));

	};

	back = () => {
		navigateToLocation(ROUTES.UPDATE_PROFILE);
	};

	render () {
		if(!this.props.avatarList) return null;
		const filteredAvatarList = this.props.avatarList.filter(ava => ava.isHidden !== true);

		if (this.props.isLoading) {
			return (
				<div className="loader-games">
					<Loader loading={true}/>
				</div>
			);
		}

		return(
			<div className="login-wrapper">
				<div className="login-section login-section--avatar">
					<div className="logo-container">
						<img src={logo} alt="logo" />
					</div>
					<h1>Select Avatar</h1>
					{filteredAvatarList.map((item,index) => {
						return (
							<AvatarComponent
								key={`avatar-${index}`}
								item={item}
								navProps={navId}
								onAvatarClicked={this.onAvatarClicked}
							/>
						);
					})}
					<div>
						{this.props.returnUpdateProfile?<Button
							className="secondary"
							onClick={this.back}
							navProps={navId}
						>
							Back
						</Button>:null}
						{/*<FileUploadForm*/}
						{/*navProps={navId}*/}
						{/*/>*/}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const user = getLoggedUser(state);
	const isLoading = getAppLoading(state);
	const returnUpdateProfile = getRoutingSearch(state).return === 'updateProfile';

	return {
		avatarList:getAvatars(state),
		returnUpdateProfile,
		avatarId: user ? user.profile_image_id : null,
		isLoading,
	};
};
  
export const SignupAvatar = connect(mapStateToProps)(SignupAvatarComponent);
