import React from 'react';

import { Button } from './common-button.component';
import './common-button-with-facebook-icon.component.less';
import thumbImage from '../../../assets/images/global/facebook_logo_square.svg';

const ButtonFacebook = (props) => {
	return (
		<Button
			{...props}
			className={`${props.className} with-facebook`}
		>
			<img className="facebook-image" src={thumbImage} alt="facebook"/>
			<div className="text-container">
				{props.children}
			</div>
		</Button>
	);
};

export { ButtonFacebook };
