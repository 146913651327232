/*eslint no-empty-function: 0, no-console: 0, no-mixed-operators: 0 */
export const logger = (store) => next => action => {
	const log = 0;
	if (log) console.group(action.type);
	if (log) console.log('dispatching', action);
	const result = next(action);
	if (log) console.log('next state', store.getState());
	if (log) console.groupEnd();
	return result;
};

export const objectToParams = (paramsObj) => {
	let str = '';
	for (const key in paramsObj) {
		if (paramsObj[key]) {
			if (str !== '') {
				str += '&';
			}
			str += `${key}=${encodeURIComponent(paramsObj[key])}`;
		}
	}
	return `?${str}`;
};

export const objectToMessage = (obj) => {
	return Object.keys(obj).reduce((acc, key) => {
		acc += `${key}: ${obj[key]}`;
		return acc;
	}, '');
};

export const paramsToObject = ( url ) => {
	let start = url.indexOf('?');
	if (start === -1) start = url.indexOf('#');
	if (start === -1) return {};

	let params = url.slice(start + 1);
	params = params.split('&');

	return params.reduce((acc, p) => {
		 const values = decodeURIComponent(p).split('=');
		 acc[values[0]] = values[1];
		 return acc;
	}, {});
};

export const scrollTo = (element, to, duration, endCallback, property = 'scrollTop') => {
	// console.log({element, to, duration, endCallback, property})
	const start = element[property],
		change = to - start,
		increment = 20;


	const animateScroll = function(elapsedTime) {
		elapsedTime += increment;
		const position = easeInOut(elapsedTime, start, change, duration);
		element[property] = position;
		if (elapsedTime < duration) {
			setTimeout(() => {
				animateScroll(elapsedTime);
			}, increment);
		}
		else {
			endCallback();
		}
	};

	animateScroll(0);
};

	/*
	* SC:
	* IN: element: The DOM element to scroll
	* IN: property: The element property we are animating - Usually scrollTop or scrollLeft
	* IN: targetPos: The value we want the property for end on
	* IN: scrollInertia (optional): How fast we want the animation to happen.
	*/
export function startScrollToAnimation (element, axis,  targetPos, speed = '500', animate = true) {
	if (!animate) {
		element = targetPos;
		return;
	}

	element.style.WebkitTransition = `transform ${speed}ms`;

	if (axis === 'x') {
		element.style.webkitTransform = `translateX(-${targetPos}px)`;
	} else {
		element.style.webkitTransform = `translateY(-${targetPos}px)`;
	}

	return new Promise(resolve => {
		setTimeout(() => resolve(), speed);
	});
};
export const FindReact = (dom)=> {
	for (const key in dom) {
		if (key.startsWith('__reactInternalInstance$')) {
			const compInternals = dom[key]._currentElement;
			const compWrapper = compInternals._owner;
			const comp = compWrapper._instance;
			return comp;
		}
	}
	return null;
};
const easeInOut = (currentTime, start, change, duration) => {
	currentTime /= duration / 2;
	if (currentTime < 1) {
		return change / 2 * currentTime * currentTime + start;
	}
	currentTime -= 1;
	return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
};

export const generateUUID = function() {
	let d = new Date().getTime();
	const uuid = 'xxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, (c) =>{
		const r = (d + Math.random()*16)%16 | 0;
		d = Math.floor(d/16);
		return (c==='x' ? r : r&0x3|0x8).toString(16);
	});
	return uuid;
};

export const getTransformPosition = (element) => {
	const value = parseFloat(element.style.transform.match(/[0-9.]+/));
	return isNaN(value) ? 0 : value;
};

export const addClass = (element, classname) => {
	element && element.classList && element.classList.add(classname);
};

export const removeClass = (element, classname) => {
	element && element.classList && element.classList.remove(classname);
};

export const usingLivePayment = () => {
    return !!(window.config.REACT_APP_ENV === 'live');
 };
