
export const getBrowserData = () => {
	const browsers = [
		{
			string: navigator.userAgent,
			subString: 'Chrome',
			identity: 'Chrome'
		},
		{ 	string: navigator.userAgent,
			subString: 'OmniWeb',
			versionSearch: 'OmniWeb/',
			identity: 'OmniWeb'
		},
		{
			string: navigator.vendor,
			subString: 'Apple',
			identity: 'Safari',
			versionSearch: 'Version'
		},
		{
			prop: window.opera,
			identity: 'Opera',
			versionSearch: 'Version'
		},
		{
			string: navigator.vendor,
			subString: 'iCab',
			identity: 'iCab'
		},
		{
			string: navigator.vendor,
			subString: 'KDE',
			identity: 'Konqueror'
		},
		{
			string: navigator.userAgent,
			subString: 'Firefox',
			identity: 'Firefox'
		},
		{
			string: navigator.vendor,
			subString: 'Camino',
			identity: 'Camino'
		},
		{
			string: navigator.userAgent,
			subString: 'Netscape',
			identity: 'Netscape'
		},
		{
			string: navigator.userAgent,
			subString: 'MSIE',
			identity: 'Explorer',
			versionSearch: 'MSIE'
		},
		{
			string: navigator.userAgent,
			subString: 'Gecko',
			identity: 'Mozilla',
			versionSearch: 'rv'
		},
		{
			string: navigator.userAgent,
			subString: 'Mozilla',
			identity: 'Netscape',
			versionSearch: 'Mozilla'
		}
	];

	const operatingSystems = [
		{
			string: navigator.platform,
			subString: 'Win',
			identity: 'Windows'
		},
		{
			string: navigator.platform,
			subString: 'Mac',
			identity: 'Mac'
		},
		{
			string: navigator.userAgent,
			subString: 'iPhone',
			identity: 'iPhone/iPod'
		},
		{
			string: navigator.platform,
			subString: 'Linux',
			identity: 'Linux'
		}
	];

	function searchForIdentity(data) {
		for (let i = 0; i < data.length; i++) {
			const string = data[i].string;
			const prop = data[i].prop;

			if (string) {
				// Look for the sub string in the string.
				if (string.indexOf(data[i].subString) !== -1) {
					return data[i].identity;
				}
			} else if (prop) {
				return data[i].identity;
			}
		}
	}

	return {
		browser: searchForIdentity(browsers),
		operatingSystem: searchForIdentity(operatingSystems)
	};
};
