import React, { Component } from 'react';
import './avatar.component.less';
import {getAvatarUrl} from '../../../entities/entities.selectors';
import { connect } from 'react-redux';

class AvatarComponent extends Component {
	onAvatarClicked = (event) => {
		if(this.props.onAvatarClicked)this.props.onAvatarClicked(this.props.item.type,event);
	};

	render () {
		return (
			<div
				className={`avatar-component ${this.props.className?this.props.className:''} ${this.props.selected?'selected':''} ${this.props.circle?'circle':''}`}
				onClick={this.onAvatarClicked}
			>
				<img
					className="avatar-image"
					alt={`avatar-${this.props.item.type}`}
					src={this.props.avatarUrl}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state,ownProps) => {
	return {
		avatarUrl:getAvatarUrl(state, ownProps.item.type),
	};
};

export default connect(mapStateToProps)(AvatarComponent);
