import React, { Component } from 'react';
import "./navigation-bar.component.less";
import NavigationBarItem from "./navigation-bar-item.component";


export const NAV_BAR_ITEM_TYPES = {
	CREATE_ACCOUNT:{type:"CREATE_ACCOUNT", name:"Create account"},
	SUBSCRIPTION:{type:"SUBSCRIPTION", name:"Subscription"},
	PAYMENT:{type:"PAYMENT", name:"Payment"},
};

class NavigationBarComponent extends Component {

	renderItems = () => {
		const elements = [];
		let currentItemFound = false;
		let nextIndex = 1;

		Object.keys(NAV_BAR_ITEM_TYPES).forEach(key=> {
			const itemObject = NAV_BAR_ITEM_TYPES[key];
			const isCurrentItem = itemObject.type === this.props.currentItem;
			if(isCurrentItem)currentItemFound=true;
			elements.push(<NavigationBarItem key={"nav-bar-item"+itemObject.name} status={!currentItemFound?'past': isCurrentItem?'current' : 'next-'+nextIndex} itemName={itemObject.name}/>)
			if(currentItemFound && !isCurrentItem)nextIndex++;
		});

		return elements;
	};

	render () {
		return(
			<div className="navigation-bar">
				<div className="current-item-name">{NAV_BAR_ITEM_TYPES[this.props.currentItem].name}</div>
				<div className="navigation-bar-item-container">
					{this.renderItems()}
				</div>
			</div>
		);
	}
}

export default NavigationBarComponent;