import { push, goBack } from 'connected-react-router'
import { store } from './configureStore';
import { SaveRoute } from './app.actions';
import {
	GetAccountDetailsAction,
	LoginFacebookAction,
	SetCustomPlans,
	SetPlanType
} from './components/login/login.actions';
import {getIsAppInitialised, getIsLoggedIn, getRoutingAuthToken, getSavedRoute} from './app.selectors';
import { objectToParams } from './app.helpers';
import {
	KEY_SHOW_ACCOUNT_DELETE_BTN,
	readFBUserData,
	readRestUserData,
	writeRestUserData,
	writeShowAccountDeleteBtn
} from './assets/lib/local-storage';

const guardRouting = (route, params= {}, search= {}) => {
	const isInitialised = getIsAppInitialised(store.getState());
	const isLogged = getIsLoggedIn(store.getState());

	if (route.init && !isInitialised) {
		store.dispatch(SaveRoute(route, params, search));
		return ROUTES.SPLASH;
	}else if (route.login && !isLogged) {
			const autoLoginToken = getRoutingAuthToken(store.getState());
			if (autoLoginToken) {
				writeRestUserData({email: null, authToken: autoLoginToken, userId: null});
				store.dispatch(SaveRoute(route, params, search));
				store.dispatch(GetAccountDetailsAction());
				return ROUTES.HOMEPAGE;
			}

			const userData = readRestUserData();
			if (userData) {
				store.dispatch(SaveRoute(route, params, search));
				store.dispatch(GetAccountDetailsAction());
				return ROUTES.HOMEPAGE;
			}

			const accessToken = readFBUserData();
			if (accessToken) {
				store.dispatch(SaveRoute(route, params, search));
				store.dispatch(LoginFacebookAction(accessToken, true));
				if (route === ROUTES.HOMEPAGE) {
					return ROUTES.HOMEPAGE;
				} else {
					return null;
				}
			}

			return ROUTES.LANDING;

	}

	return route;
};

const composeRoutes = () => {
	const { init, login, noHeader, noHistory } = {init: true, login: true, noHeader: true, noHistory: true };
	return {
		SPLASH: 				{path: '/', noHistory, noHeader},
		LOGIN_CREDENTIALS:		{path: '/login/credentials', init, noHeader, noHistory},
		SIGNUP_AVATAR: 			{path: '/signup/avatar', init, login, noHeader, noHistory},
		RESET_PASSWORD:			{path: '/login/reset-password', init, noHeader, noHistory},
		PASSWORD_RESET_REQUEST: {path: '/login/request-password-reset', init, noHeader},
		HOMEPAGE: 				{path: '/homepage', init, login},
		UPDATE_PROFILE: 		{path: '/update-profile', init, login},
		PAYMENT: 				{path: '/payment', init, login, noHeader},
		PAYMENT_SELECTION: 		{path: '/payment-selection/', init, login, noHeader},
		LANDING: 				{path: '/landing', init, noHeader, noHistory},
		CREATE_USER: 			{path: '/create-user', init, noHeader, noHistory},
		DISPLAY_NAME: 			{path: '/display-name', init, login, noHeader, noHistory},
		AVATAR_SELECTION: 		{path: '/avatar', init, login, noHeader, noHistory},
		GDPR: 					{path: '/gdpr', init, noHeader, noHistory},
		FORTUMO: 				{path: '/fortumo', init, noHeader, noHistory,login},
		PLANS: 					{path: '/plans', init, noHeader, noHistory,login},
		CARD_DETAILS: 			{path: '/card-details', init, noHeader, noHistory,login},
		ACCOUNT_CREATED: 		{path: '/account-created', init, noHeader, noHistory, login},
		PROMO_CODE: 			{path: '/code', init, login},
		DOCOMO_REGISTRATION:	{path: '/docomo-registration'},
		DOCOMO_ACKNOWLEDGE:		{path: '/docomo-acknowledgement'},
		THANK_YOU:				{path: '/thank-you'},
	};
};

const replaceParams = (path, params = {}) => {
	Object.keys(params).forEach((k) => {
		path = path.replace(`:${k}`, params[k]);
	});
	return path;
};

export const matchRoute = (pathname, routeList = ROUTES) => {
	for (const routename in routeList) {
		if (routeList.hasOwnProperty(routename)) {
			const route = routeList[routename];
			if (matchingPaths(pathname, route.path)) return route;
		}
	}
};

export const checkForPlans = (search = {}) => {
	const pln = new URLSearchParams(search).get("pln");
	if(pln) SetCustomPlans(pln);
};

export const checkShowAccountDeleteBtn = (search = {}) => {
	const deleteBtn = new URLSearchParams(search).get(KEY_SHOW_ACCOUNT_DELETE_BTN);
	if (deleteBtn === 'true') {
		writeShowAccountDeleteBtn(deleteBtn);
	}
};

export const checkPlanTypeSelection = (search = {}) => {
	const type = new URLSearchParams(search).get("type");
	if(type) SetPlanType(type);
}

// XXX Matches url segments, please replace with something from a library if found
const matchingPaths = (a, b) => {
	const aslices = a.split('/').filter(x => !!x);
	const bslices = b.split('/').filter(x => !!x);
	if (aslices.length !== bslices.length) return false;
	if (aslices.join() === bslices.join()) return true;
	for (let i = 0; i < aslices.length; i++) {
		const aseg = aslices[i];
		const bseg = bslices[i];
		if (aseg === bseg) continue;
		if (aseg[0] === ':' || bseg[0] === ':') continue;
		return false;
	}
	return true;
};

export const navigateToLocation = (route, params={}, search) => {
	if (typeof route === 'string') {
		let matchedRoute = matchRoute(route, ROUTES);
		if(!matchedRoute) {
			matchedRoute = ROUTES.HOMEPAGE;
		}
		route = matchedRoute;
	}

	checkShowAccountDeleteBtn(search);
	checkForPlans(search);
	checkPlanTypeSelection(search);

	const selectedRoute = guardRouting(route, params, search);
	if(!selectedRoute)return;

	const pathname = replaceParams(selectedRoute.path, params);
	if (typeof search !== 'string') search = objectToParams({...search, ...params});
	const locationObj = { pathname, search, hash: params.hash, original: route };

	store.dispatch(push(locationObj));
	window.scrollTo(0,0);
};

export const navigateToSavedLocation = () => {
	const savedRoute = getSavedRoute(store.getState());
	store.dispatch(SaveRoute(null));
	if(savedRoute && savedRoute.route) {
		navigateToLocation(savedRoute.route,savedRoute.params,savedRoute.search);
	}else {
		navigateToLocation(ROUTES.HOMEPAGE);
	}
};

export const navigateBack = () => {
	store.dispatch(goBack());
};

export const ROUTES = composeRoutes();
