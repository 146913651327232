import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./landing.component.less";
import {Button} from '../../common/button/common-button.component';
import landingBackground from '../../../assets/images/ftui/landing-page.png';
import {navigateToLocation, ROUTES} from '../../../app.router';
import {clearPlan, clearPromoCode, readPromoCode} from '../../../assets/lib/local-storage';

class LandingComponent extends Component {
	onCreateAccountClicked = () => {
		let search;

		// if promo code exists in storage - do not clear it - use it 
		const promoCode = readPromoCode();
		if (!promoCode || promoCode === 'PLAYFREE') {
			clearPlan();
			clearPromoCode();
			// Give users the default PLAYFREE promo
			search = "pln=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBbnRTdHJlYW0iLCJpYXQiOjE2MDc3MDU2OTMsImV4cCI6MjU4NTkyNjQ5MywiYXVkIjoiYWNjb3VudHMuYW50c3RyZWFtLmNvbSIsInN1YiI6IkFudFN0cmVhbSIsInByb21vQ29kZSI6IlBMQVlGUkVFIiwiYXR0cmlidXRpb25fb3JpZ2luIjoicGxheWZyZWUiLCJhdHRyaWJ1dGlvbl9pZCI6InBsYXlmcmVlIiwiY2FtcGFpZ24iOiJwbGF5ZnJlZSIsImhpZGVQcm9tbyI6ZmFsc2V9.3XWtdYjtqSNg1wzIxCuQKExH3ikahbVWtfEyg9o4WZE";
		}

		navigateToLocation(ROUTES.CREATE_USER, {}, search);
	};

	onLoginClicked = () => {
		navigateToLocation(ROUTES.LOGIN_CREDENTIALS);
	};

	render () {
		return(
			<div className="landing">
				<div className="text-container">
					The easiest place in the world to play the most iconic games of all time. Join our live tournament today!
				</div>
				<div className="text-container-small">
					To get started, create an account. Then download the app and play!
				</div>
				<div className="buttons-container">
					<Button
						className="danger"
						onClick={this.onCreateAccountClicked}
					>
						Let's Go!
					</Button>
					<div className="login-button-text-container">
						If you already have an account.
					</div>
					<Button
						className="light-blue"
						onClick={this.onLoginClicked}
					>
						Login
					</Button>
				</div>
				<img className="background-image" src={landingBackground} alt="landing background"/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps)(LandingComponent);