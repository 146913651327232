import {Component} from 'react';
import { connect } from 'react-redux';
import React from 'react';
import './payment-selection.component.less';
import {getLoggedUser} from '../../app.selectors';
import logo from '../../assets/images/splash/antstream_logo_vector.svg';
import {Button} from '../common/button/common-button.component';
import {PaymentPlanItem} from './payment-plan-item.component';
import {navigateToLocation, navigateToSavedLocation, ROUTES} from '../../app.router';
import {getSessionData, getPromoLimit, getPaypalPlans, getPaymentPlans} from '../../app.selectors';
import {FetchPaymentPlans, SetCurrentSku} from '../login/login.actions';
import {GetIpStackObject} from '../../app.actions';
import {
	readPlanTypeData,
	clearPlanTypeData,
} from '../../assets/lib/local-storage';
import {PaypalButton} from "../paypal/paypal-button.component";
import {PAYMENT_TYPES} from "../../constants";
import {usingLivePayment} from "../../app.helpers";
import {isFreeTierV2FeatureFlagEnabled} from "../../assets/lib/utils";
import { Loader } from '../common/loader/loader.component';

export const ANNUAL_PLAN_TEXT  = "Save nearly two and a half months subscription cost with the annual one off payment.";
export const MONTHLY_PLAN_TEXT = "Pay monthly with no fixed obligation.";
export const WEEKLY_PLAN_TEXT  = "Pay weekly with no fixed obligation.";
export const DAILY_PLAN_TEXT   = "Pay daily with no fixed obligation.";

class PaymentSelectionComponent extends Component {
	constructor(props) {
		super(props);
		this.state={
			isNeedToFetchPlans: false,
			isPaypalEnabled:false,
			selectedPlan:null
		}
	}

	componentDidMount() {
		this.props.dispatch(GetIpStackObject(this.onLocationObjectFetched));
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isPaymentDataPending && !this.props.isPaymentDataPending && this.state.isNeedToFetchPlans) {
			this.setState({isNeedToFetchPlans: false});
			this.props.dispatch(FetchPaymentPlans([PAYMENT_TYPES.STRIPE, PAYMENT_TYPES.PAYPAL], this.onPlansFetched));
		}
	}

	onLocationObjectFetched = () => {
		if (this.props.isPaymentDataPending) {
			this.setState({isNeedToFetchPlans: true});
			return;
		}
		this.props.dispatch(FetchPaymentPlans([PAYMENT_TYPES.STRIPE, PAYMENT_TYPES.PAYPAL], this.onPlansFetched));
	};

	onPlansFetched = (error) => {
		let {planType} = this.props;
		const {plans, paypalPlans} = this.props;
		if (!error && plans) {
			planType = planType || "annual";
			const selectedPlan = plans.find((plan)=>plan.term.indexOf(planType) > -1);
			if (selectedPlan) {
                this.onPlanSelected(selectedPlan);
            }
			clearPlanTypeData();
			this.checkPaypalButtonState(plans,paypalPlans);
		}
	};

    getPlanTermDescription = (term) => {
        if (term === "annual") {  return ANNUAL_PLAN_TEXT; }
        if (term === "daily")  {  return DAILY_PLAN_TEXT;  }
        return MONTHLY_PLAN_TEXT;
    }

    getMonthlyPrice = (term, planPrice) => {
        if (term === "annual") {  return planPrice / 12; }
        return planPrice;
    }

    getAnnualPrice = (term, planPrice) => {
        if (term === "daily")   {  return planPrice * 365; }  // Let's hope it's not a leap year
        if (term === "monthly") {  return planPrice * 12; }  
        return planPrice;
    }

	renderPlans = () => {
		const items = [];
		const {plans, promo, isFreeTierV2FeatureEnabled} = this.props;

		if (this.props.isPaymentDataPending || this.props.fetchPaymentPlansPending) {
			return (
				<div className="spinner-container">
					<Loader loading={true}/>
				</div>
			);
		}

		if (!plans || !Array.isArray(plans) || !plans.length) {
            return null;
        }

        for (let s in plans) {
            const plan      = plans[s];
            const planTitle = plan.term.indexOf("custom") > -1 ? plan.term.substr(7): plan.term;
            const planTerm  = planTitle;
            const planText     = (plan.description) ? plan.description : this.getPlanTermDescription(planTerm);
            const monthlyPrice = this.getMonthlyPrice(planTerm, plan.price);
            const annualPrice  = this.getAnnualPrice (planTerm, plan.price);

            const priceFormatted       = `${plan.currencyString}${monthlyPrice.toFixed(2)}`;
            const annualPriceFormatted = `${plan.currencyString}${annualPrice.toFixed(2)}`;

            if (promo === "all"
				|| promo.toLowerCase() === planTerm.toLowerCase()
				|| promo.toLowerCase() === planTerm.toLowerCase().replace('_v2.2', '')
			) {
				items.push(<PaymentPlanItem
					isFreeTierV2FeatureEnabled={isFreeTierV2FeatureEnabled}
					key={plan.sku}
					className={plan.term}
					title={planTitle}
					term={planTerm}
					priceFormatted={priceFormatted}
					annualPriceFormatted={annualPriceFormatted}
					sku={plan.sku}
					selectedSku={this.props.sku}
					description={planText}
					onSelect={() => this.onPlanSelected(plan)}
				/>);
			}
        }

		return items;
	};

	onBackClicked = () => {
		navigateToSavedLocation();
	};

	onContinueClicked = () => {
		navigateToLocation(ROUTES.PAYMENT);
	};

	checkPaypalButtonState = (plans,paypalPlans) => {
		var isPaypalEnabled = true;

        // On Live we only show the PayPal button if there is a PayPal plan that matches every
        // Stripe plan
        if (usingLivePayment()) {
            let paypalObjectsCount = 0;
            plans.forEach((plan)=>{
				const planPrice = plan.price.toFixed(2) * 100;
				const filteredPaypalPlans = paypalPlans.filter(paypalPlan => {
					return plan.interval === paypalPlan.interval
						&& planPrice === paypalPlan.amount;
				});
                if(filteredPaypalPlans.length>0)paypalObjectsCount++;
            });
		    isPaypalEnabled = !!(paypalObjectsCount===plans.length);

		    // if promo code was used and it has limit for annual or month only - no need to hide the paypal button
			if (!isPaypalEnabled && this.props.promo !== 'all') {
				isPaypalEnabled = true;
			}
        }
		this.setPaypalState(isPaypalEnabled);
	}

	setPaypalState = (isPaypalEnabled) =>  {
		this.setState({isPaypalEnabled});
		if (this.props.onPaypalStateUpdate) this.props.onPaypalStateUpdate(isPaypalEnabled);
	}

	onPlanSelected = (plan) => {
		this.setState({selectedPlan:plan});
		this.props.dispatch(SetCurrentSku(plan.sku));
		if (this.props.onPlanSelected) this.props.onPlanSelected(plan);
	};

	onPaypalApproved = () => {
		navigateToLocation(ROUTES.HOMEPAGE);
	};

	render() {
		if(this.props.asComponent){
			return(
				<div className="payment-selection">
					<div className="plans-container">
						{this.renderPlans()}
					</div>
				</div>
			);
		}

		return (
			<section className="payment-selection">
				<img src={logo} className="logo" alt="logo" />
				<h1>You are not subscribed yet!</h1>
				<div className="description">
					Enjoy a truly evolved retro experience from our ever growing collection of treasured titles. Immerse yourself in a world of challenges anywhere, on any supported device.
				</div>
				<div className="plans-container">
					{this.renderPlans()}
				</div>

				<div className="text-container">
					<p>With Antstream, all you need is an account and our app.  You can play cross platform, with controllers, keyboards or touchscreens. You can access any game in our library at any time and play with no limits.</p>
					<div className="spacer"/>
				</div>

				<div className="buttons-container">
					<Button
						disabled={!this.props.plans || !this.props.plans.length}
						className="secondary"
						notSkewed
						onClick={this.onContinueClicked}
					>
						Pay By Card
					</Button>
					{ this.state.isPaypalEnabled && <PaypalButton plan={this.state.selectedPlan} onApprove={this.onPaypalApproved}/>}
					<Button
						className="secondary back-button"
						onClick={this.onBackClicked}
					>
						Back
					</Button>
				</div>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	const loggedUser = getLoggedUser(state);
	const promo = getPromoLimit(state);
	const plans = getPaymentPlans(state); // stripe plans
	const paypalPlans = getPaypalPlans(state);
	const sessionData = getSessionData(state);
	const planType = readPlanTypeData() || null;
	const sku = sessionData ? sessionData.sku : null;

	return {
		fetchPaymentPlansPending: state.app.fetchPaymentPlansPending,
		isPaymentDataPending: state.app.isPaymentDataPending,
		isFreeTierV2FeatureEnabled: isFreeTierV2FeatureFlagEnabled(state), 
		loggedUser,
		promo,
		plans,
		paypalPlans,
		planType,
		sku
	};
};

export const PaymentSelection = connect(mapStateToProps)(PaymentSelectionComponent);
