import React, {Component} from 'react';
import {CardElement} from 'react-stripe-elements';

class CardSection extends Component {
	constructor(props) {
		super(props);
		this.state ={};
	}
	render() {
		return (
			<div className="card-section" >
				<CardElement
					style={{
						base: {
							iconColor: '#c4f0ff',
							color: '#fff',
							fontWeight: 500,
							fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
							fontSize: '18px',
							fontSmoothing: 'antialiased',

							':-webkit-autofill': {
								color: '#fce883',
							},
							'::placeholder': {
								color: '#a0a0a0',
							},
						},
						invalid: {
							iconColor: '#FFC7EE',
							color: '#FFC7EE',
						},
					}}
				/>
			</div>
		);
	}
};
export default CardSection;
