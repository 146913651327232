import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./card-details-payment.component.less";
import NavigationBar, {NAV_BAR_ITEM_TYPES} from '../navigation-bar/navigation-bar.component';
import Payment from '../../payment/payment.component';
import {SaveRoute} from '../../../app.actions';
import { getSessionData, getPaymentPlans} from '../../../app.selectors';
import {navigateToLocation, ROUTES} from '../../../app.router';

class CardDetailsPaymentComponent extends Component {
	componentDidMount(){
		this.props.dispatch(SaveRoute(ROUTES.ACCOUNT_CREATED));
	}

	onBackButtonClicked = () => {
		navigateToLocation(ROUTES.PLANS);
	};

	render () {
		const {paymentPlans,sku} = this.props;
		const selectedPaymentPlan = sku && paymentPlans ? paymentPlans.find(plan=>plan.sku===sku):null;
		if(!selectedPaymentPlan) return null;
		return(
			<div className="card-details-payment">
				<div className="component-body">
					<NavigationBar currentItem={NAV_BAR_ITEM_TYPES.PAYMENT.type}/>
					<div className="text-container">
						<h1 className="title">Pay by card</h1>
		<p>{selectedPaymentPlan.description} - {selectedPaymentPlan.currencyString + parseFloat(selectedPaymentPlan.price).toFixed(2)} {selectedPaymentPlan.term}</p>
					</div>
					<Payment
						asComponent
						onBackButtonClicked={this.onBackButtonClicked}
					/>
					
				</div>
				
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const paymentPlans = getPaymentPlans(state);
	const sessionData = getSessionData(state);
	const sku = sessionData ? sessionData.sku : null;

	return {
		paymentPlans,
		sku,
	};
};

export default connect(mapStateToProps)(CardDetailsPaymentComponent);