import Antstream from '../../assets/lib/Antstream';

export const FETCH_AVATARS_ACTION = 'Fetch All Avatars Action';
export const FetchAvatarsAction = (onComplete) => {
	return (dispatch) => {
		dispatch({ type: FETCH_AVATARS_ACTION });
		Antstream
			.getAvatars()
			.subscribe((payload) => {
				dispatch(FetchAvatarsSuccessAction(payload));
				if(onComplete)onComplete();
			});
	};
};

export const FETCH_AVATARS_ACTION_SUCCESS = 'Fetch All Avatars Success';
export const FetchAvatarsSuccessAction = (payload) => {
	return (dispatch) =>  {
		Object.values(payload).forEach((avatarObject)=>{
			avatarObject._id = avatarObject.type;
		});
		dispatch({type: FETCH_AVATARS_ACTION_SUCCESS, payload});
	}
};
