import {Subject} from 'rxjs/Subject';
import {FACEBOOK_APP_ID} from '../../constants';

export const login = (redirectRoute) => {
	const path = redirectRoute.path || '';
	const redirect = window.location.origin + path;
	const url = `https://www.facebook.com/v3.2/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${redirect}&response_type=token&scope=email`;
	window.top.location.href = url;
};

export const shareWithFriendsLinkWithRedirectUri = (redirect) => {
	redirect = window.location.origin + redirect;
	window.location.href = `https://www.facebook.com/v3.2/dialog/share?client_id=${FACEBOOK_APP_ID}&href=www.antstream.com&display=popup&redirect_uri=${redirect}`;
};

export const getFacebookUserData = (accessToken) => {
	const subj = new Subject();
	fetch(`https://graph.facebook.com/me/?access_token=${accessToken}&fields=name,id,email`)
		.then(function (response) {
			return response.json();
		})
		.then(function(data) {
			subj.next(data);
			subj.complete();
		});
	return subj;
};

export const getFBImageUrl = (id) => {
	return `https://graph.facebook.com/${id}/picture?width=500&height=500`;
};

export const checkImageUrl = (url) => {
	if (url && url.slice(0, 4) !== 'http') return getFBImageUrl(url);
	return url;
};
