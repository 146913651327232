import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import {logger} from './app.helpers';
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './app.reducer';

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
    createRootReducer(history), // root reducer with router state
    {},
    composeEnhancers(
        applyMiddleware(
            ReduxThunk,
            routerMiddleware(history), // for dispatching history actions
            logger
        ),
    ),
)
