/* eslint-disable no-console */
import React  from 'react';
import GenericPopup from '../../components/popup/generic-popup/generic-popup.component';
import {addPopup} from '../../components/popup/popup.component';
import {navigateToLocation, ROUTES} from '../../app.router';

class WebsocketAPI {
	init (webSocketUrl, options) {
		this.options = options;
		this.pendingRequests = {};
		this.requestCounter = 0;
		options.url = webSocketUrl + options.key;
		this.connect(options.url);
		this.reconnectTimer = this.reconnectionTimerHandler()
	}

	// Timeout to handle the user losing connection and trying to auto reconnect
	// Cancel the interval when the websocket is opened again
	// Try and reconnect when it is closed
	reconnectionTimerHandler(){
		const reconnectConnection = this.reconnectConnection.bind(this)
		return {
			startReconnection: function () {
				this.timeoutID = setInterval(reconnectConnection, 3000); // Future Improvement is to make this exponentional with a counter
			},
			isReconnecting: function() {
				return typeof this.timeoutID === 'number'
			},
			cancel: function() {
				window.clearInterval(this.timeoutID);
				this.timeoutID = undefined; // remove the id so we can know when multiple different sucessful reconnections and disconects happen
			}
		};
	}

	close () {
		if (this.webSocket != null) {
			if (this.keepAliveTimerId) {
				clearInterval(this.keepAliveTimerId);
			}
			this.webSocket.onopen = null;
			this.webSocket.onclose = null;
			this.webSocket.onerror = null;
			this.webSocket.onmessage = null;
			this.webSocket.close();
			this.webSocket = null;
		}
	}

	connect (socketUrl) {
		try {
			this.webSocket = new WebSocket(socketUrl);
			this.webSocket.onopen = this.onWebSocketOpen.bind(this);
			this.webSocket.onclose = this.onWebSocketClose.bind(this);
			this.webSocket.onerror = this.onWebSocketError.bind(this);
			this.webSocket.onmessage = this.onWebSocketMessage.bind(this);
		} catch(e) {
			console.log('Error on new websocket connect: ' + e.message);
		}
	}

	reconnect (url) {
		this.close();
		this.connect(url);
	}

	onWebSocketOpen (/*event*/) {
		// console.log('WebSocket onOpen');
		this.reconnectTimer && this.reconnectTimer.isReconnecting() && this.reconnectTimer.cancel(); // clear any existing reconnection attempts
		this.startKeepAliveTimer();
	}

	onWebSocketClose (event) {
		// console.log('WebSocket onClose ---', this.webSocket.readyState,'---',event);
		if(this.webSocket.readyState === 3){
			if (this.reconnectTimer && !this.reconnectTimer.isReconnecting()) { // can fire multiple times so don't let it if already reconnecting
				this.reconnectTimer.startReconnection(); // Start trying to reconnect

				// AWS connection duration for WebSocket API is limited to 2 hours
				// it means that the connection is closed each 2 hours
				// no need to show reconnect popup if new connection is opened
				setTimeout(() => {
					if (this.isWebsocketOpen()) return;
					this.showReconnectPopup();
				}, 3000);
			}
		}
	}

	onWebSocketError (/*event*/) {
		if (this.reconnectTimer && !this.reconnectTimer.isReconnecting()) { // can fire multiple times so don't let it if already reconnecting
			this.reconnectTimer.startReconnection(); // Start trying to reconnect
			this.showReconnectPopup();  // don't show popup on IOS
		}
	}

	startKeepAliveTimer = () => {
		if(this.keepAliveTimerId)clearInterval(this.keepAliveTimerId);
		this.keepAliveTimerId = setInterval(()=>{
			if(this.webSocket.readyState  !== 0) {
				this.webSocket.send(JSON.stringify({action: 'message'}));
			}
		},
		(1000 * 60 * 9) // 9 minutes - because AWS ws timeout is 10 minutes
		);
	};

	onWebSocketMessage(message) {
		let result;
		try {
			result = JSON.parse(message.data);
		} catch (e) {
			return;
		}

		if (this.options && this.options.onMessage) {
			this.options.onMessage(result);
		}

		const resultType = result['@class'];
		if (resultType && resultType.match(/Response$/)) {
			if (result.requestId) {
				const requestId = result.requestId;
				if (this.pendingRequests[requestId]) {
					this.pendingRequests[requestId](result);
					delete this.pendingRequests[requestId];
				}
			}
		}
	}

	reconnectConnection() {
		if (this.options.url) {
			try {
				this.reconnect(this.options.url);
			}
			catch (e) {
				navigateToLocation(ROUTES.HOMEPAGE);
			}
		}
		else {
			navigateToLocation(ROUTES.HOMEPAGE);
		}
	}

	// Websocket is either opening or open
	isWebsocketOpen () {
		return this.webSocket.readyState === 0 || this.webSocket.readyState === 1
	}

	showReconnectPopup () {
		if(this.isPopupActive) return;
		this.isPopupActive = true;
		addPopup(<GenericPopup
			okButtonLabel="Got it!"
			title="There was a problem with your connection (ws)"
			message="Reconnecting..."
			onOkClicked={()=>{
				navigateToLocation(ROUTES.HOMEPAGE);
				this.isPopupActive = false;
			}}
		/>);
	};
}

export default WebsocketAPI;
