import React,{Component} from 'react';
import { Button } from '../../common/button/common-button.component';
import { closePopup } from '../popup.component';
import inputManager from '../../../assets/lib/inputmanager';
import './generic-popup.component.less';

const navId = 'popup-component';
const navLayer = 2;

export const TYPE_HAS_TWO_BUTTONS='hasTwoButtons';

class GenericPopup extends Component {
	componentDidMount() {
		inputManager.refreshNavMap(navId, navLayer);
		if(this.props.buttonType === TYPE_HAS_TWO_BUTTONS)inputManager.setCurrentElement(navLayer,0 ,1);
		else inputManager.setCurrentElement(navLayer,0 ,0);
		this.refs.containerRef.focus();
	}

	componentWillUnmount() {
		inputManager.removeRow(navId);
	}

	onOkButtonClicked = () => {
		if(this.props.onOkClicked)this.props.onOkClicked();
		if(!this.props.isPermanent)closePopup();
	};

	onCancelButtonClicked = () => {
		if(this.props.onCancelClicked)this.props.onCancelClicked();
		if(!this.props.isPermanent)closePopup();
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13){
			this.onOkButtonClicked();
		}else if(event.keyCode === 27){
			this.onCancelButtonClicked();
		}
	};

	render() {
		const {
			buttonType,
			message,
			title,
			cancelButtonLabel,
			okButtonLabel,
			okButtonClass = '',
		} = this.props;

		return (
			<div className="generic-popup-component" ref="containerRef" tabIndex="-1" onKeyDown={this.onKeyDown}>
				<div className="title-text">
					{title}
				</div>
				<div className="message-text">
					{message}
				</div>
				<div className="buttons-container">
					{buttonType===TYPE_HAS_TWO_BUTTONS?<Button
						className="secondary"
						onClick={this.onCancelButtonClicked}
						navProps={navId}
					>
						{cancelButtonLabel||'Cancel'}
					</Button>:null}
					<Button
						className={`secondary ${okButtonClass}`}
						onClick={this.onOkButtonClicked}
						navProps={navId}
					>
						{okButtonLabel||'Ok'}
					</Button>
				</div>
			</div>
		);
	}

};

export default GenericPopup;
