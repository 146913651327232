import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button} from '../common/button/common-button.component';
import {Loader} from '../common/loader/loader.component';
import './homepage.component.less';
import {navigateToLocation, ROUTES} from '../../app.router';
import {getIsLoggedIn, getIsUserSubscribed, getPaymentData} from '../../app.selectors';
import {addPopup} from '../popup/popup.component';
import GenericPopup, {TYPE_HAS_TWO_BUTTONS} from '../popup/generic-popup/generic-popup.component';
import {getDateStringWithEpoch} from '../../assets/lib/utils';
import { CancelSubscription, DeleteMyAccount } from '../login/login.actions';
import { readShowAccountDeleteBtn } from '../../assets/lib/local-storage';
import { PAYMENT_TYPES } from '../../constants';

class Homepage extends Component {
	constructor() {
		super();
		this.state = {
			showLoadingSpinner: false,
			showDeleteAccountBtn: false
		};
	}

	componentDidMount() {
		if (readShowAccountDeleteBtn() === 'true') {
			this.setState({ showDeleteAccountBtn: true });
		}
	}

	onUpdateProfileClicked = () => {
		navigateToLocation(ROUTES.UPDATE_PROFILE);
	};

	onSubscribeClicked = () => {
		if (this.props.paymentType === PAYMENT_TYPES.FORTUMO) {
			navigateToLocation(ROUTES.FORTUMO, {standalone: true});
		} else {
			navigateToLocation(ROUTES.PAYMENT_SELECTION);
		}
	};

	onUpdatePaymentClicked = () => {
		navigateToLocation(ROUTES.PAYMENT);
	};

	onDeleteAccountPending = () => {
		this.setState({showLoadingSpinner: true});
	};
	onDeleteAccountSuccess = () => {
		this.setState({showLoadingSpinner: false});
	};
	onDeleteAccountError = () => {
		this.setState({showLoadingSpinner: false});
	};

	deleteAccount = () => {
		this.onDeleteAccountPending();
		this.props.dispatch(DeleteMyAccount(
			this.onDeleteAccountSuccess,
			this.onDeleteAccountError
		));
	};

	onProceedAccountDeletingClicked = () => {
		addPopup(
			<GenericPopup
				okButtonClass='danger'
				title="Confirmation"
				message={<span>Are you sure to delete your account?</span>}
				buttonType={TYPE_HAS_TWO_BUTTONS}
				okButtonLabel="DELETE"
				cancelButtonLabel="Cancel"
				onOkClicked={this.deleteAccount}
			/>
		);
	};

	onDeleteAccountClicked = (ignoreIosSubscription) => {
		if (!ignoreIosSubscription
			&& this.props.isSubscribed
			&& this.props.paymentData?.paymentType === PAYMENT_TYPES.APPLE_IOS
		) {
			addPopup(
				<GenericPopup
					title="Warning"
					message={<span>You are still subscribed. It is recommended that you cancel your subscription first. Do you want to continue?</span>}
					buttonType={TYPE_HAS_TWO_BUTTONS}
					okButtonLabel="Continue"
					cancelButtonLabel="Cancel"
					onOkClicked={() => this.onDeleteAccountClicked(true)}
				/>
			);
			return;
		}

		addPopup(
			<GenericPopup
				okButtonClass='danger'
				title="Warning"
				message={<span>Warning! This operation cannot be undone. You will be logged out and your account will be deleted from the system.</span>}
				buttonType={TYPE_HAS_TWO_BUTTONS}
				okButtonLabel="Proceed"
				cancelButtonLabel="Cancel"
				onOkClicked={this.onProceedAccountDeletingClicked}
			/>
		);
	};

	onPromoCodeClicked = () => {
		navigateToLocation(ROUTES.PROMO_CODE);
	};

	onSubscriptionCancelClicked = () => {
		addPopup(
			<GenericPopup
				title="Warning"
				message={<span>You will lose all your progress data. Are you sure to cancel your subscription?</span>}
				buttonType={TYPE_HAS_TWO_BUTTONS}
				okButtonLabel="Yes"
				cancelButtonLabel="No"
				onOkClicked={()=>{this.props.dispatch(CancelSubscription());}}
			/>
		);
	};

	renderSubscriptionDetails = () => {
        const { isSubscribed, cardDetails, paymentDue, subscriptionStatus, removedSubscriptionEndDate, isPaymentDataPending } = this.props;//{last4: '4242', exp_month: 4, exp_year: 2024, brand: 'Visa'}

        if (subscriptionStatus==="past_due") {
            if (this.props.paymentType === PAYMENT_TYPES.STRIPE) {
                return (
                    <div className="subscription-details">
                        <h5>{`Payment declined. Please check the payment details.`}</h5>
                        <Button
                            className="secondary"
                            onClick={this.onUpdatePaymentClicked}
                        >
                            Update Payment Method
                        </Button>
                    </div>
                );
            }        

            if (this.props.paymentType === PAYMENT_TYPES.PAYPAL) {
				return (
					<div>
						<h5>Subscription currently suspended. Please check your PayPal account.</h5>
					</div>
				);
            }
        }

		if (isSubscribed) {
            let paymentDueString = <h5>{`Your subscription renews on ${getDateStringWithEpoch(paymentDue)}`}</h5>
            if (removedSubscriptionEndDate) {
                paymentDueString = <h5>{`Your subscription expires on ${getDateStringWithEpoch(removedSubscriptionEndDate)}`}</h5>
            }

			if (this.props.paymentData.paymentType===PAYMENT_TYPES.APPLE_IOS) {
				return (
					<div>
						<h5>You are currently subscribed with your Apple account.</h5>
					</div>
				);
			}

			if (this.props.paymentData.paymentType===PAYMENT_TYPES.AMAZON) {
				return (
					<div>
						<h5>You are currently subscribed with your Amazon account.</h5>
						{paymentDueString}
					</div>
				);
			} 
            
            if (this.props.paymentData.paymentType===PAYMENT_TYPES.MICROSOFT) {
				return (
					<div>
						<h5>You are currently subscribed with your Microsoft Store account.</h5>
						{paymentDueString}
					</div>
				);
			} 
            
            if (this.props.paymentData.paymentType===PAYMENT_TYPES.PAYPAL) {
				return (
					<div>
						<h5>You are currently subscribed with your PayPal account. If you want to cancel your subscription please visit the PayPal website.</h5>
					</div>
				);
			}  
            
            if(this.props.paymentData.paymentType===PAYMENT_TYPES.GOOGLE) {
				return (
					<div>
						<h5>You are currently subscribed with your Google Play Store account.</h5>
						{paymentDueString}
					</div>
				);
			} else if(this.props.paymentType===PAYMENT_TYPES.FORTUMO) {
				return (
					<div>
						<h5>You are currently subscribed with your mobile carrier.</h5>
						{paymentDueString}
					</div>
				);
			} else if(this.props.paymentType===PAYMENT_TYPES.USER_PLAN) {
				const paymentMethod = "Free Subscription";

				if(!paymentDue) {
					return (
						<div className="subscription-details">
							<h5>Payment Method: {paymentMethod}</h5>
							<h5>You have a lifetime subscription</h5>
						</div>
					);
				}

                return (
                    <div className="subscription-details">
                        <h5>Payment Method: {paymentMethod}</h5>
                        {paymentDueString}
                        <Button
                            className="secondary"
                            onClick={this.onSubscribeClicked}
                        >
                            Subscribe
                        </Button>
                    </div>
                );
			} else if(this.props.paymentType===PAYMENT_TYPES.STRIPE) {
                if (cardDetails) {
                    if (removedSubscriptionEndDate) {
                        return (
                            <div className="subscription-details">
                                <h5>{cardDetails? `Payment Method: ${cardDetails.brand} xx${cardDetails.last4} - Your subscription expires on ${getDateStringWithEpoch(removedSubscriptionEndDate)}`:'' }</h5>
                            </div>
                        );
                    }

					return(
						<div className="subscription-details">
							<h5>{cardDetails? `Payment Method: ${cardDetails.brand} xx${cardDetails.last4} - Your subscription renews on ${getDateStringWithEpoch(paymentDue)}`:'' }</h5>
							<Button
								className="secondary"
								onClick={this.onUpdatePaymentClicked}
							>
								Update Payment Method
							</Button>
							<Button
								className="secondary"
								onClick={this.onSubscriptionCancelClicked}
							>
								Cancel Your Subscription
							</Button>
						</div>
					);
				} 

                // AJK 24/02/22 - I have no idea why this is here? Can we be Stripe AND Docomo?
                if (this.props.paymentData && this.props.paymentData.paymentType && this.props.paymentData.paymentType===PAYMENT_TYPES.DOCOMO) { 
                    return;
                }

                return (
                    <div className="subscription-details">
                        <h5>{`Payment Method: Please update your card details - Your subscription expires on ${getDateStringWithEpoch(paymentDue)}`}</h5>
                        <Button
                            className="secondary"
                            onClick={this.onUpdatePaymentClicked}
                        >
                            Add Payment Method
                        </Button>
                    </div>
                );
			}
		} else if (isPaymentDataPending) {
			return (
				<div className="subscription-details">
					Loading...
				</div>
			);
		} else {
			return (
				<div className="subscription-details">
					<Button
						className="secondary"
						onClick={this.onSubscribeClicked}
					>
						Subscribe
					</Button>
				</div>
			);
		}
 	};

	shouldDisplayLoader = () => {
		return !this.props.isLoggedIn
			|| this.state.showLoadingSpinner;
	};

	render() {
		const isTelcoProvider = this.props.paymentData &&
			this.props.paymentData.paymentType &&
			(this.props.paymentData.paymentType === PAYMENT_TYPES.DOCOMO ||
				this.props.paymentData.paymentType === PAYMENT_TYPES.FORTUMO)

		if (this.shouldDisplayLoader()) {
			return (
				<div className="loader-games">
					<Loader loading={true}/>
				</div>
			);
		}

		return (
			<section className="homepage-component">
				<Button
					className="secondary"
					isLink
					targetUrl="https://www.antstream.com/download-retro-games"
				>
					Install Antstream
				</Button>
				<Button
					className="secondary"
					onClick={this.onUpdateProfileClicked}
				>
					Update Profile
				</Button>
				{!isTelcoProvider &&
					<Button
						className="secondary"
						onClick={this.onPromoCodeClicked}
					>
						Redeem Code
					</Button>
				}
				{this.state.showDeleteAccountBtn &&
					<Button
						className="danger"
						onClick={this.onDeleteAccountClicked}>
						Delete Account
					</Button>
				}
				<div className="line"/>
				{this.renderSubscriptionDetails()}
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	const isLoggedIn = getIsLoggedIn(state);
	const isSubscribed = getIsUserSubscribed(state);
	const paymentData = getPaymentData(state);
	let paymentDue = null;
	let paymentType = null;
	let subscriptionStatus = null;
	let planType = null;
	let cardDetails = null;
    let removedSubscriptionEndDate = null;
	if(paymentData) {
		paymentType = paymentData.paymentType;
		subscriptionStatus = paymentData.subscriptionStatus;
		planType = paymentData.planType;
		cardDetails = paymentData.card;
        removedSubscriptionEndDate = paymentData.removedSubscriptionEndDate
		if( paymentData.paymentDue) paymentDue = paymentData.paymentDue;
		else if (paymentData.removedSubscriptionEndDate) paymentDue = paymentData.removedSubscriptionEndDate;
	}

	return {
		isPaymentDataPending: state.app.isPaymentDataPending,
		isLoggedIn,
		paymentDue,
		isSubscribed,
		paymentData,
		subscriptionStatus,
		paymentType,
		planType,
		cardDetails,
        removedSubscriptionEndDate,
	};
};

Homepage = connect(mapStateToProps)(Homepage);
export default Homepage;
