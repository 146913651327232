import { combineReducers } from 'redux';
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import { loginReducer } from './components/login/login.reducer';
import {
	CHANGE_USER_DETAILS_SUCCESS,
	CLEAR_CURRENT_SKU_ACTION,
	FETCH_PAYMENT_PLANS_SUCCESS,
	SET_CUSTOM_PLANS,
	FETCH_POST_AUTHENTICATION_ACTION_SUCCESS,
	SET_CURRENT_SKU_ACTION,
	USER_FULLY_REGISTERED_SUCCESS,
	GET_FORTUMO_CONFIG_ACTION_SUCCESS,
	SET_PAYMENT_TYPE_ACTION,
	SET_USER_AVATAR_ACTION_FAILED,
	FETCH_POST_AUTHENTICATION_ACTION,
	SET_USER_AVATAR_ACTION_PENDING, FETCH_PAYMENT_PLANS_ACTION, FETCH_PAYMENT_PLANS_ERROR
} from './components/login/login.actions';
import { homepageReducer } from './components/homepage/homepage.reducer';
import entitiesReducer from './entities/entities.reducer';

import {
	APP_INIT_SUCCESS_ACTION, GET_IP_STACK_OBJECT_SUCCESS,
	GET_UPLOAD_URL_ACTION_SUCCESS,
	SAVE_ROUTE, SET_CONFIG, SET_DISPLAY_NAME_ACTION, SET_DISPLAY_NAME_ACTION_FAIL,
	SET_DISPLAY_NAME_ACTION_SUCCESS,
	SET_UPLOAD_ID_ACTION_SUCCESS
} from './app.actions';
import {
	LOGIN_USER_ACTION, LOGIN_USER_ACTION_SUCCESS,
	SET_USER_AVATAR_ACTION, FETCH_PAYMENT_DATA_ACTION_SUCCESS, LOGOUT_USER_ACTION
} from './components/login/login.actions';
import { matchRoute, ROUTES } from './app.router';
import { paramsToObject } from './app.helpers';
import {promoCodeReducer} from './components/promo-code/promo-code.reducer';
import { Api as GeneratedAPI } from './assets/lib/generatedApi';

export const antstreamAPIService = new GeneratedAPI();

const appInitState = {
	loading: false,
	fetchPaymentPlansPending: false,
	isDisplayNamePending: false,
	initialised: false,
	uploadUrl:null,
	loggedUser:null,
	paymentData:null,
	paymentPlans:null,
	subscriptionPlan:null,
	ipStackObject:null,
	fortumoConfig:null,
};

const appReducer = (state = appInitState, action) => {
	switch (action.type) {
	case APP_INIT_SUCCESS_ACTION:
		return { 
			...state,
			loading: false,
			initialised: true
		};
	case LOGIN_USER_ACTION:
		return { 
			...state,
			loggedUser: null
		};
	case LOGOUT_USER_ACTION:
		return {
			...state,
			loggedUser: null
		};
	case LOGIN_USER_ACTION_SUCCESS:
		const user = action.payload.user;
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				...user,
			}
		};
	case CHANGE_USER_DETAILS_SUCCESS:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				userName: action.userName,
			}
		};
	case FETCH_POST_AUTHENTICATION_ACTION: {
		return {
			...state,
			isPaymentDataPending: true
		};
	}
	case FETCH_POST_AUTHENTICATION_ACTION_SUCCESS:
		const { registrationCountry, registrationRegionId, freemiumV2User, paymentData, subscriptionPlan, savedGames, optInUndecided} = action.response;
		return {
			...state,
			registrationRegionId,
			registrationCountry,
			freemiumV2User,
			isPaymentDataPending: false,
			loggedUser: {
				...state.loggedUser,
				savedGames,
				optInUndecided,
			},
			subscriptionPlan,
			paymentData,
		};
	case FETCH_PAYMENT_DATA_ACTION_SUCCESS:
		return {
			...state,
			paymentData:action.payload
		};
	case FETCH_PAYMENT_PLANS_ACTION: {
		return {
			...state,
			fetchPaymentPlansPending: true
		};
	}
	case FETCH_PAYMENT_PLANS_ERROR: {
		return {
			...state,
			fetchPaymentPlansPending: false
		};
	}
	case FETCH_PAYMENT_PLANS_SUCCESS:
		return {
			...state,
			fetchPaymentPlansPending: false,
			paymentPlans: action.stripePlans,
			paypalPlans: action.paypalPlans,
			fortumoPlans: action.fortumoPlans,
		};
	case SET_CUSTOM_PLANS:
		return {
			...state,
			customPlans: action.payload
		}
	case SET_USER_AVATAR_ACTION_PENDING:
		return {
			...state,
			loading: true,
		};
	case SET_USER_AVATAR_ACTION:
		return {
			...state,
			loading: false,
			loggedUser: { ...state.loggedUser, profile_image_id: action.payload }
		};
	case SET_USER_AVATAR_ACTION_FAILED:
		return {
			...state,
			loading: false,
		};
	case SET_DISPLAY_NAME_ACTION:
		return {
			...state,
			isDisplayNamePending: true
		};
	case SET_DISPLAY_NAME_ACTION_SUCCESS:
		return {
			...state,
			isDisplayNamePending: false,
			loggedUser: {
				...state.loggedUser,
				displayName: action.payload.displayName
			}
		};
	case SET_DISPLAY_NAME_ACTION_FAIL:
		return {
			...state,
			isDisplayNamePending: false,
			loggedUser: {
				...state.loggedUser,
				displayName: state.loggedUser.displayName
			}
		};
	case SET_UPLOAD_ID_ACTION_SUCCESS:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				profile_image_id: action.payload
			}
		};
	case GET_UPLOAD_URL_ACTION_SUCCESS:
		return {
			...state,
			uploadUrl:action.payload
		};
	case GET_IP_STACK_OBJECT_SUCCESS:
		return {
			...state,
			ipStackObject:action.payload
		};
	case USER_FULLY_REGISTERED_SUCCESS:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				profile_image_id: action.payload.avatarType,
				displayName: action.payload.displayName,
				isNotFullyRegistered: false,
			}
		};
	case GET_FORTUMO_CONFIG_ACTION_SUCCESS:
		return {
			...state,
			fortumoConfig: action.config
		}
	case SET_PAYMENT_TYPE_ACTION:
		return {
			...state,
			paymentType: action.paymentType
		}
	default:
		return state;
	}
};

const routingInitialState = {
	search: {},
	hash: {},
	locationBeforeTransitions: {},
	currentRoute: null,
	sessionData: {},
};

function routerReducer(state = routingInitialState, { type, payload } = {}) {
	switch (type) {
		case LOCATION_CHANGE: {
			if (!payload) {
				return {...state};
			}

			const payload2 = payload.location;

			const currentRoute = matchRoute(payload2.pathname);
			const search = paramsToObject(payload2.search);
			const hash = paramsToObject(payload2.hash);
			const canGoBack = currentRoute !== ROUTES.HOMEPAGE;

			let sessionData = state.sessionData;
			if (currentRoute === ROUTES.SPLASH && search.sessionId && search.authToken) {
				sessionData = {
					sessionId: search.sessionId,
					authToken: search.authToken,
					sku: search.sku
				};
			}
	
			let origin = state.origin;
			let paymentMethod = state.paymentMethod;
			if ((currentRoute === ROUTES.CREATE_USER || currentRoute === ROUTES.FORTUMO) && search.origin && search.paymentMethod) {
				origin = search.origin;
				paymentMethod = search.paymentMethod;
			}
	
			return {
				...state,
				locationBeforeTransitions: payload2,
				currentRoute,
				search: {...search},
				hash: {...state.hash, ...hash},
				canGoBack,
				sessionData,
				origin,
				paymentMethod,
			};
		}
		case SET_CURRENT_SKU_ACTION:
			return {
				...state,
				sessionData: {
					sku: payload
				}
			};
		case CLEAR_CURRENT_SKU_ACTION:
			return {
				...state,
				sessionData: {
					...state.sessionData,
					sku: null
				}
			};
		case SAVE_ROUTE:
			return {
				...state,
				savedRoute: {...payload}
			};
		case LOGOUT_USER_ACTION:
			return {
				...state,
				search: {},
				hash: {},
				sessionData:null,
				origin: null,
			};
		default:
			return state;
	}
}

const configInitialState = {
	assetsUrl:'',
	folderTypes:null,
};

const configReducer = (state = configInitialState, action) => {
	switch (action.type) {
		case SET_CONFIG:
			return {
				...state,
				assetsUrl:action.config.assetsUrl,
				folderTypes:action.config.folderTypes,
			};
		default:
			return state;
	}
};

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	form: reduxFormReducer, 
	app: appReducer,
	entities: entitiesReducer,
	config: configReducer,
	routing: routerReducer,
	login: loginReducer,
	promoCode: promoCodeReducer,
	homepage: homepageReducer,
});

export default createRootReducer;
