import {FetchPaymentDataActionSuccess} from '../login/login.actions';
import {readRestUserData} from '../../assets/lib/local-storage';
import {antstreamAPIService} from '../../app.reducer';

export const APPLY_PROMO_CODE_ACTION = 'APPLY_PROMO_CODE_ACTION';
export const applyPromoCodeAction = (code, googleReCaptchaToken) => {
	return (dispatch) => {
		dispatch({ type: APPLY_PROMO_CODE_ACTION, code, googleReCaptchaToken });

			const { authToken, userId } = readRestUserData();
			const payload = {
				action: 'usePromoCode',
				code,
				googleReCaptchaToken,
				userId: userId
			};
			const params = {
				body: payload,
				headers: {'Authorization': "Bearer " + authToken}
			};
			antstreamAPIService.promoCode.postPromoCode(params).then(({data}) => {
				if (!data || !data.data) throw new Error((data && data.error) || 'Something went wrong');
				if (data.error) throw new Error(data.error);

				const response = data.data.response;
				dispatch(applyPromoCodeResponse(response));
			}).catch(catchErr => {
				const errMsg = 'Failed to apply promo code: ' + catchErr.message; 
				console.log(errMsg);
				dispatch({ type: APPLY_PROMO_CODE_FAIL, response: {message: errMsg} });
			});
	};
};

export const APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS';
export const APPLY_PROMO_CODE_FAIL = 'APPLY_PROMO_CODE_FAIL';
export const applyPromoCodeResponse = (response) => {
	return (dispatch) => {
		if(response && response.success) {
			dispatch({ type: APPLY_PROMO_CODE_SUCCESS, response });
			if(response.paymentData)dispatch(FetchPaymentDataActionSuccess(response.paymentData, true));//if user is subscribed update paymentData Object
		} else {
			dispatch({ type: APPLY_PROMO_CODE_FAIL, response });
		}
	};
};

export const CLEAR_PROMO_CODE_REDUCER = 'CLEAR_PROMO_CODE_REDUCER';
export const clearPromoReducer = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_PROMO_CODE_REDUCER});
	};
};