import React, { Component } from 'react';
import PopupAnimation from './popup-animation/popup-animation.component';
import inputManager from '../../assets/lib/inputmanager';
import AudioManager, {TYPE_NOTIFICATION} from '../../app.audio';
import './popup.component.less';
import { connect } from 'react-redux';
const navLayer=2;

let addPopup = null;
let closePopup = null;

class PopupComponent extends Component {
	constructor(props) {
		super(props);

		this.popupList = [];
		this.prevLayerIndex = -1;
		this.animationRef = null;

		this.state = {
			selectedPopup:null,
		};

		addPopup = this.addNewPopup;
		closePopup = this.closePopup;
	}

	componentDidMount() {
		const scriptTagId = 'stripe_script';

		// Loads up the Stripe API, needed for the payment dialog.
		if (!document.getElementById('stripe_script')) {
			const script = document.createElement('script');

			script.setAttribute('id', scriptTagId);
			script.setAttribute('src', 'https://js.stripe.com/v3/');
			script.setAttribute('async', true);

			document.body.appendChild(script);
		}
	}

	componentDidUpdate () {
		if(!this.state.selectedPopup && this.popupList.length>0)this.setSelectedPopup();
	}

	showPopupOverlay = () => {
		if(inputManager.layerIndex !== navLayer) {
			this.prevLayerIndex = inputManager.layerIndex;
			inputManager.setLayer(navLayer);
			document.getElementById('popup-overlay').classList.add('active');
			document.getElementById('app-main-container').classList.add('active');
		}
	};

	hidePopupOverlay = () => {
		inputManager.setLayer(this.prevLayerIndex);
		document.getElementById('popup-overlay').classList.remove('active');
		document.getElementById('app-main-container').classList.remove('active');
	};

	addNewPopup = (element,props={}) => {
		const listItem = {
			props,
			element,
		};
		this.popupList.push(listItem);
		this.forceUpdate();
	};

	setSelectedPopup = () => {
		if(!this.state.selectedPopup) {
			const nextPopup = this.popupList.shift();

			if (this.animationRef) this.animationRef.startShowAnimation();
			this.showPopupOverlay();
			AudioManager.playAudio(TYPE_NOTIFICATION);
			this.setState({
				selectedPopup: nextPopup,
				hiding: false,
			});
		}
	};

	closePopup = () => {
		if(this.animationRef)this.animationRef.startHideAnimation();
	};

	onHideAnimationEnd = () => {
		if(this.popupList.length <= 0) {
			this.hidePopupOverlay();
		}

		this.setState({
			selectedPopup:null
		});
	};

	onShowAnimationEnd = () => {
		// inputManager.refreshNavMap(navId, navLayer);
	};

	renderContent = () => {
		return (
			<div className="popup-component">
				{this.state.selectedPopup.element}
			</div>
		);
	};

	render() {
		return (
			<PopupAnimation
				onHideAnimationEnd={this.onHideAnimationEnd}
				onShowAnimationEnd={this.onShowAnimationEnd}
				ref = {element => this.animationRef = element}
			>
				{this.state.selectedPopup?this.renderContent():null}
			</PopupAnimation>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export {addPopup,closePopup};
export default connect(mapStateToProps)(PopupComponent);
