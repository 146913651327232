import React from 'react';
import logo from '../../assets/images/splash/antstream_logo_vector.svg';

import './thank-you.component.less';

export const ThankYouComponent = () => {
    return (
        <section className="thank-you-component">
            <div className="logo-container">
                <img src={logo} alt="logo" />
            </div>
            <div className="text-container">
                    <h1 className="title">Thank You For Registering!</h1>
                    <div className="content-text">
                        <p>
                            Your account is ready to rock, and we’ve sent you an email with a magic verification link to proceed.
                        </p>
                    </div>
                </div>
        </section>
    );
}


export default ThankYouComponent;
