import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./promo-code.component.less";
import {Field, reduxForm} from 'redux-form';
import {Input} from '../common/input/input.component';
import {
	maxLengthValidator,
	minLengthValidator,
	requiredValidator, spaceCharValidator,
	trimValidator
} from '../common/input/input.validator';
import {Button} from '../common/button/common-button.component';
import { navigateToLocation, navigateToSavedLocation, ROUTES} from '../../app.router';
import {addPopup} from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import {applyPromoCodeAction, clearPromoReducer} from './promo-code.actions';
import {getResponse, getWaitingResponse} from './promo-code.selector';
import ReCAPTCHA from "react-google-recaptcha";
import {getRoutingSearchIsFtui} from '../../app.selectors';

const maxValue30 = maxLengthValidator(30);
const minValue2 = minLengthValidator(2);

class PromoCodeComponent extends Component {
	saveForm = (values) => {
		if(!this.googleReCaptchaToken){
			addPopup(<GenericPopup
				message="Please verify that you are not a robot."
			/>);
			return;
		}

		const {code} = values;
		this.props.dispatch(applyPromoCodeAction(code, this.googleReCaptchaToken));
	};
	onPromoCodeResponse = () => {
		const {response} = this.props;

		if(response.success) {
			addPopup(<GenericPopup
				message={response.description}
				onOkClicked={()=>{
					if(this.props.isFtui){
						if(response.redirect==="PAYMENT") {
							navigateToLocation(ROUTES.PLANS);
						}else if(response.redirect==="HOMEPAGE") {
							navigateToLocation(ROUTES.ACCOUNT_CREATED);
						}
					}else{
						if(response.redirect==="PAYMENT") {
							navigateToLocation(ROUTES.PAYMENT_SELECTION);
						}else if(response.redirect==="HOMEPAGE") {
							navigateToLocation(ROUTES.HOMEPAGE);
						}
					}
				}}
			/>);
		}else{
			addPopup(<GenericPopup
				message={response.message}
			/>);

			if(this.refReCAPTCHA){
				this.refReCAPTCHA.reset();
				this.googleReCaptchaToken = null;
			}
		}
	};
	back = () => {
		navigateToSavedLocation();
	};
	onReCAPTCHAChange = (token) => {
		if(token){
			this.googleReCaptchaToken = token;
		}
	};

	constructor(props){
		super(props);

		this.props.dispatch(clearPromoReducer());
		this.googleReCaptchaToken = null;
		this.refReCAPTCHA = null;
	}

	componentDidUpdate(prevPros){
		if(prevPros.waitingResponse && !this.props.waitingResponse){
			this.onPromoCodeResponse();
		}
	}

	render () {
		return(
			<div className="promo-code-component">
				<form
					onSubmit={event => event.preventDefault()}
				>
					<div className="input-field-container">
						<Field
							name="code"
							component={Input}
							disabled={this.props.waitingResponse}
							placeholder="Enter code here"
							validate={[ minValue2, maxValue30, requiredValidator, trimValidator, spaceCharValidator]}
						/>
					</div>
					<p className="content-text" >
						Enter your unique promo code exactly as it is displayed.<br/>
						Then just press Next, and we’ll take it from there!
					</p>

					<ReCAPTCHA
						ref={element=>this.refReCAPTCHA=element}
						className="google-reCaptcha"
						sitekey="6LdEwNUUAAAAAL8uqAizjSy_PTMo1DSKk0wK9NDf"
						onChange={this.onReCAPTCHAChange}
						theme="dark"
					/>

					<div className="buttons-container">
						<Button
							className="secondary"
							onClick={this.back}
						>
							Back
						</Button>
						<Button
							type="submit"
							className="secondary"
							disabled={!this.props.valid || this.props.waitingResponse}
							onClick={this.props.handleSubmit(this.saveForm)}
						>
							Next
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		waitingResponse:getWaitingResponse(state),
		response:getResponse(state),
		isFtui:getRoutingSearchIsFtui(state),
	};
};

export default reduxForm({
	form: 'promoCodeForm'
})(connect(mapStateToProps)(PromoCodeComponent));