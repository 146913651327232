import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { navigateToLocation } from './app.router';
import {ListenApiWebsocket} from './app.actions';
import AudioManager from './app.audio';
import PopupComponent from './components/popup/popup.component';
import DialogBoxOverlay from './components/popup/popup-overlay.component';
import gamePad from './assets/lib/gamepad.lib';
import isDeviceIphoneX from './assets/lib/iphoneXDetector';
import './app.less';
import Header from './components/header/header.component';
import { store, history } from './configureStore';
import AllRoutes from './routes';
import { ConnectedRouter } from 'connected-react-router'

class App extends Component {
	constructor(props) {
		super(props);

		if(isDeviceIphoneX()) {
			const meta = document.createElement('meta');
			meta.name = 'viewport';
			meta.content = 'initial-scale=1, viewport-fit=cover';
			document.getElementsByTagName('HEAD')[0].appendChild(meta);
			document.body.classList.add('iPhoneX');
		}
	}

	componentDidMount() {
		const search = store.getState().router.location.search;
		const pathname = store.getState().router.location.pathname;
		navigateToLocation(pathname, {hash: window.location.hash}, search);

		store.dispatch(ListenApiWebsocket());
		
		this.unsubscribeGamepad = store.subscribe(() => gamePad.onStoreUpdate(store));
		this.unsubscribeAudioManager = store.subscribe(() => AudioManager.onStoreUpdate(store));
	}

	componentWillUnmount() {
		this.unsubscribeGamepad();
		this.unsubscribeAudioManager();
	}

	render() {
		return (
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<div className="main">
						<DialogBoxOverlay/>
						<PopupComponent />
						<div className="app-main-container" id="app-main-container">
							<Header/>
							<AllRoutes/>
						</div>
					</div>
				</ConnectedRouter>
			</Provider>
		);
	}
}

export default App;
