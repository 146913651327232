import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./avatar-selection.component.less";
import {getAvatars} from '../../login/login.selectors';
import AvatarComponent from '../../common/avatar/avatar.component';
import {
	SetAvatarFieldAction,
	FetchAvatarsBeforeLoginAction,
	UserFullyRegisteredRequestAction
} from '../../login/login.actions';
import {Button} from '../../common/button/common-button.component';
import leftImageTop from '../../../assets/images/ftui/footer-images/leaderboards_top.png';
import leftImageBottom from '../../../assets/images/ftui/footer-images/leaderboards_bot.png';
import FtuiFooterComponent from '../ftui-footer/ftui-footer.component';
import {navigateToLocation, ROUTES} from '../../../app.router';
import {SaveRoute} from '../../../app.actions';

class AvatarSelectionComponent extends Component {
	constructor() {
		super();
		this.state = {
			selectedItem: null,
		};

		this.isMouseDown = false;
		this.startMouseX = 0;
		this.lastMouseX = 0;
		this.avatarContainerRef = null;
	}

	componentDidMount() {
		this.refs.avatarSelectionRef.focus();
		this.props.dispatch(FetchAvatarsBeforeLoginAction(this.onFetchCompleted));
	}

	onAvatarClicked = (selectedId,event) => {
		if(Math.abs(event.clientX-this.startMouseX)<10){
			const selectedItem = this.props.avatarList.find(item=>item.type===selectedId);
			this.setState({selectedItem});
		}
	};

	onFetchCompleted = () => {
		if(this.props.avatarList && this.props.avatarList.length>0){
			this.setState({
				selectedItem:this.props.avatarList[0]
			});
		}
	};

	onBackClicked = () => {
		navigateToLocation(ROUTES.DISPLAY_NAME);
	};

	onNextClicked = () => {
		this.props.dispatch(SetAvatarFieldAction(this.state.selectedItem.type));
		this.props.dispatch(SaveRoute(ROUTES.PLANS));
		this.props.dispatch(UserFullyRegisteredRequestAction());
	};

	onMouseDown = (event) => {
		event.preventDefault();
		this.lastMouseX = event.clientX;
		this.startMouseX = event.clientX;
		this.isMouseDown = true;
	};

	onMouseMove = (event) => {
		event.preventDefault();
		if(this.isMouseDown) {
			this.setMouseDiff(event.clientX);
		}
	};

	onMouseUp = (event) => {
		event.preventDefault();
		this.setMouseDiff(event.clientX);
		this.isMouseDown = false;
	};

	onMouseLeave = (event) => {
		event.preventDefault();
		if(this.isMouseDown) {
			this.setMouseDiff(event.clientX);
		}

		this.isMouseDown = false;
	};

	setMouseDiff = (mouseX) => {
		const diff = this.lastMouseX - mouseX;

		if(this.avatarContainerRef) {
			this.avatarContainerRef.scrollLeft = this.avatarContainerRef.scrollLeft + diff;
		}

		this.lastMouseX = mouseX;
	};

	onKeyDown = (event) => {
		if(event.keyCode === 13){
			this.onNextClicked();
		}
	};

	render () {
		return(
			<div className="avatar-selection" ref="avatarSelectionRef" tabIndex="-1" onKeyDown={this.onKeyDown}>
				<div className="component-body">
					<br/>
					<div
						className="avatar-section"
						onMouseDown={this.onMouseDown}
						onMouseMove={this.onMouseMove}
						onMouseUp={this.onMouseUp}
						onMouseLeave={this.onMouseLeave}
					>
						<div className="selected-avatar" >
							{this.state.selectedItem && <AvatarComponent
								key={`avatar-selected`}
								item={this.state.selectedItem}
								selected
								circle
							/>}
						</div>
						<div
							className="avatar-container"
							ref={element=>this.avatarContainerRef=element}
						>
							<div className="avatar-inner-container">
								{this.props.avatarList.map((item,index) => {
									return (
										<AvatarComponent
											key={`avatar-${index}`}
											item={item}
											selected={this.state.selectedItem && this.state.selectedItem.type===item.type}
											circle
											onAvatarClicked={this.onAvatarClicked}
										/>
									);
								})}
							</div>
						</div>
					</div>
					<div className="buttons-container">
						<Button
							className="secondary"
							onClick={this.onBackClicked}
						>
							Back
						</Button>
						<Button
							className="secondary"
							onClick={this.onNextClicked}
						>
							Next
						</Button>
					</div>
				</div>
				<FtuiFooterComponent
					leftImageTop={leftImageTop}
					leftImageBottom={leftImageBottom}
					content="Take on the retro community and climb the global leaderboards!"
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const avatarList = getAvatars(state);
	return {
		avatarList
	};
};

export default connect(mapStateToProps)(AvatarSelectionComponent);