import React from 'react';
import { navigateToSavedLocation, navigateToLocation, ROUTES } from '../../app.router';
import {
    readRestUserData,
    writeRestUserData,
    writeUserData,
    clearAll,
    writeSignUpDisplayName,
    writeSignUpAvatar,
    readSignUpData,
    writeSignUpEmailAndPassword,
    writeCustomPlanData,
    writePlanTypeData, readCustomPlanData, writePromoCode, readPromoCode, clearPromoCode,
} from '../../assets/lib/local-storage';
import {AppInitActionSuccess, SaveRoute, setConfig} from '../../app.actions';
import {addPopup} from '../popup/popup.component';
import {resetPayNow} from '../stripe/CheckoutForm';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import Antstream from '../../assets/lib/Antstream';
import {getFacebookUserData} from '../../assets/lib/facebook.lib';
import {getLoggedUser, getPromoCodeDiscount, getSavedRoute, getSessionData} from '../../app.selectors';
import {FetchAvatarsSuccessAction} from '../../entities/avatars/avatars.actions';
import FreeTrialPopup from '../popup/free-trial-popup/free-trial-popup.component';
import {ATTRIBUTION_ORIGIN_TYPES} from "../../constants";
import {antstreamAPIService} from "../../app.reducer";
import {isFreeTierV2FeatureFlagEnabled} from "../../assets/lib/utils";


export const CHECK_EMAIL_ACTION = 'Check Email Action';
export const CheckEmailAction = (email, onResponse) => {
    return (dispatch) => {
        dispatch({ type: CHECK_EMAIL_ACTION });

            const payload = {
                operation: 'register',
                provider: 'USER',
                data: {
                    action: 'CheckEmail',
                    email: email,
                },
            };

            antstreamAPIService.register.registerCreate(payload).then(({data}) => {
                if (data.error) throw new Error(data.error);

                dispatch(CheckEmailSuccessAction(data));
                if(onResponse)onResponse(true);
            }).catch(registerErr => {
                dispatch(CheckEmailFailAction({error: registerErr.message}));
                if(onResponse)onResponse(false);
            });

    };
};

export const CHECK_EMAIL_ACTION_SUCCESS = 'Check Email Success';
const CheckEmailSuccessAction = (payload) => {
    return { type: CHECK_EMAIL_ACTION_SUCCESS, payload };
};

export const CHECK_EMAIL_ACTION_FAIL = 'Check Email Fail';
const CheckEmailFailAction = (payload) => {
    return { type: CHECK_EMAIL_ACTION_FAIL, payload };
};

export const CHECK_DISPLAY_NAME_ACTION = 'CHECK_DISPLAY_NAME_ACTION';
export const CheckDisplayNameAction = (displayName, onResponse) => {
    return (dispatch) => {
        dispatch({ type: CHECK_DISPLAY_NAME_ACTION });

            const payload = {
                operation: 'register',
                provider: 'USER',
                data: {
                    action: 'CheckUserName',
                    displayName,
                },
            };
            antstreamAPIService.register.registerCreate(payload).then(({data}) => {
                if (data.error) {
                    dispatch(CheckDisplayNameFailAction({error: data.error}));
                    if (onResponse) onResponse(false);
                } else {
                    dispatch(CheckDisplayNameSuccessAction({displayName: data.message}));
                    if (onResponse) onResponse(true);
                }
            }).catch(checkDisplayNameErr => {
                dispatch(CheckDisplayNameFailAction({error: checkDisplayNameErr.message}));
            });

    };
};

export const CHECK_DISPLAY_NAME_ACTION_SUCCESS = 'CHECK_DISPLAY_NAME_ACTION_SUCCESS';
const CheckDisplayNameSuccessAction = (payload) => {
    return { type: CHECK_DISPLAY_NAME_ACTION_SUCCESS, payload };
};

export const CHECK_DISPLAY_NAME_ACTION_FAIL = 'CHECK_DISPLAY_NAME_ACTION_FAIL';
const CheckDisplayNameFailAction = (payload) => {
    return { type: CHECK_DISPLAY_NAME_ACTION_FAIL, payload };
};

export const LOGIN_USER_ACTION = 'Login With Password Action';
export const LoginUserAction = ({email, password}) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_USER_ACTION });

            const payload = {
                provider: 'USER',
                operation: 'login',
                data: {
                    userName: email,
                    password
                }
            };
            antstreamAPIService.login.loginCreate(payload).then(async res => {
                const resp = res.data;
                if (resp.error) throw new Error(resp.error); 

                const {accessToken, userId} = resp.data;

                writeRestUserData({email, authToken: accessToken, userId});
                dispatch(GetAccountDetailsAction());
            }).catch(loginErr => {
                addPopup(<GenericPopup
                    okButtonLabel="Ok"
                    title="Login Message"
                    message={loginErr.message}
                />);

                dispatch(LoginUserFailAction(loginErr.message));
                clearAll();
                navigateToLocation(ROUTES.LOGIN_CREDENTIALS);
            });

    };
};

export const AuthUserAction = () => {
    return (dispatch) => {
        dispatch({ type: LOGIN_USER_ACTION });
        Antstream
            .getAuthResponse()
            .subscribe(
                (resp) => {
                    if (!resp.error) {
                        dispatch(LoginUserSuccessAction(resp));
                        fetchLoginDatas(dispatch);
                    } else {
                        dispatch(LoginUserFailAction(resp.error));
                        navigateToLocation(ROUTES.LOGIN_CREDENTIALS);
                    }

                    dispatch(AppInitActionSuccess());
                }
            );
    };
};

const fetchLoginDatas = (dispatch, isNotFullyRegistered) => {
    const promoCode = readPromoCode() || undefined;//if there is a promoCode inserted to the localStorage
    dispatch(fetchPostAuthenticationAction(promoCode, isNotFullyRegistered));
};


export const FETCH_POST_AUTHENTICATION_ACTION = 'FETCH_POST_AUTHENTICATION_ACTION';
export const fetchPostAuthenticationAction = (promoCode, isNotFullyRegistered, responseOnly, onResponse) => {
    return (dispatch,getState) => {
        dispatch({ type: FETCH_POST_AUTHENTICATION_ACTION });

            const { authToken } = readRestUserData();
            const body = {
                promoCode,
            };
            if (navigator && navigator.userAgent){
                body.userAgent = navigator.userAgent;
            }
            const params = {
                body: body,
                headers: { 'Authorization': "Bearer " + authToken }
            };
            antstreamAPIService.postLogin.postLoginCreate(params).then(({data}) => {
                if (!data || !data.data) throw new Error('Something went wrong');
                if (data.error) throw new Error(data.error);

                const resp = data.data;

                // if promocode was used - take paymentData from promoCodeResponse
                if (resp.promoCodeResponse && resp.promoCodeResponse.paymentData) {
                    resp.paymentData = resp.promoCodeResponse.paymentData;
                }

                dispatch(fetchPostAuthenticationActionSuccess(resp));

                if (responseOnly) {
                    if (onResponse) onResponse(data);
                    return;
                }

                const paymentData = resp.paymentData;
                const promoCodeResponse = resp.promoCodeResponse;
                const sessionData = getSessionData(getState());
                const userDetails = getLoggedUser(getState());
                const sku = sessionData ? sessionData.sku : null;

                if (!paymentData.subscription && !paymentData.token && paymentData.subscriptionStatus !== 'past_due' && sku) {
                    navigateToLocation(ROUTES.PAYMENT_SELECTION);
                } else {
                    if (promoCodeResponse) { // tried to use PromoCode. We can remove it from local storage.
                        clearPromoCode();
                        if (promoCodeResponse.success) {
                            if (promoCodeResponse.redirect === "PAYMENT") {
                                if (isNotFullyRegistered) {
                                    dispatch(SaveRoute(ROUTES.PLANS));
                                } else {
                                    navigateToLocation(ROUTES.PLANS);
                                }
                            } else if (promoCodeResponse.redirect === "HOMEPAGE") {
                                if (isNotFullyRegistered) {
                                    dispatch(SaveRoute(ROUTES.ACCOUNT_CREATED));
                                } else {
                                    navigateToLocation(ROUTES.ACCOUNT_CREATED);
                                }
                            }
                        } else {
                            navigateToSavedLocation();
                        }
                    } else {
                        if (userDetails.isNotFullyRegistered) {
                            const savedRoute = getSavedRoute(getState());
                            if (!savedRoute || !savedRoute.route || (savedRoute.route.path !== ROUTES.PLANS.path && savedRoute.route.path !== ROUTES.FORTUMO.path)) {
                                navigateToLocation(ROUTES.DISPLAY_NAME);
                            } else {
                                navigateToSavedLocation();
                            }
                        } else {
                            navigateToSavedLocation();
                        }
                    }
                }
            }).catch(catchErr => {
                const errMsg = 'Failed to fetch post login data: ' + catchErr.message;
                console.error(errMsg, catchErr);

                if (responseOnly) {
                    if (onResponse) onResponse(null);
                }
            });
    };
};


export const FETCH_POST_AUTHENTICATION_ACTION_SUCCESS = 'FETCH_POST_AUTHENTICATION_ACTION_SUCCESS';
export const fetchPostAuthenticationActionSuccess = (response) => {
    return (dispatch) => {
        dispatch({ type: FETCH_POST_AUTHENTICATION_ACTION_SUCCESS, response });
    };
};

export const LOGIN_USER_ACTION_SUCCESS = 'Login With Password Success';
const LoginUserSuccessAction = (payload) => {
    return (dispatch) => {
        dispatch(setConfig(payload.config));
        dispatch(FetchAvatarsSuccessAction(payload.avatars));
        dispatch({ type: LOGIN_USER_ACTION_SUCCESS, payload });
    };
};

export const LOGIN_USER_ACTION_FAIL = 'Login Fail';
export const LoginUserFailAction = (payload) => {
    return { type: LOGIN_USER_ACTION_FAIL, payload };
};

export const LOGOUT_USER_ACTION_PENDING = 'LogoutUserActionPending';
export const LogoutUserActionPending = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT_USER_ACTION_PENDING });
    };
};

export const LOGOUT_USER_ACTION = 'LogoutUserActionSuccess';
export const LogoutUserAction = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT_USER_ACTION });
    };
};


export const LOGIN_FACEBOOK_ACTION = 'Login Facebook Action';
export const LoginFacebookAction = (accessToken, isAutoLogin=false) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_FACEBOOK_ACTION, accessToken });
            const payload = {
                provider: 'FB',
                operation: 'login',
                data: {
                    fbAccessToken: accessToken
                }
            };
            antstreamAPIService.login.loginCreate(payload).then(async res => {
                if (!res || !res.data || res.data.error) {
                    dispatch(LoginUserFailAction(res.data.error));
                    clearAll();
                }
                const resp = res.data;

                writeRestUserData({ // for new backend
                    email: resp.data.player.userName,
                    authToken: resp.data.accessToken,
                    userId: resp.data.userId,
                });

                dispatch(GetAccountDetailsAction());
            });

    };
};

const getFacebookUserDataAction = (accessToken) => {
    return (dispatch,getState) => {
        getFacebookUserData(accessToken)
            .subscribe((resp) => {
                if (!resp.error) {
                    dispatch(getFacebookUserDataActionSuccess(resp));
                }
                const loggedUser = getState().app.loggedUser;
                if(resp && resp.email && loggedUser && loggedUser.userName && loggedUser.userName.substr(0,3) === "FB_"){
                    // dispatch(ChangeUserDetails(resp.email));
                }
            });
    };
};

export const CHANGE_USER_DETAILS = 'CHANGE_USER_DETAILS';
export const ChangeUserDetails = (userName) => {
    return (dispatch) => {
        dispatch({ type: CHANGE_USER_DETAILS });
        Antstream
            .changeUserDetails(userName)
            .subscribe((resp) => {
                if (!resp.error) {
                    dispatch(ChangeUserDetailsSuccess(userName));
                }
            });
    };
};

export const CHANGE_USER_DETAILS_SUCCESS = 'CHANGE_USER_DETAILS_SUCCESS';
export const ChangeUserDetailsSuccess = (userName) => {
    return (dispatch) => {
        dispatch({ type: CHANGE_USER_DETAILS_SUCCESS, userName});
    };
};

const getFacebookUserDataActionSuccess = ({id}) => {
    return(dispatch) => {
        if(id) {
            dispatch(SetUserAvatarAction(id));
        }
    };
};

export const SET_USER_AVATAR_ACTION_PENDING = 'Set User Avatar Action Pending';
export const SetUserAvatarActionPending = () => {
    return (dispatch) => {
        dispatch({ type: SET_USER_AVATAR_ACTION_PENDING });
    };
};
export const SET_USER_AVATAR_ACTION_FAILED = 'Set User Avatar Action Failed';
export const SetUserAvatarActionFailed = () => {
    return (dispatch) => {
        dispatch({ type: SET_USER_AVATAR_ACTION_FAILED });
    };
};

export const SET_USER_AVATAR_ACTION = 'Set User Avatar Action';
export const SetUserAvatarAction = (payload, onComplete = null) => {
    return (dispatch) => {
            dispatch(SetUserAvatarActionPending());

            const updAvaBody = {
                requestId: new Date().getTime() + "",
                data: {avatarUrl: payload}
            };
            const {authToken} = readRestUserData();
            antstreamAPIService.player.avatarUpdate(updAvaBody, {
                headers: {'Authorization': "Bearer " + authToken}
            }).then(({data}) => {
                if (data && data.result === "OK") {
                    if (onComplete) {
                        onComplete();
                    }
                    dispatch({ type: SET_USER_AVATAR_ACTION, payload });
                    return;
                }

                throw new Error((data && data.error) || "Failed to update avatar. Please try later.");
            }).catch(avatarUpdErr => {
                dispatch(SetUserAvatarActionFailed());
            });

    };
};

export const SIGNUP_USER_ACTION = 'Signup User Action';
export const SignupUserAction = () => {
    return (dispatch, getState) => {
        const payload = getState().login.userFields;

        const {attribution_origin, attribution_id, campaign} = readCustomPlanData() || {};
        const attributionData = {attribution_origin, attribution_id, campaign};
        const origin = attribution_origin && attribution_origin === ATTRIBUTION_ORIGIN_TYPES.ATARI ? attribution_origin : getState().routing.origin;

        const localStorageData = readSignUpData();//The reason of keeping this data is possible browser page refreshing.
        if(!payload.email)payload.email = localStorageData.email;
        if(!payload.password)payload.password = localStorageData.password;
        if(!payload.displayName)payload.displayName = localStorageData.displayName;

        const {email, password, displayName} = payload;
        const max = 99;
        const randomNumber = Math.floor(Math.random() * Math.floor(max));
        const generatedDisplayName = `${Date.now()}+${randomNumber}`;
        const finalDisplayName = displayName ? displayName : generatedDisplayName;
        dispatch({ type: SIGNUP_USER_ACTION, payload });

            const payloadBody = {
                provider: 'USER',
                operation: 'register',
                data: {
                    userName: email,
                    displayName: finalDisplayName,
                    password: password,
                    scriptData: {
                        action: 'SimpleRegistration',
                        origin,
                        attributionData,
                    }
                }
            };
            antstreamAPIService.register.registerCreate(payloadBody).then(async ({data}) => {
                if (data.error) throw new Error(data.error);

                const {accessToken, userId, gsUserId, gsAuthToken} = data.data;
                writeRestUserData({email, authToken: accessToken, userId});

                dispatch(SignupUserSuccessAction());

                dispatch(GetAccountDetailsAction());
            }).catch(registerErr => {
                dispatch(SignupFailAction({error: registerErr.message}));
            });

    };
};

export const SIGNUP_USER_ACTION_SUCCESS = 'Signup User Success';
const SignupUserSuccessAction = (payload) => {
    return (dispatch) => {
        dispatch({ type: SIGNUP_USER_ACTION_SUCCESS, payload });
    };
};

export const SIGNUP_USER_ACTION_FAIL = 'Signup User Fail';
const SignupFailAction = (payload) => {
    return (dispatch) => {
        dispatch({ type: SIGNUP_USER_ACTION_FAIL, payload });
    };
};


const handlePaymentIntentAction = (dispatch, clientSecret, data, response) => {
    const authData = response.authData;

    // Was the payment method totally rejected?
    if (authData.lastInvoice.payment_intent.status === "requires_payment_method") {
        console.log("[login.action|allowSub: Payment requires_payment_method");
        dispatch(CreditCardActionFailed({ error: "Card processing error. Please try again." }));
        resetPayNow();
        return;
    }

    // Otherwise, do whatever is required to confirm via Stripe.js
    window.ak_stripe.handleCardPayment(
        clientSecret, {}
    ).then(function (result) {
        console.log("[login.actions:allowSub:pi] Result:", result);
        if (result && result.error) {
            resetPayNow();
            dispatch(CreditCardActionFailed({ error: "Could not process this credit card: " + result.error.message }));
        } else {
            // subscription is now valid
            console.log("[login.actions:allowSub:pi] Response:", response);
            response.subscription = true;

                const { authToken } = readRestUserData();
                const payload = {
                    action: 'updateSCACardStatus',
                    paymentMethodId: data,
                };
                const params = {
                    body: payload,
                    headers: {'Authorization': "Bearer " + authToken}
                };
                antstreamAPIService.stripe.updateCardStatusCreate(params).then(({data}) => {
                    if (!data || data.result !== 'OK') throw new Error('Something went wrong');
                    if (data.error) throw new Error(data.error);
                }).catch(someErr => {
                    console.error('update stripe status failed: ', someErr);
                });

            dispatch(SaveRoute(ROUTES.HOMEPAGE));
            dispatch(FetchPaymentDataActionSuccess(response));
			dispatch(fetchPostAuthenticationAction());
        }
    }).catch(someErr => {
        addPopup(<GenericPopup
            okButtonLabel="Ok"
            title="Payment Failed"
            message={someErr.message}
        />);
        console.error(someErr);
    });
}

const handleSetupIntentAction = (dispatch, clientSecret, paymentMethod, data, response) => {
    window.ak_stripe.confirmCardSetup(
            clientSecret, {
            payment_method: paymentMethod
        }
    ).then(function (result) {
        console.log("[login.actions:allowSub:si] Result:", result);
        if (result && result.error) {
            resetPayNow();
            dispatch(CreditCardActionFailed({ error: "Credit card processing error. " + result.error.message }));
        } else {
            // subscription is now valid
            console.log("[login.actions:allowSub:si] Response:", response);
            response.subscription = true;

                const { authToken } = readRestUserData();
                const payload = {
                    action: 'updateSCACardStatus',
                    paymentMethodId: data,
                };
                const params = {
                    body: payload,
                    headers: {'Authorization': "Bearer " + authToken}
                };
                antstreamAPIService.stripe.updateCardStatusCreate(params).then(({data}) => {
                    if (!data || data.result !== 'OK') throw new Error('Something went wrong');
                    if (data.error) throw new Error(data.error);
                }).catch(someErr => {
                    console.error('update stripe status failed: ', someErr);
                });

            dispatch(FetchPaymentDataActionSuccess(response));
        }
    });
}


const handleCardRequiresAction = (dispatch, data, response) => {
    const authData = response.authData;
    const cardData = response.card;

    console.log("[login.actions:allowSub] Card requires further action", response);
    if (authData.lastInvoice && authData.lastInvoice.payment_intent) {
        console.log("[login.actions:allowSub] Need to deal with a payment_intent");
        var clientSecret = authData.lastInvoice.payment_intent.client_secret;
        handlePaymentIntentAction(dispatch, clientSecret, data, response);
    } 
    else if (authData.setupIntentId && cardData && cardData.id) {
        // Note: this method of SCA validation is usually only used when we 
        //       need to validate a card at the start of a Free Trial period.
        console.log("[login.actions:allowSub] Need to deal with a setup_intent");
        handleSetupIntentAction(dispatch, authData.setupIntentId, cardData.id, data, response);
    }
    else {
        console.log("[login.action|allowSub: no action type");
        dispatch(CreditCardActionFailed({ error: "Credit card processing error. Please try again." }));
        resetPayNow();
    }
}

const allowSubscription = (dispatch, data, sku) => {
    console.log("[login.actions:allowSub] Called");

    Antstream.continueCreateSubscription(sku)
        .subscribe(
            (response) => {
                console.log("[login.actions:allowSub]", response)
                const authData = response.authData;
                if (authData && authData.cardRequiresAction) {
                    handleCardRequiresAction(dispatch, data, response);
                } else {
                    // No further processing needed..
                    if (response.error) {
                        dispatch(CreditCardActionFailed({ error: "Credit card processing error. Error:" + response.error }));
                        resetPayNow();
                    } else {
                        dispatch(FetchPaymentDataActionSuccess(response));
                    }
                }
            },

            resp => {
                console.log('replace card SCA failed', resp);
                resetPayNow();
            }
        );
};

export const CreateCardWithSCATokenAction = (data, sku) => {
    return (dispatch) => {

            const { authToken } = readRestUserData();
            const body = {
                action: 'createCardWithSCAData',
                token: data, // paymentMethod.id
                sku,
            };
            const params = {
                body,
                headers: {'Authorization': "Bearer " + authToken}
            };
            antstreamAPIService.stripe.createCardCreate(params).then(({data}) => {
                if (!data || !data.data || data.error) {
                    throw new Error((data && data.error) || 'Something went wrong');
                }

                const response = data.data.paymentData;
                console.log("[login.actions:CCWSTA]", response);
                const authData = response.authData;

                // First thing to do is see if freetrial is available!
                if (authData && !authData.freeTrialAllowed) {
                    // this code should never be executed because freeTrialAllowed is always true

                    // No Free Trial!  Stop all further processing until reply from user
                    addPopup(<FreeTrialPopup
                        onOkClicked={() => {
                            console.log("[login.actions:CCWSATA] OK CLICKED");
                            allowSubscription(dispatch, data, sku);
                        }}
                        onCancelClicked={() => {
                            console.log("[login.actions:CCWSATA] CANCEL CLICKED");
                            resetPayNow();
                        }}
                    />);
                } else {
                    console.log("[login.actions:CCWSATA] FREE TRIAL Allowed.  Continue with purchase");
                    if (response.error) {
                        console.log("[login.actions:CCWSATA] Error");
                        dispatch(CreditCardActionFailed({error: "Credit card processing error. Error:" + response.error}));
                        resetPayNow();
                    } else {
                        // FreeTrial allowed, but card may require additional processing
                        if (authData && authData.cardRequiresAction) {
                            console.log("Card needs action!", authData);
                            handleCardRequiresAction(dispatch, data, response);
                        } else {
                            console.log("Card is OK? ActionSuccess!", response);
                            dispatch(FetchPaymentDataActionSuccess(response));
                        }
                    }
                }
            }).catch(someErr => {
                console.log('create card SCA failed', someErr);
                resetPayNow();
                addPopup(<GenericPopup
                    okButtonLabel="Ok"
                    title="Payment Failed"
                    message={someErr.message}
                />);
            });

    };
};

/*eslint no-console: 0 */
export const CancelSubscription = () => {
    return (dispatch) => {
            const { authToken } = readRestUserData();
            const payload = {
                body: { action: 'cancelCard' },
                headers: {'Authorization': "Bearer " + authToken}
            };
            antstreamAPIService.stripe.cancelSubscriptionCreate(payload).then(({data}) => {
                if (!data || !data.data || data.error) {
                    throw new Error((data && data.error) || 'Something went wrong');
                }
                const resp = data.data.paymentObject;

                dispatch(FetchPaymentDataActionSuccess(resp));
            }).catch(someErr => {
                console.log('CancelSubscriptionFailed', someErr);
            });

    };
};

export const REPLACE_CARD_ACTION = 'Replace Card Action';
export const ReplaceCardAction = (token) => {
    return (dispatch) => {
        dispatch({ type: REPLACE_CARD_ACTION, token });

            const {authToken} = readRestUserData();
            const body = {
                action: 'replaceCard',
                paymentMethodId: token,
            };
            const params = {
                body,
                headers: {'Authorization': "Bearer " + authToken}
            };
            antstreamAPIService.stripe.replaceCardCreate(params).then(({data}) => {
                if (!data || !data.data || data.error) {
                    throw new Error((data && data.error) || 'Something went wrong');
                }

                const response = data.data.paymentData;
                dispatch(FetchPaymentDataActionSuccess(response));
            }).catch(someErr => {
                console.log('replace card failed', someErr);
            });

    };
};

export const REPLACE_CARD_SCA_ACTION = 'Replace Card SCA Action';
export const ReplaceCardSCAAction = (data) => {
    return (dispatch) => {
        dispatch({ type: REPLACE_CARD_SCA_ACTION, data });

            const {authToken} = readRestUserData();
            const body = {
                action: 'replaceCard',
                paymentMethodId: data,
            };
            const params = {
                body,
                headers: {'Authorization': "Bearer " + authToken}
            };
            antstreamAPIService.stripe.replaceCardCreate(params).then(({data}) => {
                if (!data || !data.data || data.error) {
                    throw new Error((data && data.error) || 'Something went wrong');
                }

                const response = data.data.paymentData;
                if (response.authData && response.authData.cardRequiresAction) {
                    handleCardRequiresAction(dispatch, data, response);  // From observation, nothing so useful is returned. Instead an odd invoice comes back.
                } else {
                    // No further processing required... ie: Card is replaced
                    dispatch(FetchPaymentDataActionSuccess(response));
                }
            }).catch(someErr => {
                console.log('replace card SCA failed', someErr);
                resetPayNow();
            });

    };
};

// Password Reset
export const REQUEST_PASSWORD_RESET_ACTION = 'Request Password Reset Action';
export const RequestPasswordResetAction = ( email ) => {
    return (dispatch) => {
        dispatch({ type: REQUEST_PASSWORD_RESET_ACTION });

            antstreamAPIService.passwordRequest.passwordRequestCreate({
                body: {data: {email: email}}
            }).then(({data}) => {
                if (data && data.data && data.data.passwordRecoveryTokenSent) {
                    dispatch(RequestPasswordResetActionSuccess());
                    navigateToLocation(ROUTES.RESET_PASSWORD);
                } else {
                    dispatch(RequestPasswordResetActionFail({error: (data && data.error) || 'Something went wrong'}));
                }
            }).catch(passwordRequestErr => {
                dispatch(RequestPasswordResetActionFail({error: passwordRequestErr.message}));
            });

    };
};

export const REQUEST_PASSWORD_RESET_ACTION_SUCCESS = 'Request Password Reset Success';
const RequestPasswordResetActionSuccess = () => {
    return (dispatch) => {
        dispatch({type: REQUEST_PASSWORD_RESET_ACTION_SUCCESS});
    };
};


export const REQUEST_PASSWORD_RESET_ACTION_FAIL = 'Request Password Reset Fail';
const RequestPasswordResetActionFail = (payload) => {
    return(dispatch) => {
        dispatch({type: REQUEST_PASSWORD_RESET_ACTION_FAIL, payload});
        addPopup(<GenericPopup
            okButtonLabel="Got it!"
            title="Something went wrong"
            message="That account does not exist. Please check your email and try again."
        />);
    };
};


export const RESET_PASSWORD_ACTION = 'Reset Password Action';
export const ResetPasswordAction = ( payload, onSuccess) => {
    return (dispatch) => {
        dispatch({ type: RESET_PASSWORD_ACTION });
        const {token, email, password} = payload;

            antstreamAPIService.resetPassword.resetPasswordCreate({
                data: {email, token, newPassword: password}
            }).then(({ data }) => {
                if (data && data.data && data.data.passwordReset) {
                    if (onSuccess) onSuccess();
                    dispatch(ResetPasswordActionSuccess());
                    dispatch(LoginUserAction({email, password}));
                } else {
                    throw new Error((data && data.error) || 'Something went wrong');
                }
            }).catch(passwordResetErr => {
                dispatch(ResetPasswordActionFail({error: passwordResetErr.message}));
                addPopup(<GenericPopup
                    okButtonLabel="Got it!"
                    title="Reset Password"
                    message={passwordResetErr.message}
                />);
            });

    };
};

export const RESET_PASSWORD_ACTION_FAIL = 'Reset Password Fail';
const ResetPasswordActionFail = (payload) => {
    return(dispatch) => {
        dispatch({type: RESET_PASSWORD_ACTION_FAIL, payload});
    };
};

export const RESET_PASSWORD_ACTION_SUCCESS = 'Reset Password Success';
const ResetPasswordActionSuccess = () => {
    return(dispatch) => {
        dispatch({type: RESET_PASSWORD_ACTION_SUCCESS});
    };
};

export const FETCH_PAYMENT_DATA_ACTION_SUCCESS = 'FETCH_PAYMENT_DATA_ACTION_SUCCESS';
export const FetchPaymentDataActionSuccess = (payload, notRediect) => {
    return (dispatch)=>{
        //console.log("[login.actions:FetchPaymentDataActionSuccess] payload:", payload);
        dispatch({type: FETCH_PAYMENT_DATA_ACTION_SUCCESS, payload });
        if(!notRediect)navigateToSavedLocation();
        // if(gamePad.platformType===PLATFORM_TYPE_SAFARI)window.location.href = 'antstream://';
    };
};

export const CREDIT_CARD_ACTION_FAILED = 'Credit Card Action Failed';
const CreditCardActionFailed = (payload) => {
    return(dispatch) => {
        dispatch({type: CREDIT_CARD_ACTION_FAILED, payload});
        const popupMsg = (payload && payload.error) ? payload.error : "Payment Failed.";
        addPopup(
            <GenericPopup
                title="Warning"
                message={popupMsg}
            />
        );
    };
};

export const FETCH_PAYMENT_PLANS_SUCCESS = 'FETCH_PAYMENT_PLANS_SUCCESS';
export const FetchPaymentPlansSuccess = ({stripePlans, paypalPlans, fortumoPlans}) => {
    return (dispatch,getState)=>{
        const promoCodeDiscount = getPromoCodeDiscount(getState());
        if(promoCodeDiscount){
            stripePlans.forEach(plan => {
                const percentage = parseFloat(100 - promoCodeDiscount) / 100;
                plan.price = plan.price * percentage;
            });
        }

        if (isFreeTierV2FeatureFlagEnabled(getState())) {
            stripePlans = stripePlans.filter(plan => plan.term === "monthly_V2.2" || plan.term === "annual");
        } else {
            stripePlans = stripePlans.filter(plan => plan.term !== "monthly_V2" && plan.term !== "monthly_V2.2");
        }

        dispatch({type: FETCH_PAYMENT_PLANS_SUCCESS, stripePlans, paypalPlans, fortumoPlans });
    };
};

export const FETCH_PAYMENT_PLANS_ACTION = 'FETCH_PAYMENT_PLANS_ACTION';
export const FETCH_PAYMENT_PLANS_ERROR = 'FETCH_PAYMENT_PLANS_ERROR';
export const FetchPaymentPlans = (paymentTypes, onResponse) => {
    return (dispatch) => {
        dispatch({ type: FETCH_PAYMENT_PLANS_ACTION });

            const { authToken } = readRestUserData();
            const query = {
                action: 'getPlans',
                paymentTypes: paymentTypes.join(',')
            };
            const params = {
                headers: {'Authorization': "Bearer " + authToken}
            };
            antstreamAPIService.payment.plansList(query, params).then(({ data }) => {
                if (!data || !data.data || data.error) {
                    throw new Error((data && data.error) || 'Something went wrong');
                }
                dispatch(FetchPaymentPlansSuccess(data.data));
                if (onResponse) setTimeout(() => onResponse());
            }).catch(someErr => {
                dispatch({ type: FETCH_PAYMENT_PLANS_ERROR });
                console.error('Failed to fetch payment plans: ', someErr.message);
                // dispatch(FetchPaymentPlansSuccess(null));
                if (onResponse) onResponse('error');
            });

    };
};

export const SET_CUSTOM_PLANS = 'SET_CUSTOM_PLANS';
export const SetCustomPlans = (payload) => {
    writeCustomPlanData(payload);
    var decodedPlanData = readCustomPlanData();
    if(decodedPlanData && decodedPlanData.promoCode){
        writePromoCode(decodedPlanData.promoCode);
    }
};

export const SET_PLAN_TYPE = 'SET_PLAN_TYPE';
export const SetPlanType = (payload) => {
    writePlanTypeData(payload);
};

export const SET_CURRENT_SKU_ACTION = 'SET_CURRENT_SKU_ACTION';
export const SetCurrentSku = (payload) => {
    return (dispatch)=>{
        dispatch({type: SET_CURRENT_SKU_ACTION, payload });
    };
};

export const CLEAR_CURRENT_SKU_ACTION = 'CLEAR_CURRENT_SKU_ACTION';
export const ClearCurrentSku = () => {
    return (dispatch)=>{
        dispatch({type: CLEAR_CURRENT_SKU_ACTION});
    };
};

export const SET_EMAIL_PASSWORD_FIELDS_ACTION = 'SET_EMAIL_PASSWORD_FIELDS_ACTION';
export const SetEmailPasswordFieldsAction = (email, password) => {
    writeSignUpEmailAndPassword(email,password);
    return { type: SET_EMAIL_PASSWORD_FIELDS_ACTION, payload:{email,password} };
};


export const SET_DISPLAY_NAME_FIELD_ACTION = 'SET_DISPLAY_NAME_FIELD_ACTION';
export const SetDisplayNameFieldAction = (displayName) => {
    writeSignUpDisplayName(displayName);
    return { type: SET_DISPLAY_NAME_FIELD_ACTION, payload:{displayName} };
};


export const SET_AVATAR_FIELD_ACTION = 'SET_AVATAR_FIELD_ACTION';
export const SetAvatarFieldAction = (avatar) => {
    writeSignUpAvatar(avatar);
    return { type: SET_AVATAR_FIELD_ACTION, payload:{avatar} };
};


export const FETCH_AVATARS_BEFORE_LOGIN_ACTION = 'FETCH_AVATARS_BEFORE_LOGIN_ACTION';
export const FetchAvatarsBeforeLoginAction = (onComplete) => {
    return (dispatch) => {
        dispatch({ type: FETCH_AVATARS_BEFORE_LOGIN_ACTION });

            const {authToken} = readRestUserData();
            const params = {headers: {'Authorization': "Bearer " + authToken}};
            antstreamAPIService.avatars.avatarsList(params).then(({data}) => {
                dispatch(setConfig(data.data.message.config));
                dispatch(FetchAvatarsSuccessAction(data.data.message.avatarImages));
                if(onComplete) onComplete();
            }).catch(someErr => {
                console.log('Failed to get avatars list: ', someErr.message);
            });

    };
};

export const USER_FULLY_REGISTERED_REQUEST = 'USER FULLY REGISTERED REQUEST';
export const UserFullyRegisteredRequestAction = () => {
    return (dispatch, getState) => {
        const userId = getState().app.loggedUser._id;
        const userFields = getState().login.userFields;
        dispatch({ type: USER_FULLY_REGISTERED_REQUEST, userFields});

            const {authToken} = readRestUserData();
            const finishRegBody = {
                requestId: new Date().getTime() + "",
                data: {
                    action: 'FinishRegistration',
                    avatarUrl: userFields.avatar,
                    displayName: userFields.displayName,
                }
            };
            const finishRegOpts = {headers: {'Authorization': "Bearer " + authToken}};
            antstreamAPIService.player.avatarUpdate(finishRegBody, finishRegOpts).then(({data}) => {
                if (data.result === "OK") {
                    dispatch(UserFullyRegisteredSuccessAction({
                        avatarType: userFields.avatar,
                        displayName: userFields.displayName,
                    }));

                    const savedRoute = getSavedRoute(getState());
                    if (savedRoute) {
                        navigateToSavedLocation();
                    } else {
                        navigateToLocation(ROUTES.HOMEPAGE);
                    }
                } else {
                    dispatch(UserFullyRegisteredFailAction(data));
                }
            }).catch(finishRegErr => {
                dispatch(UserFullyRegisteredFailAction(finishRegErr));
            });

    };
};


export const USER_FULLY_REGISTERED_SUCCESS = 'USER FULLY REGISTERED SUCCESS';
const UserFullyRegisteredSuccessAction = (payload) => {
    return (dispatch) => {
        dispatch({ type: USER_FULLY_REGISTERED_SUCCESS, payload });
    };
};

export const USER_FULLY_REGISTERED_FAIL = 'USER FULLY REGISTERED FAILED';
const UserFullyRegisteredFailAction = (payload) => {
    return (dispatch) => {
        dispatch({ type: USER_FULLY_REGISTERED_FAIL, payload });
    };
};

const ON_PAYPAL_CHECKOUT_RESPONSE = 'ON_PAYPAL_CHECKOUT_RESPONSE';
export const onPaypalCheckoutResponse = (response) => {
    return (dispatch) => {
        dispatch({ type: ON_PAYPAL_CHECKOUT_RESPONSE, response });

            const { authToken } = readRestUserData();
            const payload = {
                action: 'onPaypalCheckoutResponse',
                response: response
            };
            const params = {
                body: payload,
                headers: {'Authorization': "Bearer " + authToken}
            };
            antstreamAPIService.payment.paypalCheckoutCreate(params).then(({ data }) => {
                if (!data || !data.data || data.error) {
                    throw new Error((data && data.error) || 'Something went wrong');
                }

                dispatch(onPaypalCheckoutResponseSuccess(data.data.response));
            }).catch(someErr => {
                console.error('Failed paypal checkout request: ' + someErr.message);
            });

    };
};

const ON_PAYPAL_CHECKOUT_RESPONSE_SUCCESS = 'ON_PAYPAL_CHECKOUT_RESPONSE_SUCCESS';
const onPaypalCheckoutResponseSuccess = (paymentData) => {
    return (dispatch) => {
        dispatch({ type: ON_PAYPAL_CHECKOUT_RESPONSE_SUCCESS, paymentData });
        dispatch(FetchPaymentDataActionSuccess(paymentData,true));
    };
};

export const GET_FORTUMO_CONFIG_ACTION = 'GET_FORTUMO_CONFIG_ACTION';
export const GetFortumoConfigAction = (origin, onComplete) => {
    return (dispatch) => {
        dispatch({ type: GET_FORTUMO_CONFIG_ACTION });

            const { authToken } = readRestUserData();
            const payload = {
                action: 'getConfig',
                origin: origin
            };
            const params = {
                body: payload,
                headers: {'Authorization': "Bearer " + authToken}
            };
            antstreamAPIService.fortumo.configCreate(params).then(({ data }) => {
                if (!data || !data.data || data.error) {
                    throw new Error((data && data.error) || 'Something went wrong');
                }

                dispatch(getFortumoConfigActionSuccess(data.data));
                if (onComplete) onComplete();
            }).catch(someErr => {
                console.error('Failed to get fortumo config: ' + someErr.message);
            });

    };
};

export const GET_FORTUMO_CONFIG_ACTION_SUCCESS = 'GET_FORTUMO_CONFIG_ACTION_SUCCESS';
export const getFortumoConfigActionSuccess = ({config}) => {
    return (dispatch) => {
        dispatch({ type: GET_FORTUMO_CONFIG_ACTION_SUCCESS, config});
    };
};

export const SET_PAYMENT_TYPE_ACTION = 'SET_PAYMENT_TYPE_ACTION';
export const setPaymentType = ({paymentType}) => {
    return (dispatch) => {
        dispatch({ type: SET_PAYMENT_TYPE_ACTION, paymentType});
    };
};

const GET_FORTUMO_PAYMENT_OBJECT = 'GET_FORTUMO_PAYMENT_OBJECT';
export const getFortumoPaymentObject = (onComplete) => {
    return (dispatch) => {
        dispatch({ type: GET_FORTUMO_PAYMENT_OBJECT});

            const { authToken } = readRestUserData();
            const payload = { action: 'getPaymentObject' };
            const params = {
                body: payload,
                headers: {'Authorization': "Bearer " + authToken}
            };
            antstreamAPIService.payment.checkStatusCreate(params).then(({ data }) => {
                if (!data || !data.data || data.error) {
                    throw new Error((data && data.error) || 'Something went wrong');
                }

                dispatch(getFortumoPaymentObjectSuccess(data.data.paymentObject));
                if (onComplete) onComplete();
            }).catch(someErr => {
                console.error('Failed to get fortumo payment object: ' + someErr.message);
            });

    };
};

const GET_FORTUMO_PAYMENT_OBJECT_SUCCESS = 'GET_FORTUMO_PAYMENT_OBJECT_SUCCESS';
const getFortumoPaymentObjectSuccess = (paymentData) => {
    return (dispatch) => {
        dispatch({ type: GET_FORTUMO_PAYMENT_OBJECT_SUCCESS, paymentData });
        dispatch(FetchPaymentDataActionSuccess(paymentData,true));
    };
};


export const GetAccountDetailsAction = (
    promoCode = null,
    isAutoLogin = false,
    isSignUp = false
) => {
    return (dispatch) => {
        const {authToken, email, userId} = readRestUserData();
        antstreamAPIService.accountDetails.accountDetailsList({
            headers: {'Authorization': "Bearer " + authToken}
        }).then(res => {
            const resp = res.data;
            if (resp.error) throw new Error(resp.error);

            if (!email || !userId) {
                writeRestUserData({email: resp.data.scriptData.user.userName, authToken: authToken, userId: resp.data.userId});
            }

            dispatch(LoginUserSuccessAction(resp.data.scriptData));
            Antstream.socketConnect();

            fetchLoginDatas(dispatch, resp.data.scriptData.isNotFullyRegistered); // post login with promoCode if exists

            // from fetchPostAuthenticationAction
            if(resp.data.scriptData.isNotFullyRegistered) {
                navigateToLocation(ROUTES.DISPLAY_NAME);
            }else{
                navigateToSavedLocation();
            }
        }).catch(() => {
            clearAll();
            return navigateToLocation(ROUTES.LOGIN_CREDENTIALS);
        });
    }
}

export const sendLogOutRequest = (onResponse) => {
    const { authToken } = readRestUserData();
    antstreamAPIService.logout.logoutCreate({}, {
        headers: {'Authorization': "Bearer " + authToken}
    }).then(({ data }) => {
        if (!data || !data.data || !data.data.result) {
            throw new Error((data && data.error) || "Something went wrong.");
        }

        Antstream.socketDisconnect();

        if (onResponse) { onResponse(); }

    }).catch(logoutErr => {
        addPopup(<GenericPopup
            okButtonLabel="Got it!"
            title="Something went wrong"
            message={logoutErr.message}
        />);

        if (onResponse) { onResponse(); }
    });
};

export const DELETE_MY_ACCOUNT_ACTION = 'DELETE_MY_ACCOUNT_ACTION';
export const DELETE_MY_ACCOUNT_ACTION_SUCCESS = 'DELETE_MY_ACCOUNT_ACTION_SUCCESS';
export const DELETE_MY_ACCOUNT_ACTION_ERROR = 'DELETE_MY_ACCOUNT_ACTION_ERROR';
export const DeleteMyAccount = (onResponse, onErrorResponse) => {
    return (dispatch) => {
        dispatch({ type: DELETE_MY_ACCOUNT_ACTION });

        const { authToken } = readRestUserData();
        const params = {
            headers: {'Authorization': "Bearer " + authToken}
        };
        const defaultErrorMsg = 'Failed to delete account. Please try later.';
        antstreamAPIService.player.deleteAccountDelete(params).then((res) => {
            if (res?.data?.statusCode !== 200) {
                throw new Error(res?.data?.error || defaultErrorMsg);
            }
            if (onResponse) setTimeout(() => onResponse());
            dispatch({ type: DELETE_MY_ACCOUNT_ACTION_SUCCESS });
        }).catch(deleteAccountDeleteErr => {
            dispatch({ type: DELETE_MY_ACCOUNT_ACTION_ERROR });
            console.error('Failed to delete account: ', deleteAccountDeleteErr.message);
            if (onResponse) onErrorResponse(deleteAccountDeleteErr.message);

            addPopup(<GenericPopup
                okButtonLabel="Ok"
                title="Account delete error"
                message={deleteAccountDeleteErr.message || defaultErrorMsg}
            />);
        });
    };
};
