import { createSelector } from 'reselect';
import {getAllAvatarsById, getAllAvatarsIds} from '../../entities/entities.selectors';

export const getLoading = state => state.login.loading;
export const getError = state => state.login.error;

export const getAvatars = createSelector (
	getAllAvatarsById,
	getAllAvatarsIds,
	(byId, ids) => {
		if (!byId || !ids) return [];
		return ids.map(id => byId[id]);
	}
);
