import React from 'react';
import "./ftui-footer.component.less";

const FtuiFooterComponent = ({leftImageTop,leftImageBottom,content}) => (
	<div className="ftui-footer">
		<div className="inner-container">
			<div className="text-container">
				{content}
			</div>
			<div className="left-image-container">
				<img className="left-image-bottom" alt="ftui-background-bottom" src={leftImageBottom}/>
				<img className="left-image-top" alt="ftui-background-top" src={leftImageTop}/>
			</div>
		</div>
	</div>
);

export default FtuiFooterComponent;