import React, { Component } from 'react';
import {Button} from '../../common/button/common-button.component';
import {Checkbox} from '../../common/checkbox/checkbox.component';
import { navigateToLocation, ROUTES} from '../../../app.router';
import logo from '../../../assets/images/splash/antstream_logo_vector.svg';

import './docomo-acknowledge.component.less';

export default class DocomoAcknowledge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTermsAndConditionsAgreed: false,
        }
    }

    render()  {
        const { location } = this.props
        const {ddSubscriptionId, merchantSubscriptionId } = location && location.query
        
        const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        return (
            <section className="docomo-acknowledge-component">
                <div className="logo-container">
                    <img src={logo} alt="logo" />
                </div>
                <div className="page-content">
                    <div className="benefits">
                        <p> Enjoy the following benefits:</p>
                        <ul>
                            <li>Instant access to over 1000 retro games. No download or installation needed.</li>
                            <li>Play across multiple devices (mobile phone, tablets and PC) with just 1 account.</li>
                            <li>Next-level gaming with zero lag on Singapore's fastest network.</li>
                        </ul>
                    </div>
                    <div className="terms-conditions">
                        See <a href="https://www.starhub.com/mobiletnc"> full offer terms.</a> By clicking "Sign up now", you agree to 
                        <a href="https://www.antstream.com/terms-of-use"> Antstream Terms and Conditions</a> and <a href="https://www.antstream.com/privacy"> Antstream Privacy Policy.</a> 
                    </div>
                    {isiOS &&
                        <> 
                            <div className="warning-prompt">
                                <p className="warning-prompt-text">Note: We detected that you are signing up via iOS. Antstream is not supported via iOS, but you can still enjoy Antstream app via Mac, PC, Android tablet and mobile devices</p>
                            </div>
                            <div className="agree-conditions">
                                <Checkbox 
                                    name="agreeTerms"
                                    checkboxToggled={(checked) =>this.setState({isTermsAndConditionsAgreed: checked})}
                                    initallyChecked={false}
                                />
                                <label className="agree-conditions-text" htmlFor="agreeTerms">I've acknowledged that Antstream is not supported on iOS and I would like to continue subscribing</label>
                            </div>
                        </> 
                    }
                    <div className="button-container">
                        <Button
                            className="primary"
                            disabled={isiOS && !this.state.isTermsAndConditionsAgreed}
                            type="submit"
                            onClick={() => navigateToLocation(ROUTES.DOCOMO_REGISTRATION, { ddSubscriptionId, merchantSubscriptionId, isIOSAcknowledged: true})}
                        >
                            Sign up now
                        </Button>
                    </div>
                </div>
            </section>
        )
    }
}